//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import {
  onChangeCompanyBusiness,
  mappingCurrency,
  mappingThousand
} from '@/utils/helpers.js'
import { COURIER_AGGREGATOR_NAME } from '~/utils/constants'

export default {
  name: 'MainSidebar',
  filters: {
    mappingCurrency,
    mappingThousand
  },
  props: {
    isVerifyBannerShown: {
      type: Boolean,
      default: false
    },
    isAnnouncementShown: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const mainMenu = [
      {
        name: 'Home',
        icon: 'home-sclvico',
        link: '/dashboard',
        hasPermission: true,
        children: [
          {
            name: 'Analytics',
            link: '/analytics',
            hasPermission: false
          }
        ],
        isExpanded: false
      },
      {
        name: 'Products',
        icon: 'ic-cube',
        link: '/products',
        hasPermission: false,
        children: [
          {
            name: 'Bundles',
            link: '/bundles',
            hasPermission: false
          },
          {
            name: 'Inventory',
            link: '/inventory',
            hasPermission: false
          }
        ],
        isExpanded: false
      },
      {
        name: 'Landing Pages',
        icon: 'doc-sclvico',
        link: '/pages',
        hasPermission: false,
        children: [],
        isExpanded: false
      },
      {
        name: 'Orders',
        icon: 'ic-orders',
        link: '/order',
        hasPermission: false,
        children: [
          {
            name: 'Ongkir',
            link: '/shipping-fee',
            hasPermission: true
          }
        ],
        isExpanded: false
      },
      {
        name: 'Stores',
        icon: 'ic-store',
        link: '/store',
        hasPermission: false,
        children: [],
        isExpanded: false
      },
      {
        name: 'Customers',
        icon: 'user-group-sclvico',
        link: '/customer',
        hasPermission: false,
        children: [],
        isExpanded: false
      },
      {
        name: 'Tutorials',
        icon: 'information-sclvico',
        link: '/tutorial',
        hasPermission: true,
        children: [],
        isExpanded: false
      }
    ]
    return {
      isCancel: false,
      mainMenu,
      breakpointWatcher: null,
      canViewBalance: false,
      businessCountAlert: {
        check: false,
        maxBusiness: 1,
        ownedBusinessCount: 0
      },
      isLoadingBalance: true,
      businessCount: this.$auth.user.detail?.business_count ?? 0,
      canEditBusiness: false,
      isWarningsShown: false,
      canViewAnotherBalances: false,
      balances: [],
      isAnotherBalancesShown: false
    }
  },
  async fetch() {
    if (
      this.$auth?.user?.currentBusiness?.invitationStatus !== 'invited'
      // this.$auth?.user?.currentBusiness?.isUserBusinessActive
    ) {
      this.canViewBalance = await this.$store.dispatch(
        'permission/hasPermission',
        { user: this.$auth.user, permission: ['view_business_transaction'] }
      )
    }
  },
  fetchKey: 'dashboard-sidebar',
  computed: {
    isWarningsAvailable() {
      return (
        (this.$auth.user.detail?.current_business_subscription
          ?.current_pricing_plan &&
          this.$auth.user.detail?.current_business_subscription
            ?.current_pricing_plan.subscription_plan.monthly_order_limit &&
          this.orderLimitUsage >= 80) ||
        (this.$auth.user.currentBusiness.subscription &&
          this.$auth.user.currentBusiness.subscription
            .latest_subscription_order &&
          this.$auth.user.currentBusiness?.subscription
            ?.latest_subscription_order.status === 'open') ||
        (this.$auth.user.currentBusiness.subscription &&
          this.$auth.user.currentBusiness.subscription?.status === 'unpaid')
      )
    },
    orderLimitUsage() {
      let percent = 0

      if (
        this.$auth.user.detail.current_business_subscription
          ?.current_pricing_plan?.subscription_plan?.monthly_order_limit
      ) {
        percent = Math.round(
          (this.$auth.user.currentBusiness.monthly_order_count /
            this.$auth.user.detail?.current_business_subscription
              ?.current_pricing_plan.subscription_plan.monthly_order_limit) *
            100
        )
      }

      return percent
    },
    mini: {
      get() {
        return this.$store.getters['common/getSidebarMini']
      },
      set(val) {
        this.$store.dispatch('common/setSidebarMini', val)
      }
    },
    isBusinessAddModelShow: {
      get() {
        return this.getBusinessAddModalShow
      },
      set(val) {
        this.$store.dispatch('common/setBusinessAddModalShow', val)
      }
    },
    ...mapGetters('business', ['getOwnedBusinessCount']),
    ...mapGetters('permission', ['getPagePermission']),
    ...mapGetters('notification', ['getUnread']),
    ...mapGetters('common', ['getBusinessAddModalShow', 'getSidebarKey']),
    ...mapGetters('balance', ['getCurrentBalance', 'getOnHoldBalance']),
    getUserHasBusiness() {
      return !!this.$auth.user.detail?.current_business
    },
    getBusinessDetail() {
      return this.$auth.user.currentBusiness || {}
    },
    getIsOwner() {
      return (
        (this.$auth.user.currentBusiness || {}).owner?.id ===
        this.$auth.user.detail?.id
      )
    },
    isBusinessActive() {
      return this.$auth?.user?.currentBusiness?.isUserBusinessActive
    }
  },
  watch: {
    getSidebarKey() {
      this.$fetch()
    }
  },
  async mounted() {
    this.canEditBusiness = await this.$store.dispatch(
      'permission/hasPermission',
      { user: this.$auth.user, permission: 'edit_business' }
    )

    const canViewAnalytics = await this.$store.dispatch(
      'permission/hasPermission',
      { user: this.$auth.user, permission: 'view_order' }
    )

    const canViewProducts = await this.$store.dispatch(
      'permission/hasPermission',
      { user: this.$auth.user, permission: 'view_product' }
    )

    const canViewBundle = await this.$store.dispatch(
      'permission/hasPermission',
      { user: this.$auth.user, permission: 'view_bundle' }
    )

    const canViewInventoryBalance = await this.$store.dispatch(
      'permission/hasPermission',
      { user: this.$auth.user, permission: 'view_inventory_flow' }
    )

    const canViewOrderList = await this.$store.dispatch(
      'permission/hasPermission',
      { user: this.$auth.user, permission: 'view_order' }
    )

    const canViewStoreList = await this.$store.dispatch(
      'permission/hasPermission',
      { user: this.$auth.user, permission: 'list_store' }
    )

    const canViewWebStudio = await this.$store.dispatch(
      'permission/hasPermission',
      { user: this.$auth.user, permission: ['view_form', 'view_page'] }
    )

    const canViewCusList = await this.$store.dispatch(
      'permission/hasPermission',
      { user: this.$auth.user, permission: 'view_customer' }
    )

    this.mainMenu.forEach((menuList, i) => {
      if (menuList.name === 'Products') {
        this.mainMenu[i].hasPermission = canViewProducts
      }

      if (menuList.name === 'Landing Pages') {
        menuList.hasPermission = canViewWebStudio
      }

      if (menuList.name === 'Orders') {
        this.mainMenu[i].hasPermission = canViewOrderList
      }

      if (menuList.name === 'Stores') {
        this.mainMenu[i].hasPermission = canViewStoreList
      }

      if (menuList.name === 'Customers') {
        this.mainMenu[i].hasPermission = canViewCusList
      }

      menuList.children.forEach((child, j) => {
        if (child.name === 'Bundles') {
          this.mainMenu[i].children[j].hasPermission = canViewBundle
        }

        if (child.name === 'Inventory') {
          this.mainMenu[i].children[j].hasPermission = canViewInventoryBalance
        }

        if (child.name === 'Analytics') {
          this.mainMenu[i].children[j].hasPermission = canViewAnalytics
        }
      })
    })

    this.mainMenu = this.mainMenu.map((x) => {
      return {
        ...x,
        isExpanded:
          x.children
            .filter((y) => y.hasPermission)
            .some((y) => this.$route.path.includes(y.link)) ||
          (x.children.filter((y) => y.hasPermission).length > 0 &&
            this.$route.path.includes(x.link))
      }
    })

    if (this.$mq === 'md' || this.$mq === 'sm') {
      this.mini = true
    } else {
      this.mini = false
    }
    this.breakpointWatcher = this.$watch('$mq', (val) => {
      if (val === 'md' || val === 'sm') {
        this.mini = true
      } else {
        this.mini = false
      }
    })
    document.addEventListener('showWarnings', () => {
      this.isWarningsShown = true
    })

    await this.$store.dispatch('balance/fetchBalance')

    const canEditBusiness = await this.$store.dispatch(
      'permission/hasPermission',
      {
        user: this.$auth.user,
        permission: ['edit_business']
      }
    )

    if (
      this.$auth.user.currentBusiness?.is_pickup_enabled ||
      this.$auth.user.currentBusiness?.is_epayment_enabled
    ) {
      if (this.$auth.user.currentBusiness?.is_xendit_account_created) {
        try {
          const [res, res1] = await Promise.all([
            this.$store.dispatch('xpTransaction/fetchBalance'),
            this.$store.dispatch('xpTransaction/fetchBalance', true)
          ])

          this.balances.push({
            name: 'E-Payment Balance',
            balance: res.data.balance + res1.data.balance
          })
        } catch (err) {
          console.log(err)
        }
      }

      this.balances.push({
        name: 'Classic Balance',
        balance:
          parseInt(this.getCurrentBalance) + parseInt(this.getOnHoldBalance)
      })
    }

    if (canEditBusiness) {
      for (const x of this.$auth.user.currentBusiness
        .business_courier_aggregators) {
        const balance = {
          ...x,
          name: COURIER_AGGREGATOR_NAME[x.courier_aggregator] + ' Balance',
          balance: 0
        }

        try {
          const res = await this.$store.dispatch(
            'courierAggregator/fetchBalance',
            x.id
          )
          balance.balance = res.data.balance
        } catch (err) {
          console.error(err)
        }

        this.balances.push(balance)
      }
    }

    this.isLoadingBalance = false
  },
  methods: {
    expandMenu(index) {
      this.mainMenu = this.mainMenu.map((x) => ({
        ...x,
        isExpanded: false
      }))

      if (
        this.mainMenu[index]?.children.filter((x) => x.hasPermission).length > 0
      ) {
        this.mainMenu[index].isExpanded = true
      }
    },
    onChangeCompanyBusiness,
    daysDifference(dateString) {
      const inputDate = new Date(dateString)
      const today = new Date()

      const differenceInTime = inputDate - today

      const differenceInDays = Math.floor(
        differenceInTime / (1000 * 60 * 60 * 24)
      )

      return differenceInDays < 0 ? 0 : differenceInDays
    },
    onAddClick() {
      this.isBusinessAddModelShow = true
      this.$emit('add-business')
    },
    yesCancel() {
      this.isCancel = false
      this.isBusinessAddModelShow = false
    },
    noCancel() {
      this.isCancel = false
    },
    showCancel() {
      this.isCancel = true
    },
    navigatePage(evt, navigate) {
      navigate(evt)
    },
    callScalev() {
      this.businessCountAlert.check = false
      open('https://t.me/scalev_id', '_blank')
    }
  }
}
