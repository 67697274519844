import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  whatsappIntegrations: [],
  metaWhatsappIntegrations: {}
})

export const mutations = {
  ...mapMutationsHelper(state()),
  SET_IS_OPEN_BY_INDEX(state, { index, isOpen }) {
    state.stores[index].is_open = isOpen
  }
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_WHATSAPP_INTEGRATIONS', [])
    }

    const { page, itemsLength } = state.metaWhatsappIntegrations

    if (!isFirst && itemsLength === state.whatsappIntegrations.length) {
      return state.whatsappIntegrations
    }

    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: 25,
      ...query
    })

    const queries = encodeQueryData(initQuery)
    const url = `${this.$config.apiUrlV2}/whatsapp-integration/?${queries}`

    try {
      const res = await this.$axios.$get(url)
      const allData = [...state.whatsappIntegrations, ...res.data.results]
      commit('SET_WHATSAPP_INTEGRATIONS', allData)
      const newMeta = decodeQueryData(res.data.next?.split('?')?.[1])

      if (newMeta) {
        commit('SET_META_WHATSAPP_INTEGRATIONS', {
          itemsLength: res.data.count || res.data.results.length,
          page: newMeta.page - 0,
          pageSize: newMeta.page_size - 0
        })
      }

      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }

      throw error
    }
  },

  async retrieve({ commit }, id) {
    try {
      const res = await this.$axios.$get(
        `${this.$config.apiUrlV2}/whatsapp-integration/${id}/`
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }

      throw error
    }
  },

  async fetchStatus({ commit }, id) {
    try {
      const res = await this.$axios.$get(
        `${this.$config.apiUrlV2}/whatsapp-integration/${id}/check-status/`
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }

      throw error
    }
  },

  async storeData({ commit }, payload) {
    try {
      const {
        name,
        provider,
        woowaApiKey,
        ssAccountApiKey,
        ssDeviceApiKey,
        phoneNumber
      } = payload

      const res = await this.$axios.$post(
        `${this.$config.apiUrlV2}/whatsapp-integration/`,
        {
          name,
          provider,
          woowa_api_key: woowaApiKey,
          ss_account_api_key: ssAccountApiKey,
          ss_device_api_key: ssDeviceApiKey,
          phone_number: phoneNumber
        }
      )

      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }

      throw error
    }
  },

  async testMessage({ commit }, id) {
    try {
      const res = await this.$axios.$post(
        `${this.$config.apiUrlV2}/whatsapp-integration/${id}/check-status/`
      )

      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }

      throw error
    }
  },

  async update({ commit }, payload) {
    try {
      const {
        id,
        name,
        provider,
        woowaApiKey,
        ssAccountApiKey,
        ssDeviceApiKey,
        phoneNumber
      } = payload

      const res = await this.$axios.$put(
        `${this.$config.apiUrlV2}/whatsapp-integration/${id}/`,
        {
          name,
          provider,
          woowa_api_key: woowaApiKey,
          ss_account_api_key: ssAccountApiKey,
          ss_device_api_key: ssDeviceApiKey,
          phone_number: phoneNumber
        }
      )

      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }

      throw error
    }
  },

  async delete({ commit }, id) {
    try {
      await this.$axios.$delete(
        `${this.$config.apiUrlV2}/whatsapp-integration/${id}/`
      )
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }

      throw error
    }
  }
}
