//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider, validate } from 'vee-validate'
import { onlyNumberOnKeypress } from '@/utils/helpers.js'

export default {
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    noPattern: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: String,
      default: undefined
    },
    id: {
      type: String,
      default: undefined
    },
    height: {
      type: String,
      default: 'h-[38px]'
    },
    bg: {
      type: String,
      default: 'bg-gray-150'
    },
    debounce: {
      type: Number,
      default: 0
    },
    mode: {
      type: [String, Function],
      default: undefined
    },
    autocomplete: {
      type: String,
      default: 'on'
    },
    prepend: {
      type: String,
      default: ''
    },
    append: {
      type: String,
      default: ''
    },
    appendBg: {
      type: String,
      default: 'bg-black-75'
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    rules: {
      type: String,
      default: ''
    },
    immediate: {
      type: Boolean,
      default: false
    },
    hideMessage: {
      type: Boolean,
      default: false
    },
    autoHideMessage: {
      type: Boolean,
      default: false
    },
    isMoney: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    },
    precision: {
      type: [String, Number],
      default: 0
    },
    labelColor: {
      type: String,
      default: 'text-gray-800'
    },
    labelSize: {
      type: String,
      default: 'text-[12px]'
    },
    textSize: {
      type: String,
      default: 'text-[14px]'
    },
    customError: {
      type: String,
      default: ''
    },
    customSuccess: {
      type: String,
      default: ''
    },
    moneyTextLeft: {
      type: Boolean,
      default: false
    },
    currencyFieldOptions: {
      type: Object,
      default() {
        return {
          currency: null,
          locale: 'id-ID',
          distractionFree: {
            hideCurrencySymbol: true,
            hideGroupingSymbol: false,
            hideNegligibleDecimalDigits: true
          },
          valueAsInteger: true,
          precision: 0
        }
      }
    },
    rounded: {
      type: String,
      default: 'rounded'
    },
    inputClass: {
      type: String,
      default: ''
    },
    errorMessageReplace: {
      type: String,
      default: ''
    }
  },
  data() {
    let temp = this.value
    if (this.isMoney && !!this.value) {
      temp = this.value - 0
    }
    return {
      tempValue: temp,
      isPasswordShow: false
    }
  },
  computed: {
    idFix() {
      return this.id || (this.name && this.label)
        ? `${this.name}-${this.label}`
        : ''
    },
    typeFixed() {
      if (this.type === 'password') {
        if (this.isPasswordShow) {
          return 'text'
        } else {
          return 'password'
        }
      }

      return this.type
    },
    innerCurrencyFieldOptions() {
      return {
        ...this.currencyFieldOptions,
        precision: this.precision
      }
    }
  },
  watch: {
    value(val) {
      this.tempValue = val
      this.$refs.provider.value = val
      validate(val, this.rules, {
        name: this.name || this.label || this.placeholder
      }).then((result) => {
        this.$refs.provider.setFlags({
          valid: result.valid,
          invalid: !result.valid
        })
        this.$refs.provider.setErrors(result.errors)
      })
    },
    rules(val) {
      if (this.$refs.provider.isRequired) {
        validate(this.value, val, {
          name: this.name || this.label || this.placeholder
        }).then((result) => {
          this.$refs.provider.setFlags({
            valid: result.valid,
            invalid: !result.valid
          })
          // this.$refs.provider.setErrors(result.errors)
        })
      } else {
        // this.$refs.provider.setErrors([])
        this.$refs.provider.setFlags({ valid: true, invalid: false })
      }
    }
  },
  mounted() {
    this.$refs.provider.initialValue = this.value
    this.$refs.provider.value = this.value
    if (this.$refs.provider.isRequired) {
      validate(this.value, this.rules, {
        name: this.name || this.label || this.placeholder
      }).then((result) => {
        this.$refs.provider.setFlags({
          valid: result.valid,
          invalid: !result.valid
        })
        // this.$refs.provider.setErrors(result.errors)
        if (this.immediate) {
          this.$refs.provider.validate()
        }
      })
    } else {
      // this.$refs.provider.setErrors([])
      this.$refs.provider.setFlags({ valid: true, invalid: false })
    }
  },
  methods: {
    focus() {
      if (this.isMoney) {
        this.$refs.moneyField.focus()
      } else {
        this.$refs.textField.focus()
      }
    },
    onInputNative(e) {
      if (this.isMoney) {
        this.$emit('input', e)
      } else {
        this.tempValue = e.target.value
        this.$emit('input', e.target.value)
      }
    },
    onKeyup(e) {
      this.$emit('keyup', e)
    },
    onKeydown(e) {
      this.$emit('keydown', e)
    },
    onChange(e) {
      if (this.isMoney) {
        this.$emit('change', e)
      } else {
        this.$emit('change', e.target.value)
      }
    },
    onFocus(val) {
      this.$emit('focus', val)
    },
    onBlur(val) {
      this.$emit('blur', val)
      this.$refs.provider.validate()
      this.$refs.provider.setFlags({ touched: true, untouched: false })
    },
    onlyNumber(ev) {
      if (this.type === 'number' && !this.noPattern) {
        onlyNumberOnKeypress(ev)
      }
    },
    onPaste(ev) {
      if (this.type === 'number') {
        // Stop data actually being pasted into div
        ev.stopPropagation()
        ev.preventDefault()
        // Get pasted data via clipboard API
        const clipboardData = ev.clipboardData || window?.clipboardData
        const pastedData = clipboardData.getData('Text')
        // Do whatever with pasteddata
        this.tempValue = pastedData.replace(/\D/g, '')
        this.$emit('input', this.tempValue)
      }
    },
    toggleShowPassword() {
      this.isPasswordShow = !this.isPasswordShow
    },
    setErrors(arrError) {
      this.$refs.provider.setErrors(arrError)
    },
    setPending(val) {
      this.$refs.provider.setFlags({ pending: val })
    }
  }
}
