//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider, validate } from 'vee-validate'
import clone from 'lodash/clone'

export default {
  components: {
    ValidationProvider
  },
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: [Array, Boolean],
      default: false
    },
    value: {
      type: [Boolean, Array, String, Number],
      default: undefined
    },
    small: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: undefined
    },
    rules: {
      type: String,
      default: ''
    },
    immediate: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: undefined
    },
    toggle: {
      type: Boolean,
      default: false
    },
    labelClass: {
      type: String,
      default: ''
    },
    checkedColor: {
      type: String,
      default: 'bg-primary-900'
    },
    checkedBorderColor: {
      type: String,
      default: 'border-primary-900'
    }
  },
  data() {
    let isChecked = this.checked
    if (typeof this.checked === 'object') {
      isChecked = this.checked.includes(this.value)
    }
    return {
      isChecked
    }
  },
  computed: {
    idFix() {
      return this.id || (this.name && this.label)
        ? `${this.name}-${this.label}`
        : ''
    }
  },
  watch: {
    async checked(val) {
      if (typeof val === 'object') {
        this.isChecked = val.includes(this.value)
      } else {
        this.isChecked = val
      }
      await this.$refs.provider.validate()
    },
    async isChecked(val) {
      if (typeof this.checked === 'object') {
        if (!val) {
          const tempCheckedList = clone(this.checked)
          const index = this.checked.indexOf(this.value)
          if (index !== -1) {
            tempCheckedList.splice(index, 1)
            this.$emit('change', tempCheckedList)
          }
        } else if (!this.checked.includes(this.value)) {
          this.$emit('change', [...this.checked, this.value])
        }
      } else {
        this.$emit('change', val)
      }
      this.$refs.provider.syncValue(val)
      await this.$refs.provider.validate()
    },
    rules(val) {
      if (this.$refs.provider.isRequired) {
        validate(this.value, val).then((result) => {
          this.$refs.provider.setFlags({
            valid: result.valid,
            invalid: !result.valid
          })
        })
      } else {
        this.$refs.provider.setFlags({ valid: true, invalid: false })
      }
    }
  },
  mounted() {
    this.$refs.provider.initialValue = this.checked
    this.$refs.provider.value = this.checked
    if (this.$refs.provider.isRequired) {
      validate(this.checked, this.rules).then((result) => {
        this.$refs.provider.setFlags({
          valid: result.valid,
          invalid: !result.valid
        })
      })
    } else {
      this.$refs.provider.setFlags({ valid: true, invalid: false })
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
      setTimeout(() => {
        this.$emit('click-delayed')
      }, 85)
    }
  }
}
