import {
  mapGettersHelper,
  mapMutationsHelper,
  initQueryParams,
  encodeQueryData
} from '@/utils/helpers.js'

export const state = () => ({
  reportsMeta: {
    pageSize: 25,
    lastId: undefined
  },
  reports: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_REPORTS', [])
      commit('SET_REPORTS_META', {
        pageSize: 25,
        lastId: undefined
      })
    }
    const { pageSize, lastId } = state.reportsMeta
    const initQuery = initQueryParams({
      page_size: isFirst ? 25 : pageSize,
      last_id: isFirst ? undefined : lastId,
      ...query
    })

    const queries = encodeQueryData(initQuery)
    const url = `${this.$config.apiUrlV2}/business/xp-reports/?${queries}`
    try {
      const res = await this.$axios.$get(url)
      const allReports = [...state.reports, ...res.data.results]
      commit('SET_REPORTS', allReports)
      const nextMeta = {
        pageSize,
        lastId: res.data.results[res.data.results.length - 1]?.id || undefined
      }
      commit('SET_REPORTS_META', nextMeta)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchById({ commit, state }, id) {
    const url = `${this.$config.apiUrlV2}/business/xp-reports/${id}`
    try {
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async generate({ commit, state }, payload) {
    const { type, from, to } = payload
    const url = `${this.$config.apiUrlV2}/business/xp-reports/`
    try {
      const res = await this.$axios.$post(url, {
        type,
        filter: {
          from,
          to
        },
        report_version: 'VERSION_1'
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
