// Default error messages
import { startOfDay, startOfMonth, startOfWeek } from 'date-fns'

export const DEFAULT_ERROR_MESSAGE =
  'Tidak dapat memproses, silakan untuk coba lagi nanti. Hubungi admin atau customer service untuk detail lebih lanjut.'

// user type
export const USER_TYPE_OWNER = 1
export const USER_TYPE_ADMIN = 2
export const USER_TYPE_STORE_ADMIN = 3
export const USER_TYPE_WAREHOUSE_ADMIN = 4
export const USER_TYPE_SALES_PERSON = 5
export const USER_TYPE_ADVERTISER = 6
export const USER_TYPE_FINANCE_STAFF = 7
export const USER_TYPE_ACCOUNTING_STAFF = 8
export const USER_TYPE_CRO = 9
export const USER_TYPE_SALES_MANAGER = 10
export const USER_TYPE_CONVERSION_MANAGER = 11
export const USER_TYPE_INVENTORY_MANAGER = 12
export const USER_TYPE_DROPSHIPPER = 13

// user type str
export const USER_TYPE_OWNER_STR = 'Owner'
export const USER_TYPE_ADMIN_STR = 'Admin'
export const USER_TYPE_STORE_ADMIN_STR = 'Store Admin'
export const USER_TYPE_WAREHOUSE_ADMIN_STR = 'Admin Gudang'
export const USER_TYPE_SALES_PERSON_STR = 'Sales Person'
export const USER_TYPE_ADVERTISER_STR = 'Advertiser'
export const USER_TYPE_FINANCE_STAFF_STR = 'Finance Staff'
export const USER_TYPE_ACCOUNTING_STAFF_STR = 'Accounting Staff'
export const USER_TYPE_CRO_STR = 'CRO'
export const USER_TYPE_SALES_MANAGER_STR = 'Sales Manager'
export const USER_TYPE_CONVERSION_MANAGER_STR = 'Conversion Manager'
export const USER_TYPE_INVENTORY_MANAGER_STR = 'Inventory Manager'
export const USER_TYPE_DROPSHIPPER_STR = 'Dropshipper'

// data iterator
export const LOADING_TEXT_DATA_ITERATOR = 'Loading Data'
export const NO_DATA_TEXT_DATA_ITERATOR = 'No data'

export const textEditorColors = [
  '#333333',
  '#e60000',
  '#ff9900',
  '#ffff00',
  '#008a00',
  '#0066cc',
  '#9933ff',
  '#ffffff',
  '#facccc',
  '#ffebcc',
  '#ffffcc',
  '#cce8cc',
  '#cce0f5',
  '#ebd6ff',
  '#bbbbbb',
  '#f06666',
  '#ffc266',
  '#ffff66',
  '#66b966',
  '#66a3e0',
  '#c285ff',
  '#888888',
  '#a10000',
  '#b26b00',
  '#b2b200',
  '#006100',
  '#0047b2',
  '#6b24b2',
  '#5c0000',
  '#663d00',
  '#666600',
  '#003700',
  '#002966',
  '#0899CF',
  '#3d1466',
  '#000000'
]

export const textEditorBackgrounds = [
  'transparent',
  '#343434',
  '#e60000',
  '#ff9900',
  '#ffff00',
  '#008a00',
  '#0066cc',
  '#9933ff',
  '#ffffff',
  '#facccc',
  '#ffebcc',
  '#ffffcc',
  '#cce8cc',
  '#cce0f5',
  '#ebd6ff',
  '#bbbbbb',
  '#f06666',
  '#ffc266',
  '#ffff66',
  '#66b966',
  '#66a3e0',
  '#c285ff',
  '#888888',
  '#a10000',
  '#b26b00',
  '#b2b200',
  '#006100',
  '#0047b2',
  '#6b24b2',
  '#444444',
  '#5c0000',
  '#663d00',
  '#666600',
  '#003700',
  '#002966',
  '#000000'
]

export const textEditorSizes = [
  '12px',
  '14px',
  '16px',
  '18px',
  '24px',
  '32px',
  '40px',
  '56px',
  '72px',
  false
]

export const textEditFonts = [
  'system',
  'page',
  'roboto',
  'roboto-slab',
  'noto-serif',
  'playfair-display',
  'open-sans',
  'montserrat',
  'poppins',
  'alegreya-sans'
]

export const textEditorLineHeights = [
  '1.0',
  '1.2',
  '1.5',
  '1.6',
  '1.8',
  '2.001',
  '2.4',
  '2.8',
  '3.001',
  '4.001',
  '5.001'
]

export const BALANCE_STATUS = {
  draft: 'Unpaid',
  pending: 'Paid',
  success: 'Settled',
  failed: 'Failed',
  canceled: 'Canceled'
}

export const AFFILIATE_STATUS = {
  pending: 'Pending',
  success: 'Success',
  failed: 'Failed'
}

export const BALANCE_STATUS_STYLE = {
  draft: 'bg-yellow-50 text-yellow-500',
  pending: 'bg-green-50 text-green-600',
  success: 'bg-green-600 text-white',
  failed: 'bg-red-50 text-red-600',
  canceled: 'bg-gray-200 text-black-200'
}

export const AFFILIATE_STATUS_STYLE = {
  pending: 'bg-yellow-50 text-yellow-500',
  success: 'bg-green-600 text-white',
  failed: 'bg-red-50 text-red-600'
}

export const XENDIT_VA_BANK_CODE = {
  BRI: 'BRI Virtual Account',
  MANDIRI: 'Bank Mandiri Virtual Account',
  PERMATA: 'Bank Permata Virtual Account',
  BNI: 'BNI Virtual Account',
  BCA: 'BCA Virtual Account',
  MAYBANK: 'Maybank Virtual Account',
  CIMB: 'Bank CIMB Niaga Virtual Account',
  BNC: 'Bank Neo Commerce Virtual Account',
  DANAMON: 'Bank Danamon Virtual Account',
  BSI: 'BSI Virtual Account',
  AG: 'Bank Artha Graha Virtual Account',
  BJB: 'Bank BJB Virtual Account',
  SAHABAT_SAMPOERNA: 'Bank Sahabat Sampoerna Virtual Account',
  ARTAJASA: 'Bank Arta Jasa Virtual Account'
}

export const XENDIT_E_WALLET = {
  ID_OVO: 'OVO',
  ID_DANA: 'DANA',
  ID_LINKAJA: 'LinkAja',
  ID_SHOPEEPAY: 'ShopeePay'
}

export const PAYMENT_METHOD_ICON = {
  bank_transfer: 'bank-transfer-lp-form-sclvico',
  cod: 'cod-lp-form-sclvico',
  no_payment: 'no-payment-lp-form-sclvico',
  BRI: 'bri-sclvico',
  MANDIRI: 'mandiri-sclvico',
  BANKMANDIRI: 'mandiri-sclvico',
  BCA: 'bca-sclvico',
  BNI: 'bni-sclvico',
  PERMATA: 'permata-sclvico',
  MAYBANK: 'maybank-sclvico',
  CIMB: 'cimbniaga-sclvico',
  BNC: 'bnc-sclvico',
  DANAMON: 'danamon-sclvico',
  BSI: 'bsi-sclvico',
  AG: 'ag-sclvico',
  qris: 'qris-lp-form-sclvico',
  invoice: 'online-payment-sclvico',
  card: 'credit-card-sclvico',
  gopay: 'gopay-sclvico',
  ID_DANA: 'dana-sclvico',
  ID_OVO: 'ovo-sclvico',
  ID_LINKAJA: 'linkaja-sclvico',
  ID_SHOPEEPAY: 'shopeepay-sclvico',
  shopeepay: 'shopeepay-sclvico',
  alfamart: 'alfamart-sclvico',
  dana: 'dana-sclvico',
  ovo: 'ovo-sclvico',
  linkaja: 'linkaja-sclvico',
  BJB: 'ic-bjb',
  ARTAJASA: 'ic-artajasa',
  SAHABAT_SAMPOERNA: 'ic-sahabat-sampoerna'
}

export const PAYMENT_METHOD = {
  bank_transfer: 'Bank Transfer',
  cod: 'COD',
  pg: 'Payment Gateway',
  marketplace: 'Marketplace',
  no_payment: 'No Payment',
  va: 'Virtual Account',
  qris: 'QRIS',
  invoice: 'ALL E-Payment',
  ewallet: 'E-Wallet',
  card: 'Credit Card',
  gopay: 'GoPay',
  shopeepay: 'ShopeePay',
  linkaja: 'LinkAja',
  alfamart: 'Alfamart',
  ovo: 'OVO',
  dana: 'Dana'
}

export const COLOR_LIST = [
  {
    text: '#343434',
    value: '#343434'
  },
  {
    text: '#F2F5FA',
    value: '#F2F5FA'
  },
  {
    text: '#E8F6FB',
    value: '#E8F6FB'
  },
  {
    text: '#E8F9EF',
    value: '#E8F9EF'
  },
  {
    text: '#FFF9EC',
    value: '#FFF9EC'
  },
  {
    text: '#FEEDEE',
    value: '#FEEDEE'
  }
]

export const FONT_STYLE_LIST = [
  {
    value: null,
    text: 'System Default'
  },
  {
    value: 'Roboto',
    text: 'Roboto'
  },
  {
    value: 'Roboto-Slab',
    text: 'Roboto Slab'
  },
  {
    value: 'Noto-Serif',
    text: 'Noto Serif'
  },
  {
    value: 'Playfair-Display',
    text: 'Playfair Display'
  },
  {
    value: 'Open-Sans',
    text: 'Open Sans'
  },
  {
    value: 'Montserrat',
    text: 'Montserrat'
  },
  {
    value: 'Poppins',
    text: 'Poppins'
  },
  {
    value: 'Alegreya-Sans',
    text: 'Alegreya Sans'
  }
]

export const SHORTCUT_CALENDAR = [
  {
    text: 'Hari ini',
    onClick() {
      const date = new Date()
      // return a Date
      return date
    }
  },
  {
    text: 'Kemarin',
    onClick() {
      const dateYesterday = new Date()
      dateYesterday.setTime(dateYesterday.getTime() - 3600 * 1000 * 24)
      return dateYesterday
    }
  }
]

export const ORDER_FOLLOW_UP_TITLE_MAP = {
  Welcome: 'W',
  'Follow Up 1': '1',
  'Follow Up 2': '2',
  'Follow Up 3': '3',
  'Follow Up 4': '4'
}

export const SHORTCUT_CALENDAR_RANGE = [
  {
    text: 'Hari ini',
    onClick() {
      const date = startOfDay(new Date())
      // return a Date
      return [date, date]
    }
  },
  {
    text: 'Kemarin',
    onClick() {
      const dateYesterday = startOfDay(new Date())
      dateYesterday.setTime(dateYesterday.getTime() - 3600 * 1000 * 24)
      return [dateYesterday, dateYesterday]
    }
  },
  {
    text: 'Minggu ini',
    onClick() {
      const dateStartOfWeek = startOfWeek(new Date(), {
        weekStartsOn: 1
      })
      const dateToday = startOfDay(new Date())
      return [dateStartOfWeek, dateToday]
    }
  },
  {
    text: '1 minggu terakhir',
    onClick() {
      const date7DaysAgo = startOfDay(new Date())
      const dateToday = startOfDay(new Date())
      date7DaysAgo.setTime(date7DaysAgo.getTime() - 3600 * 7000 * 24)
      return [date7DaysAgo, dateToday]
    }
  },
  {
    text: '2 minggu terakhir',
    onClick() {
      const date14DaysAgo = startOfDay(new Date())
      const dateToday = startOfDay(new Date())
      date14DaysAgo.setTime(date14DaysAgo.getTime() - 3600 * 14000 * 24)
      return [date14DaysAgo, dateToday]
    }
  },
  {
    text: 'Bulan ini',
    onClick() {
      const dateStartOfMonth = startOfMonth(new Date())
      const dateToday = startOfDay(new Date())
      return [dateStartOfMonth, dateToday]
    }
  }
  // {
  //   text: 'Tahun ini',
  //   onClick() {
  //     const dateStartOfYear = startOfYear(new Date())
  //     const dateToday = startOfDay(new Date())
  //     return [dateStartOfYear, dateToday]
  //   }
  // }
]

// add --> increase quantity in warehouse
// reduce --> decrease quantity in warehouse

export const QUANTITY_TYPES = [
  {
    code: 'in_warehouse_qty',
    name: 'Inventori pada Gudang'
  },
  {
    code: 'available_qty',
    name: 'Inventori Tersedia'
  },
  {
    code: 'processed_qty',
    name: 'Inventori Diproses'
  },
  {
    code: 'shipped_qty',
    name: 'Inventori Dikirim'
  },
  {
    code: 'rts_qty',
    name: 'Inventori RTS'
  },
  {
    code: 'rejected_qty',
    name: 'Inventori Ditolak'
  },
  {
    code: 'completed_qty',
    name: 'Inventori Selesai'
  },
  {
    code: 'closed_qty',
    name: 'Inventori Hilang'
  },
  {
    code: 'destroyed_qty',
    name: 'Inventori Dimusnahkan'
  }
]

export const CATEGORY_CHOICES = [
  {
    codeName: 'new_arrival',
    name: 'Inventori Baru',
    type: ['add'],
    isShown: true
  },
  {
    codeName: 'new_order',
    name: 'Pesanan Baru',
    type: [],
    isShown: false
  },
  {
    codeName: 'order_adjustment',
    name: 'Penyesuaian Pesanan',
    type: [],
    isShown: false
  },
  {
    codeName: 'order_cancellation',
    name: 'Pembatalan Pemesanan',
    type: [],
    isShown: false
  },
  {
    codeName: 'shipment',
    name: 'Pengiriman',
    type: [],
    isShown: false
  },
  {
    codeName: 'order_completion',
    name: 'Pesanan Selesai',
    type: [],
    isShown: false
  },
  {
    codeName: 'rts',
    name: 'Pesanan RTS',
    type: [],
    isShown: false
  },
  {
    codeName: 'lost',
    name: 'Pesanan Hilang',
    type: [],
    isShown: false
  },
  {
    codeName: 'cancel_completed',
    name: 'Pesanan Selesai kembali ke Terkirim',
    type: [],
    isShown: false
  },
  {
    codeName: 'cancel_rts',
    name: 'Pesanan RTS kembali ke Terkirim',
    type: [],
    isShown: false
  },
  {
    codeName: 'cancel_closed',
    name: 'Pesanan Hilang kembali ke Terkirim',
    type: [],
    isShown: false
  },
  {
    codeName: 'cancel_shipment',
    name: 'Pengiriman Dibatalkan',
    type: [],
    isShown: false
  },
  {
    codeName: 'internal_use',
    name: 'Penggunaan Internal',
    type: ['reduce'],
    isShown: true
  },
  {
    codeName: 'promotion',
    name: 'Promosi',
    type: ['reduce'],
    isShown: true
  },
  {
    codeName: 'sample',
    name: 'Sampel',
    type: ['reduce'],
    isShown: true
  },
  {
    codeName: 'damaged_goods',
    name: 'Barang Rusak',
    type: ['reduce'],
    isShown: true
  },
  {
    codeName: 'adjustment',
    name: 'Penyesuaian Manual',
    type: ['add', 'reduce'],
    isShown: true
  },
  {
    codeName: 'conversion',
    name: 'Konversi Produk',
    type: ['add', 'reduce'],
    isShown: true
  },
  {
    codeName: 'movement',
    name: 'Perpindahan Antar-Gudang',
    type: ['add', 'reduce'],
    isShown: true
  },
  {
    codeName: 'recover_from_rts',
    name: 'Dari RTS kembali ke Tersedia',
    type: [],
    isShown: false
  },
  {
    codeName: 'reject',
    name: 'Barang Ditolak',
    type: [],
    isShown: false
  },
  {
    codeName: 'destroy',
    name: 'Pemusnahan',
    type: [],
    isShown: false
  },
  {
    codeName: 'change_quantity_type',
    name: 'Ubah Jenis Inventori',
    type: [],
    isShown: false
  },
  {
    codeName: 'move_between_warehouse',
    name: 'Perpindahan Inventori Antar-Gudang',
    type: [],
    isShown: false
  },
  {
    codeName: 'other',
    name: 'Lainnya',
    type: ['add', 'reduce'],
    isShown: true
  }
]

export const CURRENCY_SYMBOLS = {
  IDR: 'Rp', // Indonesian Rupiah
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  CNY: '¥', // Chinese Yuan
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫' // Vietnamese Dong
}

export const BARCODE_OPTIONS = {
  format: 'CODE128B',
  lineColor: '#000',
  width: 15,
  height: 30,
  displayValue: false
  // flat: true,
}

export const STORE_FILTER_LIST = [
  {
    text: 'Tidak ada akses',
    value: 'none'
  },
  {
    text: 'Semua di bisinis',
    value: 'all_in_business'
  },
  {
    text: 'Semua di toko',
    value: 'all_in_store'
  },
  {
    text: 'Berdasarkan advertiser',
    value: 'advertiser'
  },
  {
    text: 'Berdasarkan sales person',
    value: 'sales_person'
  }
]

export const WAREHOUSE_FILTER_LIST = [
  {
    text: 'Tidak ada bisnis',
    value: 'none'
  },
  {
    text: 'Semua di bisinis',
    value: 'all_in_business'
  },
  {
    text: 'Semua di Gudang',
    value: 'all_in_warehouse'
  }
]

export const LPS_COMPONENTS_NAME = {
  Section: {
    name: 'section'
  },
  TextArea: {
    name: 'text'
  },
  ImgSlider: {
    name: 'imgSlider'
  },
  Img: {
    name: 'img'
  },
  Animation: {
    name: 'anima'
  },
  ScrollTarget: {
    name: 'scroll'
  },
  Button: {
    name: 'button'
  },
  Youtube: {
    name: 'youtube'
  },
  Embed: {
    name: 'embed'
  },
  Faq: {
    name: 'faq'
  },
  List: {
    name: 'list'
  },
  Testimony: {
    name: 'testi'
  },
  MainForm: {
    name: 'form'
  },
  MainFormItems: {
    name: 'formItems'
  },
  Html: {
    name: 'html'
  },
  Divider: {
    name: 'divider'
  }
}

export const PAGE_BUILDER_TEMPLATE = {
  version: 1,
  schemaVersion: 2,
  isPublished: false,
  is_no_scalev_logo: true,
  meta: {
    title: '',
    description: '',
    thumbnail: null,
    favicon: null,
    loadingLogo: null,
    isDisabledSearchEngineCrawler: false,
    isDisabledRightClick: false,
    lang: 'id'
  },
  general: {
    layoutType: 'full', // "default"
    containerMaxWidth: '1200px',
    fontStyle: null,
    fontSize: 'normal',
    backgroundColor: '#F2F5FA',
    pageBackgroundColor: '#FFFFFF',
    sidebarPosition: 'no_sidebar', // "right", nullable,
    customHeadScript: '',
    isCustomHeadScript: false,
    pagePadding: {
      desktop: {
        top: '32px',
        right: '128px',
        bottom: '32px',
        left: '128px'
      },
      tablet: {
        top: '32px',
        right: '50px',
        bottom: '32px',
        left: '50px'
      },
      mobile: {
        top: '20px',
        right: '16px',
        bottom: '32px',
        left: '16px'
      }
    },
    pageFullPadding: {
      desktop: {
        top: '32px',
        right: '128px',
        bottom: '32px',
        left: '128px'
      },
      tablet: {
        top: '32px',
        right: '50px',
        bottom: '32px',
        left: '50px'
      },
      mobile: {
        top: '35px',
        right: '35px',
        bottom: '35px',
        left: '35px'
      }
    },
    pagePaddingWhenSidebar: {
      desktop: {
        top: '32px',
        right: '32px',
        bottom: '32px',
        left: '32px'
      },
      tablet: {
        top: '32px',
        right: '32px',
        bottom: '32px',
        left: '32px'
      },
      mobile: {
        top: '20px',
        right: '16px',
        bottom: '32px',
        left: '16px'
      }
    },
    pageFullPaddingWhenSidebar: {
      desktop: {
        top: '32px',
        right: '32px',
        bottom: '32px',
        left: '32px'
      },
      tablet: {
        top: '32px',
        right: '32px',
        bottom: '32px',
        left: '32px'
      },
      mobile: {
        top: '35px',
        right: '35px',
        bottom: '35px',
        left: '35px'
      }
    },
    gapDesktop: '32px',
    gapTablet: '32px',
    gapMobile: '16px',
    hasForm: false
  },
  header: {
    logo: null,
    title: '',
    subtitle: ''
  },
  banner: {
    type: 'no_banner' // guarantee | no_banner
  },
  sidebar: [],
  main: [],
  fbPixels: [],
  fbPixelsObjects: [],
  onloadFbEvents: [],
  tiktokPixels: [],
  tiktokPixelsObjects: [],
  onloadTiktokEvents: [],
  kwaiClientPixels: [],
  kwaiServerPixels: [],
  kwaiClientPixelsObjects: [],
  kwaiServerPixelsObjects: [],
  onloadKwaiClientEvents: [],
  onloadKwaiServerEvents: [],
  gtm: null,
  gtmObject: null,
  fbEventsOnloadParameters: {},
  tiktokEventsOnloadParameters: {},
  kwaiClientEventsOnloadParameters: {},
  kwaiServerEventsOnloadParameters: {}
}

export const HTML_LANG_OPTIONS = [
  { text: 'Indonesian', value: 'id' },
  { text: 'English', value: 'en' },
  { text: 'Abkhazian', value: 'ab' },
  { text: 'Afar', value: 'aa' },
  { text: 'Afrikaans', value: 'af' },
  { text: 'Akan', value: 'ak' },
  { text: 'Albanian', value: 'sq' },
  { text: 'Amharic', value: 'am' },
  { text: 'Arabic', value: 'ar' },
  { text: 'Aragonese', value: 'an' },
  { text: 'Armenian', value: 'hy' },
  { text: 'Assamese', value: 'as' },
  { text: 'Avaric', value: 'av' },
  { text: 'Avestan', value: 'ae' },
  { text: 'Aymara', value: 'ay' },
  { text: 'Azerbaijani', value: 'az' },
  { text: 'Bambara', value: 'bm' },
  { text: 'Bashkir', value: 'ba' },
  { text: 'Basque', value: 'eu' },
  { text: 'Belarusian', value: 'be' },
  { text: 'Bengali (Bangla)', value: 'bn' },
  { text: 'Bihari', value: 'bh' },
  { text: 'Bislama', value: 'bi' },
  { text: 'Bosnian', value: 'bs' },
  { text: 'Breton', value: 'br' },
  { text: 'Bulgarian', value: 'bg' },
  { text: 'Burmese', value: 'my' },
  { text: 'Catalan', value: 'ca' },
  { text: 'Chamorro', value: 'ch' },
  { text: 'Chechen', value: 'ce' },
  { text: 'Chichewa, Chewa, Nyanja', value: 'ny' },
  { text: 'Chinese', value: 'zh' },
  { text: 'Chinese (Simplified)', value: 'zh-Hans' },
  { text: 'Chinese (Traditional)', value: 'zh-Hant' },
  { text: 'Chuvash', value: 'cv' },
  { text: 'Cornish', value: 'kw' },
  { text: 'Corsican', value: 'co' },
  { text: 'Cree', value: 'cr' },
  { text: 'Croatian', value: 'hr' },
  { text: 'Czech', value: 'cs' },
  { text: 'Danish', value: 'da' },
  { text: 'Divehi, Dhivehi, Maldivian', value: 'dv' },
  { text: 'Dutch', value: 'nl' },
  { text: 'Dzongkha', value: 'dz' },
  { text: 'Esperanto', value: 'eo' },
  { text: 'Estonian', value: 'et' },
  { text: 'Ewe', value: 'ee' },
  { text: 'Faroese', value: 'fo' },
  { text: 'Fijian', value: 'fj' },
  { text: 'Finnish', value: 'fi' },
  { text: 'French', value: 'fr' },
  { text: 'Fula, Fulah, Pulaar, Pular', value: 'ff' },
  { text: 'Galician', value: 'gl' },
  { text: 'Gaelic (Scottish)', value: 'gd' },
  { text: 'Gaelic (Manx)', value: 'gv' },
  { text: 'Georgian', value: 'ka' },
  { text: 'German', value: 'de' },
  { text: 'Greek', value: 'el' },
  { text: 'Greenlandic', value: 'kl' },
  { text: 'Guarani', value: 'gn' },
  { text: 'Gujarati', value: 'gu' },
  { text: 'Haitian Creole', value: 'ht' },
  { text: 'Hausa', value: 'ha' },
  { text: 'Hebrew', value: 'he' },
  { text: 'Herero', value: 'hz' },
  { text: 'Hindi', value: 'hi' },
  { text: 'Hiri Motu', value: 'ho' },
  { text: 'Hungarian', value: 'hu' },
  { text: 'Icelandic', value: 'is' },
  { text: 'Ido', value: 'io' },
  { text: 'Igbo', value: 'ig' },
  { text: 'Interlingua', value: 'ia' },
  { text: 'Interlingue', value: 'ie' },
  { text: 'Inuktitut', value: 'iu' },
  { text: 'Inupiak', value: 'ik' },
  { text: 'Irish', value: 'ga' },
  { text: 'Italian', value: 'it' },
  { text: 'Japanese', value: 'ja' },
  { text: 'Javanese', value: 'jv' },
  { text: 'Kalaallisut, Greenlandic', value: 'kl' },
  { text: 'Kannada', value: 'kn' },
  { text: 'Kanuri', value: 'kr' },
  { text: 'Kashmiri', value: 'ks' },
  { text: 'Kazakh', value: 'kk' },
  { text: 'Khmer', value: 'km' },
  { text: 'Kikuyu', value: 'ki' },
  { text: 'Kinyarwanda (Rwanda)', value: 'rw' },
  { text: 'Kirundi', value: 'rn' },
  { text: 'Kyrgyz', value: 'ky' },
  { text: 'Komi', value: 'kv' },
  { text: 'Kongo', value: 'kg' },
  { text: 'Korean', value: 'ko' },
  { text: 'Kurdish', value: 'ku' },
  { text: 'Kwanyama', value: 'kj' },
  { text: 'Lao', value: 'lo' },
  { text: 'Latin', value: 'la' },
  { text: 'Latvian (Lettish)', value: 'lv' },
  { text: 'Limburgish (Limburger)', value: 'li' },
  { text: 'Lingala', value: 'ln' },
  { text: 'Lithuanian', value: 'lt' },
  { text: 'Luga-Katanga', value: 'lu' },
  { text: 'Luganda, Ganda', value: 'lg' },
  { text: 'Luxembourgish', value: 'lb' },
  { text: 'Manx', value: 'gv' },
  { text: 'Macedonian', value: 'mk' },
  { text: 'Malagasy', value: 'mg' },
  { text: 'Malay', value: 'ms' },
  { text: 'Malayalam', value: 'ml' },
  { text: 'Maltese', value: 'mt' },
  { text: 'Maori', value: 'mi' },
  { text: 'Marathi', value: 'mr' },
  { text: 'Marshallese', value: 'mh' },
  { text: 'Moldavian', value: 'mo' },
  { text: 'Mongolian', value: 'mn' },
  { text: 'Nauru', value: 'na' },
  { text: 'Navajo', value: 'nv' },
  { text: 'Ndonga', value: 'ng' },
  { text: 'Northern Ndebele', value: 'nd' },
  { text: 'Nepali', value: 'ne' },
  { text: 'Norwegian', value: 'no' },
  { text: 'Norwegian bokmål', value: 'nb' },
  { text: 'Norwegian nynorsk', value: 'nn' },
  { text: 'Nuosu', value: 'ii' },
  { text: 'Occitan', value: 'oc' },
  { text: 'Ojibwe', value: 'oj' },
  { text: 'Old Church Slavonic, Old Bulgarian', value: 'cu' },
  { text: 'Oriya', value: 'or' },
  { text: 'Oromo (Afaan Oromo)', value: 'om' },
  { text: 'Ossetian', value: 'os' },
  { text: 'Pāli', value: 'pi' },
  { text: 'Pashto, Pushto', value: 'ps' },
  { text: 'Persian (Farsi)', value: 'fa' },
  { text: 'Polish', value: 'pl' },
  { text: 'Portuguese', value: 'pt' },
  { text: 'Punjabi (Eastern)', value: 'pa' },
  { text: 'Quechua', value: 'qu' },
  { text: 'Romansh', value: 'rm' },
  { text: 'Romanian', value: 'ro' },
  { text: 'Russian', value: 'ru' },
  { text: 'Sami', value: 'se' },
  { text: 'Samoan', value: 'sm' },
  { text: 'Sango', value: 'sg' },
  { text: 'Sanskrit', value: 'sa' },
  { text: 'Serbian', value: 'sr' },
  { text: 'Serbo-Croatian', value: 'sh' },
  { text: 'Sesotho', value: 'st' },
  { text: 'Setswana', value: 'tn' },
  { text: 'Shona', value: 'sn' },
  { text: 'Sichuan Yi', value: 'ii' },
  { text: 'Sindhi', value: 'sd' },
  { text: 'Sinhalese', value: 'si' },
  { text: 'Siswati', value: 'ss' },
  { text: 'Slovak', value: 'sk' },
  { text: 'Slovenian', value: 'sl' },
  { text: 'Somali', value: 'so' },
  { text: 'Southern Ndebele', value: 'nr' },
  { text: 'Spanish', value: 'es' },
  { text: 'Sundanese', value: 'su' },
  { text: 'Swahili (Kiswahili)', value: 'sw' },
  { text: 'Swati', value: 'ss' },
  { text: 'Swedish', value: 'sv' },
  { text: 'Tagalog', value: 'tl' },
  { text: 'Tahitian', value: 'ty' },
  { text: 'Tajik', value: 'tg' },
  { text: 'Tamil', value: 'ta' },
  { text: 'Tatar', value: 'tt' },
  { text: 'Telugu', value: 'te' },
  { text: 'Thai', value: 'th' },
  { text: 'Tibetan', value: 'bo' },
  { text: 'Tigrinya', value: 'ti' },
  { text: 'Tonga', value: 'to' },
  { text: 'Tsonga', value: 'ts' },
  { text: 'Turkish', value: 'tr' },
  { text: 'Turkmen', value: 'tk' },
  { text: 'Twi', value: 'tw' },
  { text: 'Uyghur', value: 'ug' },
  { text: 'Ukrainian', value: 'uk' },
  { text: 'Urdu', value: 'ur' },
  { text: 'Uzbek', value: 'uz' },
  { text: 'Venda', value: 've' },
  { text: 'Vietnamese', value: 'vi' },
  { text: 'Volapük', value: 'vo' },
  { text: 'Wallon', value: 'wa' },
  { text: 'Welsh', value: 'cy' },
  { text: 'Wolof', value: 'wo' },
  { text: 'Western Frisian', value: 'fy' },
  { text: 'Xhosa', value: 'xh' },
  { text: 'Yiddish', value: 'yi' },
  { text: 'Yoruba', value: 'yo' },
  { text: 'Zhuang, Chuang', value: 'za' },
  { text: 'Zulu', value: 'zu' }
]

export const COURIER_AGGREGATOR_NAME = {
  lincah: 'Lincah',
  mengantar: 'Mengantar',
  kiriminaja: 'KiriminAja'
}
