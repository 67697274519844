//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ToastItem',
  props: {
    color: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    toastId: {
      type: Number,
      default: null
    },
    callback: {
      type: Function,
      default: null
    }
  },
  // watch: {
  //   message: {
  //     handler(val) {
  //       if (val.includes('have expired')) {
  //         location.reload()
  //       }
  //     },
  //     immediate: true
  //   }
  // },
  mounted() {
    if (this.color === 'success') {
      setTimeout(() => {
        this.$store.dispatch('common/toast/closeToast', this.toastId)
      }, 3000)
    }
  },
  methods: {
    execCallback() {
      this.callback()
      this.close()
    },
    close() {
      this.$store.dispatch('common/toast/closeToast', this.toastId)
    }
  }
}
