import {
  encodeQueryData,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  businessAccounts: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, query = {}) {
    try {
      const queries = encodeQueryData(query)
      const res = await this.$axios.$get(
        `${this.$config.apiUrlV2}/business/pg-accounts/?${queries}`
      )
      commit('SET_BUSINESS_ACCOUNTS', res.data)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchAllEpaymentMethods({ commit, state }, query = {}) {
    try {
      const queries = encodeQueryData(query)
      const res = await this.$axios.$get(
        `${this.$config.apiUrlV2}/business/all-epayment-methods/?${queries}`
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchAllXPEpaymentMethods({ commit, state }, query = {}) {
    try {
      const queries = encodeQueryData(query)
      const res = await this.$axios.$get(
        `${this.$config.apiUrlV2}/business/xp-epayment-methods/?${queries}`
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchById({ commit, state }, id) {
    try {
      const res = await this.$axios.$get(
        `${this.$config.apiUrlV2}/business/pg-accounts/${id}`
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeData({ commit, state }, payload) {
    const { provider, paymentMethod, subPaymentMethod } = payload
    try {
      const res = await this.$axios.$post(
        `${this.$config.apiUrlV2}/business/pg-accounts/`,
        {
          provider,
          payment_method: paymentMethod,
          sub_payment_method: subPaymentMethod
        }
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async update({ commit, state }, payload) {
    const { id, provider, paymentMethod, subPaymentMethod } = payload
    try {
      const res = await this.$axios.$patch(
        `${this.$config.apiUrlV2}/business/pg-accounts/${id}`,
        {
          provider,
          payment_method: paymentMethod,
          sub_payment_method: subPaymentMethod
        }
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async delete({ commit, state }, id) {
    try {
      const res = await this.$axios.$delete(
        `${this.$config.apiUrlV2}/business/pg-accounts/${id}`
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
