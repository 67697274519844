//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

const generateDefaultDate = (range) => {
  const defaultDateSince = new Date(
    new Date().setDate(new Date().getDate() - range)
  )
  defaultDateSince.setHours(0, 0, 0, 0)
  const defaultDateUntil = new Date()
  defaultDateUntil.setHours(0, 0, 0, 0)
  return [
    defaultDateSince.toLocaleDateString('id-ID', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }),
    defaultDateUntil.toLocaleDateString('id-ID', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
  ]
}
export default {
  computed: {
    ...mapGetters('business', ['getBusiness']),
    getUserHasBusiness() {
      return !!this.$auth.user.detail?.current_business
    },
    getBusinessDetail() {
      return this.$auth.user.currentBusiness || {}
    },
    ownerDetail() {
      return this.getBusinessDetail.owner
    }
  },
  methods: {
    async onSkip() {
      this.$nuxt.$loading.start(true)
      try {
        if (this.getBusiness.length > 1) {
          const restBusiness = this.getBusiness.filter(
            (bus) => bus.id !== this.getBusinessDetail.id
          )
          await this.$store.dispatch(
            'business/changeBusiness',
            restBusiness?.[0]?.id || null
          )
          await this.$auth.fetchUser()
          await this.$nuxt.refresh()
        }
        // await this.$store.dispatch('common/checkBusinessPhone', this.$auth.user.currentBusiness)
        this.$emit('skip')
      } catch (error) {
        this.$errorHandler(error)
      }
      this.$nuxt.$loading.finish()
    },
    async fetchDashboardData() {
      if (
        this.$route.name === 'dashboard' &&
        this.getUserHasBusiness &&
        this.$auth.user.currentBusiness.invitationStatus === 'accepted' &&
        this.$auth.user.currentBusiness.isUserBusinessActive
      ) {
        let queryFromStorage = null
        if (localStorage.getItem('dashboardQuery')) {
          queryFromStorage = JSON.parse(localStorage.getItem('dashboardQuery'))
        }
        const type = queryFromStorage?.type || 'summary'
        // const summaryType = queryFromStorage?.summaryType || 'income'
        const date =
          queryFromStorage?.date?.split(',') || generateDefaultDate(31)
        const time = queryFromStorage?.time || 'draft_time'
        const frequency = queryFromStorage?.frequency || 'daily'
        const platform = queryFromStorage?.platform?.split(',')
        const store = queryFromStorage?.store?.split(',')?.map((x) => Number(x))
        const adSource = queryFromStorage?.adSource?.split(',')
        const conversionType =
          queryFromStorage?.conversionType || type === 'summary'
            ? ''
            : 'handler'
        const query = Object.fromEntries(
          Object.entries({
            reference_time_field: time,
            metrics: 'gross_revenue',
            frequency,
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            [`${time}_since`]: new Date(
              date[0].split('/').reverse().join('/')
            ).toISOString(),
            [`${time}_until`]: new Date(
              date[1].split('/').reverse().join('/')
            ).toISOString(),
            conversion_type: conversionType,
            platform: platform?.join(','),
            store_id: store?.join(','),
            ad_source: adSource?.join(','),
            is_only_from_form: type === 'summary' ? false : undefined
          }).filter(([_, v]) => v !== null && v !== '')
        )
        await Promise.all([
          this.$store.dispatch('order/fetchStatisticV2', query)
          // this.$store.dispatch('order/fetchChart', {
          //   period: 'last_7_days',
          //   tz: Intl.DateTimeFormat().resolvedOptions().timeZone
          // })
        ])
        window.dispatchEvent(new Event('resize'))
      }
    },
    async onDecline() {
      await this.$store.dispatch('onboarding/setLoading', true)
      this.$nuxt.$loading.start(true)
      try {
        await this.$store.dispatch('business/denyInvitation', {
          idBusiness: this.getBusinessDetail.id,
          idUser: this.$auth.user.detail.id
        })
        await this.$auth.fetchUser()
        if (this.getBusiness.length > 0) {
          const restBusiness = this.getBusiness.filter(
            (bus) => bus.id !== this.getBusinessDetail.id
          )
          await this.$store.dispatch(
            'business/changeBusiness',
            restBusiness?.[0]?.id || null
          )
        }
        await this.$auth.fetchUser()
        await Promise.all([
          this.$store.dispatch('common/refreshSidebar'),
          this.fetchDashboardData()
          // this.$store.dispatch('common/checkBusinessPhone', this.$auth.user.currentBusiness)
        ])
        await this.$store.dispatch('common/setInvitationShow', false)
        this.$emit('decline')
      } catch (error) {
        this.$errorHandler(error)
      }
      this.$store.dispatch('onboarding/setLoading', false)
      this.$nuxt.$loading.finish()
    },
    async onAccept() {
      await this.$store.dispatch('onboarding/setLoading', true)
      this.$nuxt.$loading.start(true)
      try {
        await this.$store.dispatch('business/acceptInvitation', {
          idBusiness: this.getBusinessDetail.id,
          idUser: this.$auth.user.detail.id
        })
        await this.$auth.fetchUser()
        await Promise.all([
          this.$store.dispatch('common/refreshSidebar'),
          this.fetchDashboardData()
          // this.$store.dispatch('common/checkBusinessPhone', this.$auth.user.currentBusiness)
        ])
        await this.$store.dispatch('common/setInvitationShow', false)
        this.$emit('accept')
      } catch (error) {
        this.$errorHandler(error)
      }
      this.$store.dispatch('onboarding/setLoading', false)
      this.$nuxt.$loading.finish()
    }
  }
}
