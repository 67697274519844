var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"provider",staticClass:"flex items-center",attrs:{"vid":_vm.vid,"name":_vm.name || _vm.label,"rules":_vm.rules,"immediate":_vm.immediate,"detect-input":false,"tag":"fieldset"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isChecked),expression:"isChecked"}],staticClass:"hidden",attrs:{"id":_vm.id || ("" + _vm.name + (_vm.label ? ("-" + _vm.label) : '')),"disabled":_vm.disabled,"type":"checkbox","name":_vm.name},domProps:{"value":_vm.value,"checked":Array.isArray(_vm.isChecked)?_vm._i(_vm.isChecked,_vm.value)>-1:(_vm.isChecked)},on:{"change":function($event){var $$a=_vm.isChecked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isChecked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isChecked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isChecked=$$c}}}}),_vm._v(" "),(_vm.toggle)?_c('label',{staticClass:"relative cursor-pointer rounded-full",class:[
      _vm.small ? 'h-[10px] w-[18px] border-2' : 'border-[3px] h-[16px] w-[28px]',
      _vm.isChecked && !_vm.disabled ? _vm.checkedBorderColor : '',
      {
        'border-black-750 ': !_vm.isChecked && !_vm.disabled,
        'border-gray-350': !_vm.isChecked && _vm.disabled,
        'border-blue-50': _vm.isChecked && _vm.disabled,
        'border-red-600': !!errors[0]
      }
    ],attrs:{"for":_vm.id || ("" + _vm.name + (_vm.label ? ("-" + _vm.label) : ''))},on:{"click":_vm.onClick}},[_c('span',{staticClass:"absolute block transform rounded-full transition transition-all",class:[
        _vm.small
          ? 'top-[-0.75px] h-[8px] w-[8px]'
          : 'top-[-2.75px] h-[15.5px] w-[15.5px]',
        _vm.isChecked && !_vm.disabled ? _vm.checkedColor : '',
        !_vm.isChecked && !_vm.disabled && 'bg-black-750',
        _vm.isChecked && _vm.disabled && 'bg-blue-50',
        !_vm.isChecked && _vm.disabled && 'bg-gray-350',
        {
          'bg-red-600': !!errors[0],
          'translate-x-[9px]': !_vm.small && _vm.isChecked,
          'translate-x-[-3px]': !_vm.small && !_vm.isChecked,
          'right-[-1px]': _vm.small && _vm.isChecked,
          'left-[-1px]': _vm.small && !_vm.isChecked
        }
      ]})]):_c('label',{staticClass:"h-[16px] w-[16px] min-w-[16px] cursor-pointer rounded border-2 transition transition-all",class:{
      'border-black-200': !_vm.disabled && !_vm.isChecked,
      'border-primary-900 bg-primary-900': !_vm.disabled && _vm.isChecked,
      'border-black-300 bg-black-300': _vm.disabled && _vm.isChecked,
      'border-red-600': !!errors[0]
    },attrs:{"for":_vm.id || ("" + _vm.name + (_vm.label ? ("-" + _vm.label) : ''))}}),_vm._v(" "),_vm._t("default",function(){return [(_vm.label)?_c('label',{staticClass:"ml-3 cursor-pointer",class:[_vm.labelClass],attrs:{"for":_vm.id || ("" + _vm.name + (_vm.label ? ("-" + _vm.label) : ''))}},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")]):_vm._e()]})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }