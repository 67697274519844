var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{staticClass:"w-full",class:{ 'mb-[3px]': !_vm.hideMessage },attrs:{"vid":_vm.vid,"name":_vm.name || _vm.label || _vm.placeholder,"rules":_vm.rules,"immediate":_vm.immediate,"tag":"fieldset"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{attrs:{"name":"default"}},[_vm._t("label",function(){return [(_vm.label)?_c('label',{class:("block font-medium " + _vm.labelSize + " " + _vm.labelColor + " mb-[10px]"),attrs:{"for":_vm.idFix}},[_vm._v("\n        "+_vm._s(_vm.label)+"\n        "),(_vm.label && _vm.rules.split('|').includes('required'))?_c('span',{staticClass:"text-red-900",domProps:{"textContent":_vm._s('*')}}):_vm._e()]):_vm._e()]}),_vm._v(" "),_c('div',{staticClass:"flex w-full"},[_vm._t("prepend-outer"),_vm._v(" "),_c('div',{staticClass:"border-2 focus-within:border-primary relative flex w-full transform flex-wrap items-center overflow-hidden transition duration-100 ease-in-out",class:[
          _vm.disabled ? 'bg-black-75 text-black-500' : _vm.bg,
          { 'border-red-600': !!errors[0] },
          _vm.textSize,
          _vm.rounded
        ]},[_vm._t("prepend",function(){return [(_vm.prepend)?_c('div',{staticClass:"flex h-full bg-gray-200 px-3"},[_c('span',{staticClass:"whitespace-no-wrap text-grey-dark flex items-center leading-normal"},[_vm._v("\n              "+_vm._s(_vm.prepend)+"\n            ")])]):_vm._e()]}),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.tempValue),expression:"tempValue"}],staticClass:"min-h-10 relative flex-auto flex-1 flex-shrink flex-grow px-[12px] leading-normal focus:outline-none",class:[
            _vm.disabled ? 'bg-gray-50 text-black opacity-100' : _vm.bg,
            _vm.height,
            _vm.paddingY
          ],style:(_vm.textareaStyle),attrs:{"id":_vm.idFix,"rows":_vm.rows,"placeholder":_vm.placeholder,"name":_vm.name,"disabled":_vm.disabled,"readonly":_vm.readonly},domProps:{"value":(_vm.tempValue)},on:{"change":_vm.onChange,"input":function($event){if($event.target.composing){ return; }_vm.tempValue=$event.target.value}}}),_vm._v(" "),_vm._t("append",function(){return [(_vm.append)?_c('div',{staticClass:"flex h-full font-medium"},[_c('span',{staticClass:"whitespace-no-wrap text-grey-dark bg-black-75 flex items-center px-3 leading-normal"},[_vm._v("\n              "+_vm._s(_vm.append)+"\n            ")])]):_vm._e()]})],2)],2),_vm._v(" "),(
        (!_vm.hideMessage && !_vm.autoHideMessage) ||
        (_vm.autoHideMessage && errors.length > 0)
      )?_c('span',{staticClass:"break-word mt-[5px] mb-[5px] flex items-center text-red-900",class:[!errors[0] && 'h-[3px]', _vm.labelSize]},[(errors[0])?_c('SclvIcon',{staticClass:"mr-[8px] text-[13px] text-red-900",attrs:{"icon":"ic-exclamation"}}):_vm._e(),_vm._v("\n      "+_vm._s(errors[0] || ' ')+"\n    ")],1):_vm._e()],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }