import { mapGettersHelper, mapMutationsHelper } from '@/utils/helpers.js'

export const state = () => ({
  courierAggregators: [],
  businessCourierAggregators: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit }) {
    try {
      const url = `${this.$config.apiUrlV2}/courier-aggregator/`
      const res = await this.$axios.$get(url)
      commit('SET_COURIER_AGGREGATORS', res.data)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchAllOnBusiness({ commit }) {
    try {
      const url = `${this.$config.apiUrlV2}/business/courier-aggregator/`
      const res = await this.$axios.$get(url)
      commit('SET_BUSINESS_COURIER_AGGREGATORS', res.data)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchBalance({ commit }, id) {
    try {
      const url = `${this.$config.apiUrlV2}/business/courier-aggregator/${id}/balance`
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchByIdOnBusiness({ commit }, id) {
    try {
      const url = `${this.$config.apiUrlV2}/business/courier-aggregator/${id}`
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeDataOnBusiness({ commit }, payload) {
    const { courierAggregatorId, apiKey } = payload
    try {
      const url = `${this.$config.apiUrlV2}/business/courier-aggregator/`
      const res = await this.$axios.$post(url, {
        courier_aggregator_id: courierAggregatorId,
        api_key: apiKey
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async editOnBusiness({ commit }, payload) {
    const { id, courierAggregatorId, apiKey } = payload
    try {
      const url = `${this.$config.apiUrlV2}/business/courier-aggregator/${id}`
      const res = await this.$axios.$patch(url, {
        courier_aggregator_id: courierAggregatorId,
        api_key: apiKey
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async deleteOnBusiness({ commit }, id) {
    try {
      const url = `${this.$config.apiUrlV2}/business/courier-aggregator/${id}`
      const res = await this.$axios.$delete(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
