import { mapGettersHelper, mapMutationsHelper } from '@/utils/helpers.js'

export const state = () => ({
  birdsends: [],
  birdsendSequences: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit }) {
    try {
      const url = `${this.$config.apiUrlV2}/birdsend-integration/`
      const res = await this.$axios.$get(url)
      commit('SET_BIRDSENDS', res.data.results)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAllSequencesById({ commit }, id) {
    try {
      const url = `${this.$config.apiUrlV2}/birdsend-integration/${id}/sequences/`
      const res = await this.$axios.$get(url)

      const data = res.data.map((item) => {
        item.name = `${item.name} (${item.type})`
        return {
          ...item
        }
      })

      commit('SET_BIRDSEND_SEQUENCES', data)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async syncAndFetchAllSequencesById({ commit }, id) {
    try {
      const urlSync = `${this.$config.apiUrlV2}/birdsend-integration/${id}/sync/`
      await this.$axios.$patch(urlSync)
      const url = `${this.$config.apiUrlV2}/birdsend-integration/${id}/sequences/`
      const res = await this.$axios.$get(url)
      commit('SET_BIRDSEND_SEQUENCES', res.data)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeData({ commit }, payload) {
    const { code } = payload
    try {
      const url = `${this.$config.apiUrlV2}/birdsend-integration/`
      const res = await this.$axios.$post(url, {
        code
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async remove({ commit }, id) {
    try {
      const url = `${this.$config.apiUrlV2}/birdsend-integration/${id}/`
      const res = await this.$axios.$delete(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  resetSequences({ commit }) {
    commit('SET_BIRDSEND_SEQUENCES', [])
  }
}
