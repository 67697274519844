const mapPermissionsList = (permissions) => {
  let permissionsList = []

  Object.keys(permissions).forEach((key) => {
    if (permissions[key].general) {
      permissionsList = [...permissionsList, ...permissions[key].general]
    }

    if (permissions[key].retailer) {
      permissionsList = [...permissionsList, ...permissions[key].retailer]
    }

    if (permissions[key].fulfillment_provider) {
      permissionsList = [
        ...permissionsList,
        ...permissions[key].fulfillment_provider
      ]
    }
  })

  return [...new Set(permissionsList)]
}

export default (ctx, inject) => {
  const auth = {
    get user() {
      return ctx.store.getters['user/getUser']
    },
    fetchUser: async () => {
      try {
        const res = await ctx.store.dispatch('user/fetchDetail')
        const {
          // eslint-disable-next-line camelcase
          current_business_user,
          current_business: business,
          ...detailUser
        } = res
        let { ...account } =
          // eslint-disable-next-line camelcase
          current_business_user || {}
        account = {
          ...account,
          role: {
            ...(account?.role || {}),
            permissions_list: mapPermissionsList(
              account?.role?.permissions || []
            )
          }
        }
        // if (!business) {
        //   await ctx.store.dispatch('business/setBusiness', [])
        //   const allBusiness = await ctx.store.dispatch('business/fetchAll', {
        //     page: 1
        //   })
        //   if (allBusiness[0]) {
        //     await ctx.store.dispatch(
        //       'business/changeBusiness',
        //       allBusiness[0].id
        //     )
        //     const newResUser = await ctx.store.dispatch('user/fetchDetail')
        //     const {
        //       // eslint-disable-next-line camelcase
        //       current_business_user: new_current_business_user,
        //       ...newDetailUser
        //     } = newResUser
        //     detailUser = newDetailUser
        //     // eslint-disable-next-line camelcase
        //     current_business_user = {
        //       // eslint-disable-next-line camelcase
        //       ...new_current_business_user,
        //       business: newResUser.current_business
        //     }
        //     const { business: newBusiness, ...newAccount } =
        //       // eslint-disable-next-line camelcase
        //       new_current_business_user || {}
        //     business = newBusiness
        //     account = {
        //       ...newAccount,
        //       role: {
        //         ...newAccount.role,
        //         permissions_list: mapPermissionsList(
        //           newAccount.role.permissions
        //         )
        //       }
        //     }
        //   }
        // }
        let invitationStatus = account?.is_verified ? 'accepted' : 'invited'
        if (account?.is_registering) {
          invitationStatus = 'registering'
        }
        await ctx.store.dispatch('user/setUser', {
          currentBusiness: detailUser.current_business_id
            ? {
                idMember: account.id,
                invitationStatus,
                businessPhone: account.business_phone,
                isUserBusinessActive: !account.is_locked,
                subscription: detailUser.current_business_subscription,
                ...business
              }
            : {},
          detail: {
            ...detailUser,
            current_business: detailUser.current_business_id,
            current_business_subscription: detailUser.current_business_id
              ? {
                  ...detailUser.current_business_subscription,
                  current_pricing_plan: {
                    ...detailUser.current_business_subscription
                      .current_pricing_plan,
                    subscription_plan: {
                      ...detailUser.current_business_subscription
                        .current_pricing_plan.subscription_plan,
                      ...detailUser.current_business_subscription
                    }
                  }
                }
              : null
          },
          account
        })
      } catch (err) {
        await ctx.store.dispatch('user/setUser', null)
      }
    },
    logout: async () => {
      const at = ctx.$cookies.get(`at`)
      const rt = ctx.$cookies.get(`rt`)
      await ctx.store.dispatch('user/blacklistTokens', [at, rt])
      ctx.$cookies.set(`at`, '', {
        path: '/',
        maxAge: 1000 * 3600 * 24 * 30
      })
      ctx.$cookies.set(`rt`, '', {
        path: '/',
        maxAge: 1000 * 3600 * 24 * 30
      })
      ctx.$notif?.socket?.disconnect()
    }
  }

  inject('auth', auth)
}
