//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    textareaStyle: {
      type: String,
      default: ''
    },
    rows: {
      type: [String, Number],
      default: null
    },
    id: {
      type: String,
      default: undefined
    },
    bg: {
      type: String,
      default: 'bg-gray-150'
    },
    prepend: {
      type: String,
      default: ''
    },
    append: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    },
    immediate: {
      type: Boolean,
      default: false
    },
    hideMessage: {
      type: Boolean,
      default: false
    },
    autoHideMessage: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: undefined
    },
    height: {
      type: String,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    },
    labelColor: {
      type: String,
      default: 'text-gray-800'
    },
    labelSize: {
      type: String,
      default: 'text-[12px]'
    },
    textSize: {
      type: String,
      default: 'text-[14px]'
    },
    paddingY: {
      type: String,
      default: 'py-[8.5px]'
    },
    rounded: {
      type: String,
      default: 'rounded'
    }
  },
  data() {
    return {
      tempValue: this.value
    }
  },
  computed: {
    idFix() {
      return this.id || (this.name && this.label)
        ? `${this.name}-${this.label}`
        : ''
    }
  },
  watch: {
    value(val) {
      this.tempValue = val
    },
    tempValue(val) {
      this.$emit('input', val)
    }
  },
  methods: {
    onChange(val) {
      this.$emit('change', val)
    }
  }
}
