import isEmpty from 'lodash/isEmpty'

export default function ({ store, $auth, redirect, route }) {
  // const userPermission = store.getters['permission/getPermissions']
  // const isCanUpdatePlan =
  //   ![
  //     'setting-billing',
  //     'setting-billing-history',
  //     'setting-business',
  //     'setting',
  //     'setting-profile',
  //     'setting-profile-edit',
  //     'setting-profile-change-password'
  //   ].includes(route.name) && userPermission.includes('edit_business')

  if (
    [
      'dashboard',
      'setting-profile',
      'setting-profile-edit',
      'setting-profile-change-password'
    ].includes(route.name)
  ) {
    if (
      !isEmpty($auth.user.account) &&
      ((!$auth.user.account.is_verified && !$auth.user.account.is_locked) ||
        !$auth.user.account.is_registering) &&
      $auth.user.currentBusiness.invitationStatus === 'invited'
    ) {
      store.dispatch('common/setInvitationShow', true)
    }
    return
  }

  if (
    route.name === 'setting' &&
    !isEmpty($auth.user.account) &&
    (!$auth.user.account.is_verified ||
      $auth.user.account.is_locked ||
      $auth.user.account.is_registering ||
      $auth.user.currentBusiness.invitationStatus === 'invited')
  ) {
    redirect('/setting/profile')
  }

  if (
    ($auth.user.account.is_verified && $auth.user.account.is_locked) ||
    $auth.user.account.is_registering
  ) {
    redirect('/dashboard')
  } else if (
    !isEmpty($auth.user.account) &&
    ((!$auth.user.account.is_verified && !$auth.user.account.is_locked) ||
      !$auth.user.account.is_registering)
  ) {
    if ($auth.user.currentBusiness.invitationStatus === 'invited') {
      redirect('/dashboard')
    }
    if ($auth.user.currentBusiness.invitationStatus === 'invited') {
      store.dispatch('common/setInvitationShow', true)
    } else {
      // store.dispatch('common/checkBusinessPhone', $auth.user.currentBusiness)
      // if (isCanUpdatePlan) {
      //   await store.dispatch('subscription/fetchAll', {
      //     isFirst: true,
      //     query: { page_size: 1 }
      //   })
      //   store.dispatch('subscription/checkSubscriptionMiddleware', $auth.user.detail)
      // }
    }
  } else if (
    !isEmpty($auth.user.account) &&
    $auth.user.account.is_verified &&
    !$auth.user.account.is_locked
  ) {
    // store.dispatch('common/checkBusinessPhone', $auth.user.currentBusiness)
    // if (isCanUpdatePlan) {
    //   await store.dispatch('subscription/fetchAll', {
    //     isFirst: true,
    //     query: { page_size: 1 }
    //   })
    //   store.dispatch('subscription/checkSubscriptionMiddleware', $auth.user.detail)
    // }
  }
}
