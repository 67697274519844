//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Modal',
  props: {
    titleClass: {
      type: String,
      default: ''
    },
    closeOnClick: {
      type: Boolean,
      default: true
    },
    value: {
      type: Boolean,
      default: false
    },
    noFooter: {
      type: Boolean,
      default: false
    },
    isCancelMobileButton: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: 'w-full md:w-auto'
    },
    padding: {
      type: String,
      default: 'p-[16px]'
    },
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    labelOk: {
      type: String,
      default: 'Ok'
    },
    labelCancel: {
      type: String,
      default: 'Batal'
    },
    checker: {
      type: String,
      default: ''
    },
    fullOnMobile: {
      type: Boolean,
      default: false
    },
    hasFooterDivider: {
      type: Boolean,
      default: false
    },
    bg: {
      type: String,
      default: 'bg-white'
    },
    disableContentOverflow: {
      type: Boolean,
      default: false
    },
    mobileOnlyTitle: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    mobileOkDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShow: false,
      el: null
    }
  },
  watch: {
    '$route.path'() {
      const allLayoutModal = document
        .querySelector('#__layout')
        .children[0].__vue__.$children.filter(
          (x) => x.$vnode?.componentOptions?.tag === 'Modal'
        )
      const layoutModalIds = allLayoutModal.map((x) => x._uid)
      const modalWrapper = document.querySelector('#sclv-modal-wrapper')
      const modalWrapperChild = Array.from(modalWrapper.childNodes)
      // eslint-disable-next-line array-callback-return
      modalWrapperChild.map((modal) => {
        if (
          !modal.__vue__?._uid ||
          !layoutModalIds.includes(modal.__vue__._uid)
        ) {
          modalWrapper.removeChild(modal)
        }
      })
    },
    value(val) {
      this.isShow = val
    },
    isShow(val) {
      if (val) {
        document.body.classList.add('overflow-hidden')
        this.$nextTick(() => {
          if (!this.el && document.querySelector(`#modal-${this._uid}`)) {
            this.el = document.querySelector(`#modal-${this._uid}`)
            document.querySelector('#sclv-modal-wrapper').appendChild(this.el)
          }
        })
        this.$emit('shown')
      } else {
        document.body.classList.remove('overflow-hidden')
        this.$emit('closed')
      }
      this.$emit('input', val)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isShow = this.value
    })
  },
  methods: {
    onOk() {
      if (this.$mq === 'sm' && this.mobileOkDisabled) {
        return
      }
      this.$emit('on-ok')
    },
    onCancel() {
      this.isShow = false
      this.$emit('on-cancel')
    },
    onCancelMobile() {
      this.isShow = false
      if (this.checker === '') {
        this.$emit('on-cancel')
      } else {
        this.$emit('modal-cancel')
      }
    }
  }
}
