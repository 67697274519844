import {
  encodeQueryData,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  accounts: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, query = {}) {
    try {
      const queries = encodeQueryData(query)
      const res = await this.$axios.$get(
        `${this.$config.apiUrlV2}/pg-accounts/?${queries}`
      )
      commit('SET_ACCOUNTS', res.data)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchById({ commit, state }, id) {
    try {
      const res = await this.$axios.$get(
        `${this.$config.apiUrlV2}/pg-accounts/${id}`
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeData({ commit, state }, payload) {
    const { provider, duitkuMerchantCode, duitkuApiKey, midtransServerKey } =
      payload
    try {
      const res = await this.$axios.$post(
        `${this.$config.apiUrlV2}/pg-accounts/`,
        {
          provider,
          duitku_merchant_code: duitkuMerchantCode,
          duitku_api_key: duitkuApiKey,
          midtrans_server_key: midtransServerKey
        }
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async update({ commit, state }, payload) {
    const {
      id,
      provider,
      duitkuMerchantCode,
      duitkuApiKey,
      midtransServerKey
    } = payload
    try {
      const res = await this.$axios.$patch(
        `${this.$config.apiUrlV2}/pg-accounts/${id}`,
        {
          provider,
          duitku_merchant_code: duitkuMerchantCode,
          duitku_api_key: duitkuApiKey,
          midtrans_server_key: midtransServerKey
        }
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async delete({ commit, state }, id) {
    try {
      const res = await this.$axios.$delete(
        `${this.$config.apiUrlV2}/pg-accounts/${id}`
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
