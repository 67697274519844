//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getPricingPriceList } from '@/utils/helpers'

export default {
  props: {
    currentPlan: {
      type: Object,
      default: () => null
    },
    nextPlan: {
      type: Object,
      default: () => null
    },
    isCancel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isUnpaid:
        this.$auth.user.detail.current_business_subscription &&
        this.$auth.user.detail.current_business_subscription.status ===
          'unpaid',
      tabPaymentStep: 0,
      dataStepPadding: [
        {
          title: 'Desktop'
        },
        {
          title: 'Tablet'
        }
      ]
    }
  },
  computed: {
    priceList() {
      return getPricingPriceList(
        this.nextPlan.code.includes('monthly')
          ? 'monthly'
          : this.nextPlan.code.includes('_half_yearly')
          ? 'semiAnnually'
          : 'annually'
      )
    },
    currentPlanDetail() {
      return this.priceList.find((ls) => ls.code === this.currentPlan.code)
    },
    nextPlanDetail() {
      return this.priceList.find((ls) => ls.code === this.nextPlan.code)
    }
  },
  methods: {
    onCancel() {
      this.$nextTick(() => {
        this.$emit('cancel')
      })
    },
    onSave() {
      this.$emit('save')
    }
  }
}
