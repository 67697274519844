import findIndex from 'lodash/findIndex'
import mapKeys from 'lodash/mapKeys'
import snakeCase from 'lodash/snakeCase'
import cloneDeep from 'lodash/cloneDeep'
import map from 'lodash/map'
import kebabCase from 'lodash/kebabCase'
import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper,
  generateRandomString,
  remapEventParametersKeys,
  getFbEStandardEvents,
  getTiktokStandardEvents
} from '@/utils/helpers.js'

const randStr = generateRandomString()
let config = null

/* MAPPING FOR RETRIEVE START */
const mapPageDisplayMetaObj = (metaObj) => {
  if (!metaObj) {
    return {
      title: '',
      description: '',
      thumbnail: null,
      favicon: null,
      loadingLogo: null,
      isDisabledSearchEngineCrawler: false,
      isDisabledRightClick: false,
      lang: 'id'
    }
  }
  return {
    title: metaObj.title || '',
    description: metaObj.description || '',
    thumbnail: metaObj.thumbnail || null,
    favicon: metaObj.favicon || null,
    loadingLogo: metaObj.loadingLogo || null,
    isDisabledSearchEngineCrawler:
      metaObj.isDisabledSearchEngineCrawler || false,
    isDisabledRightClick: metaObj.isDisabledRightClick || false,
    lang: metaObj.lang || 'id'
  }
}
const mapPageDisplayGeneralObj = (generalObj) => {
  const defaultPagePadding = {
    desktop: {
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px'
    },
    tablet: {
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px'
    },
    mobile: {
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px'
    }
  }

  if (!generalObj) {
    return {
      layoutType: 'full_page',
      containerMaxWidth: '1200px',
      fontStyle: null,
      fontSize: 'normal',
      backgroundColor: '#F2F5FA',
      pageBackgroundColor: '#FFFFFF',
      sidebarPosition: 'no_sidebar',
      customHeadScript: '',
      isCustomHeadScript: false,
      pagePadding: defaultPagePadding,
      pageFullPadding: defaultPagePadding,
      pagePaddingWhenSidebar: defaultPagePadding,
      pageFullPaddingWhenSidebar: defaultPagePadding,
      gapDesktop: '0px',
      gapTablet: '0px',
      gapMobile: '0px',
      hasForm: false
    }
  }
  return {
    layoutType: generalObj.layout_type || 'full_page',
    containerMaxWidth: generalObj.container_max_width || '1200px',
    fontStyle: generalObj.font_style || null,
    fontSize: generalObj.font_size || 'normal',
    backgroundColor: generalObj.background_color || '#F2F5FA',
    pageBackgroundColor: generalObj.page_background_color || '#FFFFFF',
    sidebarPosition: generalObj.sidebar_position || 'no_sidebar',
    customHeadScript: generalObj.custom_head_script || '',
    isCustomHeadScript: generalObj.is_custom_head_script || false,
    pagePadding: cloneDeep(generalObj.page_padding || defaultPagePadding),
    pageFullPadding: cloneDeep(
      generalObj.page_full_padding ||
        generalObj.page_padding ||
        defaultPagePadding
    ),
    pagePaddingWhenSidebar: cloneDeep(
      generalObj.page_padding_when_sidebar || defaultPagePadding
    ),
    pageFullPaddingWhenSidebar: cloneDeep(
      generalObj.page_full_padding_when_sidebar ||
        generalObj.page_padding_when_sidebar ||
        defaultPagePadding
    ),
    gapDesktop: generalObj.gap_desktop || '0px',
    gapTablet: generalObj.gap_tablet || '0px',
    gapMobile: generalObj.gap_mobile || '0px',
    hasForm: generalObj.has_form || false
  }
}
const mapPageDisplayHeaderObj = (headerObj) => {
  if (!headerObj) {
    return {
      logo: '',
      title: '',
      subtitle: ''
    }
  }
  return {
    logo: headerObj.logo || '',
    title: headerObj.title || '',
    subtitle: headerObj.subtitle || ''
  }
}
const mapPageDisplayFormDisplayObj = (formDisplayData) => {
  const formPosition = formDisplayData.form_position

  if (!formPosition.includes('discount_code')) {
    const buttonIndex = formPosition.findIndex((x) => x === 'button')
    formPosition.splice(buttonIndex, 0, 'discount_code')
  }

  return {
    formTitle: formDisplayData.form_title,
    isName: formDisplayData.is_name,
    isNameRequired: formDisplayData.is_name_required,
    nameLabel: formDisplayData.name_label,
    namePlaceholder: formDisplayData.name_placeholder,
    phoneLabel: formDisplayData.phone_label,
    phonePlaceholder: formDisplayData.phone_placeholder,
    emailLabel: formDisplayData.email_label,
    emailPlaceholder: formDisplayData.email_placeholder,
    isPhone: formDisplayData.is_phone,
    isPhoneRequired: formDisplayData.is_phone_required,
    isEmail: formDisplayData.is_email,
    isEmailRequired: formDisplayData.is_email_required,
    locationType: formDisplayData.location_type,
    isLocationRequired: formDisplayData.is_location_required,
    isAddress: formDisplayData.is_address,
    addressLabel: formDisplayData.address_label,
    addressPlaceholder: formDisplayData.address_placeholder,
    isAddressRequired: formDisplayData.is_address_required,
    isCourierSearch: formDisplayData.is_courier_search,
    isCourierShown: formDisplayData.is_courier_shown,
    courierLabel: formDisplayData.courier_label,
    isNotes: formDisplayData.is_notes,
    notesLabel: formDisplayData.notes_label,
    notesPlaceholder: formDisplayData.notes_placeholder,
    isNotesRequired: formDisplayData.is_notes_required,
    isDiscountCode: formDisplayData.is_discount_code,
    discountCodeLabel: formDisplayData.discount_code_label,
    discountCodePlaceholder: formDisplayData.discount_code_placeholder,
    formPosition,
    store: formDisplayData.store?.id || null,
    storeObject: formDisplayData.store,
    afterSubmitEvent: formDisplayData.after_submit_event,
    customPhone: formDisplayData.custom_phone,
    otherPage: formDisplayData.other_page?.id || null,
    otherPageObject: formDisplayData.other_page,
    customUrl: formDisplayData.custom_url,
    handlerAssignment: formDisplayData.handler_assignment,
    storeSalesPerson: formDisplayData.store_sales_person?.id || null,
    storeSalesPersonObject: formDisplayData.store_sales_person,
    textForBankTransfer: formDisplayData.text_for_bank_transfer,
    textForCod: formDisplayData.text_for_cod,
    textForEPayment: formDisplayData.text_for_epayment,
    onsubmitFbEvents: formDisplayData.onsubmit_fb_events,
    onsubmitTiktokEvents: formDisplayData.onsubmit_tiktok_events,
    onsubmitKwaiClientEvents: formDisplayData.onsubmit_kwai_client_events || [],
    onsubmitKwaiServerEvents: formDisplayData.onsubmit_kwai_server_events || [],
    fbEventsOnSubmitParameters: formDisplayData.fb_events_onsubmit_parameters
      ? remapEventParametersKeys(
          formDisplayData.fb_events_onsubmit_parameters,
          getFbEStandardEvents(config.envApp)
        )
      : {},
    tiktokEventsOnSubmitParameters:
      formDisplayData.tiktok_events_onsubmit_parameters
        ? remapEventParametersKeys(
            formDisplayData.tiktok_events_onsubmit_parameters,
            getTiktokStandardEvents(config.envApp)
          )
        : {},
    kwaiClientEventsOnSubmitParameters:
      formDisplayData.kwai_client_events_onsubmit_parameters || {},
    kwaiServerEventsOnSubmitParameters:
      formDisplayData.kwai_server_events_onsubmit_parameters || {},
    defaultAdSource: formDisplayData.default_ad_source,
    isSummaryShown: formDisplayData.is_summary_shown,
    summaryLabel: formDisplayData.summary_label,
    isPriceStrike: formDisplayData.is_price_strike,
    isUpsell: formDisplayData.is_upsell,
    upsellType: formDisplayData.upsell_type,
    upsellBundle: formDisplayData.upsell_bundle_price_option?.id || null,
    upsellBundleObject: formDisplayData.upsell_bundle_price_option,
    upsellVariant: formDisplayData.upsell_variant?.id || null,
    upsellVariantObject: formDisplayData.upsell_variant,
    upsellDescription: formDisplayData.upsell_description,
    upsellImg: formDisplayData.upsell_img,
    isUpsellImgAutoslide: formDisplayData.is_upsell_img_autoslide,
    upsellImgDuration: formDisplayData.upsell_img_duration,
    buttonText: formDisplayData.button_text,
    buttonFontSize: formDisplayData.button_font_size,
    buttonColor: formDisplayData.button_color,
    isButtonIcon: formDisplayData.is_button_icon,
    buttonIcon: formDisplayData.button_icon,
    paymentMethodLabel: formDisplayData.payment_method_label,
    outboundDomain: formDisplayData.outbound_domain?.id || null,
    outboundDomainObject: formDisplayData.outbound_domain,
    isSendingEmailInvoice: formDisplayData.is_sending_email_invoice
  }
}
const mapPageDisplayFormItemsDisplayObj = (formDisplayData) => {
  return {
    isItemsShown: formDisplayData.is_items_shown,
    isMultipleItems: formDisplayData.is_multiple_items,
    itemsType: formDisplayData.items_type,
    itemsListType: formDisplayData.items_list_type,
    isItemsPriceShown: formDisplayData.is_items_price_shown,
    bundles: formDisplayData.bundle_price_options?.map((x) => x.id) || [],
    bundlesObjects: formDisplayData.bundle_price_options,
    variants: formDisplayData.variants?.map((x) => x.id) || [],
    variantsObjects: formDisplayData.variants,
    isItemsImagesShown: formDisplayData.is_items_images_shown,
    itemsTitle: formDisplayData.items_title,
    selectedItem: null,
    items: []
  }
}

const mapMainOrSidebarWidgetFinal = (
  mainOrSidebarObj,
  pageButtons,
  formDisplay = null
) => {
  const mapButton = (buttonId, buttonValues) => {
    const valueButtonIndex = findIndex(pageButtons, ['button_id', buttonId])
    if (valueButtonIndex > -1) {
      return {
        ...buttonValues,
        otherPage: pageButtons[valueButtonIndex].other_page?.id || null,
        otherPageObject: pageButtons[valueButtonIndex].other_page,
        customDomain: pageButtons[valueButtonIndex].custom_domain?.id || null,
        customDomainObject: pageButtons[valueButtonIndex].custom_domain
      }
    } else {
      const type = buttonValues.type

      return {
        ...buttonValues,
        type: type === 'otherPage' ? 'freelink' : type,
        otherPage: null,
        otherPageObject: null,
        customDomain: null,
        customDomainObject: null
      }
    }
  }

  const mapMainForm = (formId, formValues) => {
    return {
      ...mapPageDisplayFormDisplayObj(formDisplay),
      isLabelShown:
        formValues?.isLabelShown === undefined
          ? true
          : formValues?.isLabelShown,
      paymentMethodPosition:
        formValues?.paymentMethodPosition === undefined
          ? ['cod', 'bank_transfer', 'pg']
          : formValues?.paymentMethodPosition,
      isUpsellAutoOn:
        formValues?.isUpsellAutoOn === undefined
          ? false
          : formValues?.isUpsellAutoOn,
      upsellTogglePosition:
        formValues?.upsellTogglePosition === undefined
          ? 'top'
          : formValues?.upsellTogglePosition,
      upsellTextCustom:
        formValues?.upsellTextCustom === undefined
          ? ''
          : formValues?.upsellTextCustom,
      isPaymentMethod:
        formValues?.isPaymentMethod === undefined
          ? false
          : formValues?.isPaymentMethod,
      inactiveCourierCodes:
        formValues?.inactiveCourierCodes === undefined
          ? []
          : formValues?.inactiveCourierCodes,
      inactivePaymentMethods:
        formValues?.inactivePaymentMethods === undefined
          ? []
          : formValues?.inactivePaymentMethods,
      isUpsellShown:
        formValues?.isUpsellShown === undefined
          ? true
          : formValues?.isUpsellShown,
      successPageTitle:
        formValues?.successPageTitle === undefined
          ? 'Terima kasih sudah melakukan order {{product_name}}'
          : formValues?.successPageTitle,
      successPageBodyTextNoPayment:
        formValues?.successPageBodyTextNoPayment === undefined
          ? '<p class="ql-font-roboto"><span style="font-size: 14px;">Orderan anda sedang kami proses.</p>'
          : formValues?.successPageBodyTextNoPayment,
      successPageBodyTextBankTransfer:
        formValues?.successPageBodyTextBankTransfer === undefined
          ? '<p class="ql-font-roboto"><span style="font-size: 14px;">Untuk menyelesaikan order, silahkan transfer sejumlah</span></p><p class="ql-font-roboto"><span style="font-size: 14px;">{{total_price}}</span></p><p class="ql-font-roboto"><span style="font-size: 14px;">ke salah satu bank berikut ini:</span></p><p class="ql-font-roboto"><br></p><p class="ql-font-roboto"><span style="font-size: 14px;">{{payment_options}}</span></p><p class="ql-font-roboto"><br></p><p class="ql-font-roboto"><span style="font-size: 14px;">Konfirmasi pembayaran anda di: {{bank_transfer_confirmation}}</span></p>'
          : formValues?.successPageBodyTextBankTransfer,
      successPageBodyTextCod:
        formValues?.successPageBodyTextCod === undefined
          ? '<p class="ql-font-roboto"><span style="font-size: 14px;">Orderan anda kini kami proses, silahkan tunggu kedatangan kurir dalam 2-4 hari kedepan dan siapkan pembayaran senilai</span></p><p class="ql-font-roboto"><span style="font-size: 14px;">{{total_price}}</span></p><p class="ql-font-roboto"><span style="font-size: 14px;">untuk dibayarkan ke kurir langsung</span></p>'
          : formValues?.successPageBodyTextCod,
      successPageBodyTextEPayment:
        formValues?.successPageBodyTextEPayment === undefined
          ? '<p class="ql-font-roboto"><span style="font-size: 14px;">Untuk menyelesaikan order, silahkan transfer sejumlah</span></p><p class="ql-font-roboto"><span style="font-size: 14px;">{{total_price}}</span></p><p class="ql-font-roboto"><span style="font-size: 14px;">ke salah satu pilihan pembayaran berikut ini:</span></p><p class="ql-font-roboto"><br></p><p class="ql-font-roboto"><span style="font-size: 14px;">{{payment_options}}</span></p><p class="ql-font-roboto"><br></p><p class="ql-font-roboto"></p>'
          : formValues?.successPageBodyTextEPayment,
      isPaymentMethodShown:
        formValues?.isPaymentMethodShown === undefined
          ? true
          : formValues.isPaymentMethodShown,
      textColor:
        formValues?.textColor === undefined ? '#FFFFFF' : formValues.textColor,
      isPaymentMethodBankTransferExpanded:
        formValues?.isPaymentMethodBankTransferExpanded === undefined
          ? false
          : formValues.isPaymentMethodBankTransferExpanded
    }
  }

  const mapImgNImgSlider = (widgetType, imgValues, imgStyle) => {
    const styleNa = imgStyle
    const fixedStyle = {
      backgroundColor: styleNa.backgroundColor,
      backgroundImage: styleNa.backgroundImage
    }
    const valueNa = imgValues
    const widthFix = valueNa.width > 100 ? 748 : valueNa.width
    const widthCalced =
      valueNa.width > 100 ? 100 * (widthFix / 748) : valueNa.width
    let fixedVals = {
      ...valueNa,
      width: widthCalced || 100,
      ratio: valueNa.ratio || '4 / 3',
      justifyContent: valueNa.justifyContent || styleNa.alignSelf,
      objectFit: valueNa.objectFit || 'object-cover'
    }
    if (widgetType === 'img_slider') {
      const generatedAlts = valueNa.srcs.map((src) => {
        const urlArr = src?.split('/') || []
        return urlArr[urlArr.length - 1]?.replace(/(.png)|(.webp)$/, '')
      })
      fixedVals = {
        ...fixedVals,
        alts: valueNa.alts || generatedAlts
      }
      if (!fixedVals.configs) {
        const configs = []
        fixedVals.alts.forEach((x) => {
          configs.push({
            title: x,
            caption: '',
            clickEvent: 'none',
            to: null,
            isNewTab: false,
            otherPage: null,
            otherPageObject: null
          })
        })
        fixedVals.configs = configs
      }
      fixedVals.configs = fixedVals.configs.map((x) => {
        if (!x) {
          return {
            title: x,
            caption: '',
            clickEvent: 'none',
            to: null,
            isNewTab: false,
            otherPage: null,
            otherPageObject: null
          }
        }

        return {
          ...x,
          clickEvent:
            x.clickEvent === 'freelink' && !x.to ? 'none' : x.clickEvent,
          loadingType: x.loadingType === undefined ? 'lazy' : x.loadingType
        }
      })
      if (fixedVals.imageOpenType === undefined) {
        fixedVals.imageOpenType = 'gallery'
      }
      if (fixedVals.isCaptionShowInFront === undefined) {
        fixedVals.isCaptionShowInFront = false
      }
    } else if (widgetType === 'img') {
      const urlArr = valueNa.src?.split('/') || []
      const generatedAlt = urlArr[urlArr.length - 1]?.replace(
        /(.png)|(.webp)$/,
        ''
      )
      fixedVals = {
        ...fixedVals,
        alt: valueNa.alt || generatedAlt
      }

      if (!fixedVals.config) {
        fixedVals.config = {
          title: fixedVals.alt,
          caption: '',
          clickEvent: 'none',
          to: null,
          isNewTab: false,
          otherPage: null,
          otherPageObject: null
        }
      }

      if (fixedVals.config.loadingType === undefined) {
        fixedVals.config.loadingType = 'lazy'
      }
    }
    return {
      mappedValues: fixedVals,
      mappedStyle: fixedStyle
    }
  }

  const mapList = (widgetId, widgetValues) => {
    const values = widgetValues
    const contentTemp = {
      ...widgetValues,
      componentTitle:
        values.componentTitle === undefined
          ? values.sectionTitle
          : values.componentTitle
    }
    delete contentTemp.sectionTitle
    return contentTemp
  }

  const mapSectionContents = (contents = []) => {
    const contentsMapped = map(contents, (widget) => {
      widget.createdAt = new Date(widget.createdAt)
      if (widget.type === 'section') {
        widget.contents = mapSectionContents(widget.contents)
        widget.mode = widget.mode === undefined ? 'vertical' : widget.mode
        widget.arrangement =
          widget.arrangement === undefined ? 'responsive' : widget.arrangement
        widget.childWidthDesktop =
          widget.childWidthDesktop === undefined
            ? '250'
            : widget.childWidthDesktop
        widget.childWidthTablet =
          widget.childWidthTablet === undefined
            ? '250'
            : widget.childWidthTablet
        widget.childWidthMobile =
          widget.childWidthMobile === undefined
            ? '250'
            : widget.childWidthMobile
        widget.columnSizeDesktop =
          widget.columnSizeDesktop === undefined
            ? '3'
            : widget.columnSizeDesktop
        widget.columnSizeTablet =
          widget.columnSizeTablet === undefined ? '2' : widget.columnSizeTablet
        widget.columnSizeMobile =
          widget.columnSizeMobile === undefined ? '1' : widget.columnSizeMobile
        const style = widget.container.style
        widget.container.style = {
          ...style,
          gapDesktop: style.gapDesktop || style.gap,
          gapTablet: style.gapTablet || style.gap,
          gapMobile: style.gapMobile || style.gap,
          borderDesktop: style.borderDesktop || '0px',
          borderTablet: style.borderTablet || '0px',
          borderMobile: style.borderMobile || '0px',
          borderColor: style.borderColor || 'transparent',
          borderRadiusDesktop: style.borderRadiusDesktop || 'rounded-none',
          borderRadiusTablet: style.borderRadiusTablet || 'rounded-none',
          borderRadiusMobile: style.borderRadiusMobile || 'rounded-none',
          boxShadowDesktop:
            style.boxShadowDesktop || '0 0 0px rgba(0, 0, 0, 0.6)',
          boxShadowTablet:
            style.boxShadowTablet || '0 0 0px rgba(0, 0, 0, 0.6)',
          boxShadowMobile: style.boxShadowMobile || '0 0 0px rgba(0, 0, 0, 0.6)'
        }
        const margin = widget.container.margin
        widget.container.margin = margin || {
          mobile: {
            left: '0px',
            right: '0px',
            top: '0px',
            bottom: '0px'
          },
          tablet: {
            left: '0px',
            right: '0px',
            top: '0px',
            bottom: '0px'
          },
          desktop: {
            left: '0px',
            right: '0px',
            top: '0px',
            bottom: '0px'
          }
        }
        widget.titleOnTab =
          widget.mode === undefined ? 'Section' : widget.titleOnTab
        delete widget.container.style.gap
      } else if (widget.type === 'button') {
        widget.container.style.fontSize =
          widget.container.style.fontSize === '16px'
            ? 'normal'
            : widget.container.style.fontSize === '14px'
            ? 'smaller'
            : widget.container.style.fontSize === '18px'
            ? 'larger'
            : widget.container.style.fontSize

        widget.values = {
          ...widget.values,
          fbEvents:
            widget.values?.fbEvents !== undefined
              ? widget.values.fbEvents.length > 0 &&
                typeof widget.values.fbEvents[0] === 'object'
                ? widget.values.fbEvents.map((x) => x.event_name)
                : widget.values.fbEvents
              : [],
          tiktokEvents:
            widget.values?.tiktokEvents !== undefined
              ? widget.values.tiktokEvents.length > 0 &&
                typeof widget.values.tiktokEvents[0] === 'object'
                ? widget.values.tiktokEvents.map((x) => x.event_name)
                : widget.values.tiktokEvents
              : [],
          kwaiClientEvents: widget.values?.kwaiClientEvents || [],
          kwaiServerEvents: widget.values?.kwaiServerEvents || [],
          fbEventsParameters: widget.values.fbEventsParameters
            ? remapEventParametersKeys(
                widget.values.fbEventsParameters,
                getFbEStandardEvents(config.envApp)
              )
            : {},
          tiktokEventsParameters: widget.values.tiktokEventsParameters
            ? remapEventParametersKeys(
                widget.values.tiktokEventsParameters,
                getTiktokStandardEvents(config.envApp)
              )
            : {},
          kwaiClientEventsParameters:
            widget.values.kwaiClientEventsParameters || {},
          kwaiServerEventsParameters:
            widget.values.kwaiServerEventsParameters || {},
          otherPageQuery: widget.values.otherPageQuery || {}
        }

        widget.values = mapButton(widget.values.buttonId, widget.values)
      } else if (widget.type === 'main_form') {
        // Custom mapping Form
        widget.values = mapMainForm(widget.id, widget.values)
      } else if (widget.type === 'main_form_items') {
        // Custom mapping Form items
        widget.values = mapPageDisplayFormItemsDisplayObj(formDisplay)
      } else if (widget.type === 'img_slider' || widget.type === 'img') {
        const { mappedValues, mappedStyle } = mapImgNImgSlider(
          widget.type,
          widget.values,
          widget.container.style
        )
        widget.values = mappedValues
        widget.container.style = mappedStyle
      } else if (widget.type === 'list') {
        widget.values = mapList(widget.id, widget.values)
      } else if (widget.type === 'faq') {
        widget.values = {
          ...widget.values,
          componentTitle:
            widget.values.componentTitle === undefined
              ? 'FAQ'
              : widget.values.componentTitle
        }
      } else if (widget.type === 'testimony') {
        widget.values = {
          ...widget.values,
          componentTitle:
            widget.values.componentTitle === undefined
              ? 'Testimoni'
              : widget.values.componentTitle,
          testimonies: widget.values.testimonies.map((x) => {
            return {
              ...x,
              isRating: x.isRating === undefined ? false : x.isRating,
              rating: x.rating === undefined ? 0 : x.rating
            }
          })
        }
      }
      return widget
    })
    return contentsMapped
  }

  const mainOrSidebarObjTemp = [...mainOrSidebarObj]
  map(mainOrSidebarObjTemp, (rootWidget, sectionIndex) => {
    mainOrSidebarObjTemp[sectionIndex].createdAt = new Date(
      mainOrSidebarObjTemp[sectionIndex].createdAt
    )
    if (rootWidget.type === 'section') {
      mainOrSidebarObjTemp[sectionIndex].contents = mapSectionContents(
        rootWidget.contents
      )
      mainOrSidebarObjTemp[sectionIndex].mode =
        mainOrSidebarObjTemp[sectionIndex].mode === undefined
          ? 'vertical'
          : mainOrSidebarObjTemp[sectionIndex].mode
      mainOrSidebarObjTemp[sectionIndex].arrangement =
        mainOrSidebarObjTemp[sectionIndex].arrangement === undefined
          ? 'responsive'
          : mainOrSidebarObjTemp[sectionIndex].arrangement
      mainOrSidebarObjTemp[sectionIndex].childWidthDesktop =
        mainOrSidebarObjTemp[sectionIndex].childWidthDesktop === undefined
          ? '250'
          : mainOrSidebarObjTemp[sectionIndex].childWidthDesktop
      mainOrSidebarObjTemp[sectionIndex].childWidthTablet =
        mainOrSidebarObjTemp[sectionIndex].childWidthTablet === undefined
          ? '250'
          : mainOrSidebarObjTemp[sectionIndex].childWidthTablet
      mainOrSidebarObjTemp[sectionIndex].childWidthMobile =
        mainOrSidebarObjTemp[sectionIndex].childWidthMobile === undefined
          ? '250'
          : mainOrSidebarObjTemp[sectionIndex].childWidthMobile
      mainOrSidebarObjTemp[sectionIndex].columnSizeDesktop =
        mainOrSidebarObjTemp[sectionIndex].columnSizeDesktop === undefined
          ? '3'
          : mainOrSidebarObjTemp[sectionIndex].columnSizeDesktop
      mainOrSidebarObjTemp[sectionIndex].columnSizeTablet =
        mainOrSidebarObjTemp[sectionIndex].columnSizeTablet === undefined
          ? '2'
          : mainOrSidebarObjTemp[sectionIndex].columnSizeTablet
      mainOrSidebarObjTemp[sectionIndex].columnSizeMobile =
        mainOrSidebarObjTemp[sectionIndex].columnSizeMobile === undefined
          ? '1'
          : mainOrSidebarObjTemp[sectionIndex].columnSizeMobile
      const style = mainOrSidebarObjTemp[sectionIndex].container.style
      mainOrSidebarObjTemp[sectionIndex].container.style = {
        ...style,
        gapDesktop: style.gapDesktop || style.gap,
        gapTablet: style.gapTablet || style.gap,
        gapMobile: style.gapMobile || style.gap,
        borderDesktop: style.borderDesktop || '0px',
        borderTablet: style.borderTablet || '0px',
        borderMobile: style.borderMobile || '0px',
        borderColor: style.borderColor || 'transparent',
        borderRadiusDesktop: style.borderRadiusDesktop || 'rounded-none',
        borderRadiusTablet: style.borderRadiusTablet || 'rounded-none',
        borderRadiusMobile: style.borderRadiusMobile || 'rounded-none',
        boxShadowDesktop:
          style.boxShadowDesktop || '0 0 0px rgba(0, 0, 0, 0.6)',
        boxShadowTablet: style.boxShadowTablet || '0 0 0px rgba(0, 0, 0, 0.6)',
        boxShadowMobile: style.boxShadowMobile || '0 0 0px rgba(0, 0, 0, 0.6)'
      }
      const margin = mainOrSidebarObjTemp[sectionIndex].container.margin
      mainOrSidebarObjTemp[sectionIndex].container.margin = margin || {
        mobile: {
          left: '0px',
          right: '0px',
          top: '0px',
          bottom: '0px'
        },
        tablet: {
          left: '0px',
          right: '0px',
          top: '0px',
          bottom: '0px'
        },
        desktop: {
          left: '0px',
          right: '0px',
          top: '0px',
          bottom: '0px'
        }
      }
      mainOrSidebarObjTemp[sectionIndex].titleOnTab =
        mainOrSidebarObjTemp[sectionIndex].titleOnTab === undefined
          ? 'Section'
          : mainOrSidebarObjTemp[sectionIndex].titleOnTab
      delete mainOrSidebarObjTemp[sectionIndex].container.style.gap
    } else if (rootWidget.type === 'button') {
      rootWidget.container.style.fontSize =
        rootWidget.container.style.fontSize === '16px'
          ? 'normal'
          : rootWidget.container.style.fontSize === '14px'
          ? 'smaller'
          : rootWidget.container.style.fontSize === '18px'
          ? 'larger'
          : rootWidget.container.style.fontSize

      rootWidget.values = {
        ...rootWidget.values,
        fbEvents:
          rootWidget.values?.fbEvents !== undefined
            ? rootWidget.values.fbEvents.length > 0 &&
              typeof rootWidget.values.fbEvents[0] === 'object'
              ? rootWidget.values.fbEvents.map((x) => x.event_name)
              : rootWidget.values.fbEvents
            : [],
        tiktokEvents:
          rootWidget.values?.tiktokEvents !== undefined
            ? rootWidget.values.tiktokEvents.length > 0 &&
              typeof rootWidget.values.tiktokEvents[0] === 'object'
              ? rootWidget.values.tiktokEvents.map((x) => x.event_name)
              : rootWidget.values.tiktokEvents
            : [],
        kwaiClientEvents: rootWidget.values.kwaiClientEvents || [],
        kwaiServerEvents: rootWidget.values.kwaiServerEvents || [],
        fbEventsParameters: rootWidget.values.fbEventsParameters
          ? remapEventParametersKeys(
              rootWidget.values.fbEventsParameters,
              getFbEStandardEvents(config.envApp)
            )
          : {},
        tiktokEventsParameters: rootWidget.values.tiktokEventsParameters
          ? remapEventParametersKeys(
              rootWidget.values.tiktokEventsParameters,
              getTiktokStandardEvents(config.envApp)
            )
          : {},
        kwaiClientEventsParameters:
          rootWidget.values.kwaiClientEventsParameters || {},
        kwaiServerEventsParameters:
          rootWidget.values.kwaiServerEventsParameters || {},
        otherPageQuery: rootWidget.values.otherPageQuery || {}
      }

      rootWidget.values = mapButton(
        rootWidget.values.buttonId,
        rootWidget.values
      )
    } else if (rootWidget.type === 'main_form') {
      // Custom mapping Form
      rootWidget.values = mapMainForm(rootWidget.id, rootWidget.values)
    } else if (rootWidget.type === 'main_form_items') {
      // Custom mapping Form items
      rootWidget.values = mapPageDisplayFormItemsDisplayObj(formDisplay)
    } else if (rootWidget.type === 'img_slider' || rootWidget.type === 'img') {
      const { mappedValues, mappedStyle } = mapImgNImgSlider(
        rootWidget.type,
        rootWidget.values,
        rootWidget.container.style
      )
      rootWidget.values = mappedValues
      rootWidget.container.style = mappedStyle
    } else if (rootWidget.type === 'list') {
      rootWidget.values = mapList(rootWidget.id, rootWidget.values)
    } else if (rootWidget.type === 'faq') {
      rootWidget.values = {
        ...rootWidget.values,
        componentTitle:
          rootWidget.values.componentTitle === undefined
            ? 'FAQ'
            : rootWidget.values.componentTitle
      }
    } else if (rootWidget.type === 'testimony') {
      rootWidget.values = {
        ...rootWidget.values,
        componentTitle:
          rootWidget.values.componentTitle === undefined
            ? 'Testimoni'
            : rootWidget.values.componentTitle,
        testimonies: rootWidget.values.testimonies.map((x) => {
          return {
            ...x,
            isRating: x.isRating === undefined ? false : x.isRating,
            rating: x.rating === undefined ? 0 : x.rating
          }
        })
      }
    }
  })
  return mainOrSidebarObjTemp
}
const upVer1to1sub1 = (pageDiplayDate) => {
  pageDiplayDate.schema_version = 2
  const clonedMain = cloneDeep(pageDiplayDate.main)
  let hasForm = pageDiplayDate.general.has_form || false
  const fixedMain = clonedMain.filter((widgetMain) => !!widgetMain)
  const updatedMain = map(fixedMain, (section) => {
    const newName = section.name || section.label
    section = {
      index: section.index,
      id: section.id,
      name: newName,
      type: 'section',
      component: 'Section',
      ...section
    }
    section.contents = map(section.contents, (widget) => {
      widget.id = kebabCase(widget.type) + '-' + randStr()
      widget.name = widget.name || widget.title
      if (widget.type === 'button') {
        // REMAP BUTTON
        const oldValueList = [
          {
            color: '#192434',
            value:
              'bg-shade-900 hover:bg-black-950 active:bg-black-1100 ring-shade-200'
          },
          {
            color: '#0899CF',
            value:
              'bg-primary hover:bg-primary-900 active:bg-primary-300 ring-primary-200'
          },
          {
            color: '#00A344',
            value:
              'bg-green-200 hover:bg-green-600 active:bg-green-500 ring-green-100'
          },
          {
            color: '#ECA913',
            value:
              'bg-yellow-500 hover:bg-yellow-900 active:bg-yellow-600 ring-yellow-200'
          },
          {
            color: '#DF0E03',
            value: 'bg-red-600 hover:bg-red-900 active:bg-red-500 ring-red-200'
          }
        ]
        const newColor =
          oldValueList.find(
            (colorBtn) => colorBtn.value === widget.values.colorClass
          )?.color || widget.container.style.backgroundColor
        widget.container.style.backgroundColor = newColor
        widget.container.style.color =
          widget.container.style?.color || '#ffffff'
        delete widget.values.colorClass
      }
      return widget
    })
    hasForm = pageDiplayDate.general.has_form || section.hasForm || false
    delete section.label
    delete section.hasForm
    // hasForm = section.has_form || false
    return section
  })
  pageDiplayDate.main = updatedMain
  pageDiplayDate.general.has_form = hasForm
  return pageDiplayDate
}
const upVer = (pageDiplayDate) => {
  if (pageDiplayDate.schema_version === 1) {
    pageDiplayDate = upVer1to1sub1(pageDiplayDate)
  }
  pageDiplayDate = upVer1to1sub1(pageDiplayDate)
  return pageDiplayDate
}
const mapPageDisplayObj = (pageDiplayDate) => {
  pageDiplayDate = upVer(pageDiplayDate)
  return {
    id: pageDiplayDate.id,
    version: pageDiplayDate.version,
    schemaVersion: pageDiplayDate.schema_version,
    createdAt: pageDiplayDate.created_at,
    isPublished: pageDiplayDate.is_published,
    meta: mapPageDisplayMetaObj(pageDiplayDate.meta),
    general: mapPageDisplayGeneralObj(pageDiplayDate.general),
    header: mapPageDisplayHeaderObj(pageDiplayDate.header),
    banner: pageDiplayDate.banner || { type: 'guarantee' },
    sidebar:
      mapMainOrSidebarWidgetFinal(
        pageDiplayDate.sidebar,
        pageDiplayDate.page_buttons
      ) || [],
    main:
      mapMainOrSidebarWidgetFinal(
        pageDiplayDate.main,
        pageDiplayDate.page_buttons,
        pageDiplayDate.form_display
      ) || [],
    fbPixels: map(pageDiplayDate.fb_pixels, 'id'),
    fbPixelsObjects: pageDiplayDate.fb_pixels,
    onloadFbEvents: pageDiplayDate.onload_fb_events,
    tiktokPixels: map(pageDiplayDate.tiktok_pixels, 'id'),
    tiktokPixelsObjects: pageDiplayDate.tiktok_pixels,
    onloadTiktokEvents: pageDiplayDate.onload_tiktok_events,
    kwaiClientPixels: map(pageDiplayDate.kwai_client_pixels || [], 'id'),
    kwaiClientPixelsObjects: pageDiplayDate.kwai_client_pixels || [],
    onloadKwaiClientEvents: pageDiplayDate.onload_kwai_client_events || [],
    kwaiServerPixels: map(pageDiplayDate.kwai_server_pixels || [], 'id'),
    kwaiServerPixelsObjects: pageDiplayDate.kwai_server_pixels || [],
    onloadKwaiServerEvents: pageDiplayDate.onload_kwai_server_events || [],
    gtm: pageDiplayDate.gtm?.id || null,
    gtmObject: pageDiplayDate.gtm,
    fbEventsOnloadParameters: pageDiplayDate?.fb_events_onload_parameters
      ? remapEventParametersKeys(
          pageDiplayDate?.fb_events_onload_parameters,
          getFbEStandardEvents(config.envApp)
        )
      : {},
    tiktokEventsOnloadParameters:
      pageDiplayDate?.tiktok_events_onload_parameters
        ? remapEventParametersKeys(
            pageDiplayDate?.tiktok_events_onload_parameters,
            getTiktokStandardEvents(config.envApp)
          )
        : {},
    kwaiClientEventsOnloadParameters:
      pageDiplayDate?.kwai_client_events_onload_parameters || {},
    kwaiServerEventsOnloadParameters:
      pageDiplayDate?.kwai_server_events_onload_parameters || {}
  }
}
/* MAPPING FOR RETRIEVE END */

export const state = () => {
  return {
    pages: [],
    metaPages: {},
    pageDisplay: [],
    metaPageDisplay: {},
    tags: [],
    // dummy page & page display, example data from API
    page: {
      name: 'Sumbelehengge Form Utama'
    },
    currentHighlightedId: null,
    currentOnlineUsersOnEditor: {}
  }
}

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  setActiveCurrentOnlineUsersOnEditor({ commit }, payload) {
    commit('SET_CURRENT_ONLINE_USERS_ON_EDITOR', payload)
  },
  setCurrentHighlightedId({ commit }, id) {
    commit('SET_CURRENT_HIGHLIGHTED_ID', id)
  },

  expand({ commit, state }, index) {
    const pages = cloneDeep(state.pages)
    pages[index].isExpanded = true
    commit('SET_PAGES', pages)
  },

  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_PAGES', [])
      commit('SET_META_PAGES', {})
    }
    const { page, pageSize } = state.metaPages
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 25 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/page/?${queries}`
    try {
      const resPageList = await this.$axios.$get(url)
      const allPages = [...state.pages, ...resPageList.data.results].map(
        (x) => ({ ...x, isExpanded: false })
      )
      commit('SET_PAGES', allPages)
      let newMeta = {
        itemsLength: resPageList.data.count || resPageList.data.results.length
      }
      const nextMeta = resPageList.data.next
        ? decodeQueryData(resPageList.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_PAGES', newMeta)
      return { totalCount: resPageList.data.count, currentData: allPages }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchTags({ commit }) {
    try {
      const res = await this.$axios.$get(`/page/tags/`)
      commit('SET_TAGS', res.data)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async updateTags({ commit }, payload) {
    const { id, tags } = payload
    try {
      const res = await this.$axios.$patch(`/page/${id}/tags/`, {
        tags
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async delete(_store, idPage) {
    try {
      await this.$axios.$delete(`page/${idPage}/`)
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchById({ commit }, idPage) {
    config = this.$config

    const url = `page/${idPage}/`
    try {
      const res = await this.$axios.$get(url)
      return {
        business: {
          accountHolder: res.data.business.account_holder,
          id: res.data.business.id,
          uniqueId: res.data.business.unique_id,
          username: res.data.business.username
        },
        createdAt: res.data.created_at,
        createdBy: res.data.created_by,
        currentPageDisplay: res.data.current_page_display,
        id: res.data.id,
        isPublished: res.data.is_published,
        lastUpdatedAt: res.data.last_updated_at,
        lastUpdatedBy: res.data.last_updated_by,
        name: res.data.name,
        slug: res.data.slug,
        uuid: res.data.uuid,
        isHomepage: res.data.is_homepage,
        isDisabledCustomFont: res.data.is_disable_custom_font,
        isDisabledClientTagging: res.data.is_disable_client_tagging,
        customDomains: res.data.custom_domains,
        homepageCustomDomains: res.data.homepage_custom_domains,
        clientAnalyticsConfig: res.data.client_analytics_config || 'head',
        tags: res.data.tags,
        businessUsers: res.data.business_users
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchPublic({ commit }, { username, slug }) {
    config = this.$config

    const url = `page/public/${username}/${slug}/`
    try {
      const res = await this.$axios.$get(url)
      // eslint-disable-next-line camelcase
      const { id, current_page_display } = res.data
      return {
        id,
        // eslint-disable-next-line camelcase
        currentPageDisplay: mapPageDisplayObj(current_page_display.data)
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchPublicV3({ commit }, { username, slug }) {
    config = this.$config

    try {
      const url = `${this.$config.apiUrlV2}/page/public/${username}/${slug}/`
      const res = await this.$axios.$get(url)
      // eslint-disable-next-line camelcase
      const { id, uuid, current_page_display } = res.data
      return {
        id,
        uuid,
        currentPageDisplay: {
          // eslint-disable-next-line camelcase,
          ...current_page_display.data,
          // eslint-disable-next-line camelcase,
          ...mapPageDisplayObj(current_page_display.data)
        }
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchPublicV3ARecord({ commit }, { customDomain, slug }) {
    config = this.$config

    const url = `page/public-v2/${customDomain}/${slug}/`
    try {
      const res = await this.$axios.$get(url)
      // eslint-disable-next-line camelcase
      const { id, uuid, current_page_display } = res.data
      return {
        id,
        uuid,
        currentPageDisplay: {
          // eslint-disable-next-line camelcase,
          ...current_page_display.data,
          // eslint-disable-next-line camelcase,
          ...mapPageDisplayObj(current_page_display.data)
        }
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchPageDisplayById({ commit }, { idPage, idPageDisplay }) {
    config = this.$config

    const url = `page/${idPage}/page-display/${idPageDisplay}/`
    try {
      const res = await this.$axios.$get(url)
      const mapped = mapPageDisplayObj(res.data)
      return {
        ...res.data,
        ...mapped
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  fetchPageDisplayByJson({ commit }) {
    config = this.$config
    const data = JSON.parse(localStorage.getItem('lpJson') || {})
    const mapped = mapPageDisplayObj(data)
    return {
      ...data,
      ...mapped
    }
  },

  async fetchPageDisplay({ commit, state }, { id, isFirst = false, ...query }) {
    config = this.$config

    if (isFirst) {
      commit('SET_PAGE_DISPLAY', [])
      commit('SET_META_PAGE_DISPLAY', {})
    }
    const { page, pageSize } = state.metaPageDisplay
    const currentQueries = mapKeys(query, (v, k) => snakeCase(k))
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 25 : pageSize,
      ...currentQueries
    })
    const queries = encodeQueryData(initQuery)
    const url = `/page/${id}/page-display/?${queries}`
    try {
      const resPageDisplayList = await this.$axios.$get(url)
      const allPageDisplay = [
        ...state.pageDisplay,
        ...resPageDisplayList.data.results
      ]
      commit('SET_PAGE_DISPLAY', allPageDisplay)
      let newMeta = {
        itemsLength:
          resPageDisplayList.data.count ||
          resPageDisplayList.data.results.length
      }
      const nextMeta = resPageDisplayList.data.next
        ? decodeQueryData(resPageDisplayList.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_PAGE_DISPLAY', newMeta)
      return {
        totalCount: resPageDisplayList.data.count,
        currentData: allPageDisplay
      }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeData({ commit }, payload) {
    const {
      name,
      slug,
      isPublished,
      pageDisplay,
      isHomepage,
      isDisabledCustomFont,
      isDisabledClientTagging,
      customDomains,
      homepageCustomDomains,
      clientAnalyticsConfig,
      businessUsers
    } = payload
    try {
      const res = await this.$axios.$post('/page/', {
        name,
        slug,
        is_published: isPublished,
        page_display: mapPageDisplayToSubmitObj(pageDisplay),
        is_homepage: isHomepage,
        is_disable_custom_font: isDisabledCustomFont,
        is_disable_client_tagging: isDisabledClientTagging,
        custom_domains: customDomains,
        homepage_custom_domains: homepageCustomDomains,
        client_analytics_config: clientAnalyticsConfig,
        business_users: businessUsers
      })

      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async update(store, { id, ...payload }) {
    const {
      name,
      slug,
      isPublished,
      currentPageDisplay,
      isHomepage,
      isPinned,
      isDisabledCustomFont,
      isDisabledClientTagging,
      customDomains,
      homepageCustomDomains,
      clientAnalyticsConfig,
      businessUsers
    } = payload
    try {
      const res = await this.$axios.$patch(`/page/${id}/`, {
        name,
        slug,
        is_published: isPublished,
        current_page_display: isPublished ? currentPageDisplay : undefined,
        is_homepage: isHomepage,
        is_pinned: isPinned,
        is_disable_custom_font: isDisabledCustomFont,
        is_disable_client_tagging: isDisabledClientTagging,
        custom_domains: customDomains,
        homepage_custom_domains: homepageCustomDomains,
        client_analytics_config: clientAnalyticsConfig,
        business_users: businessUsers
      })

      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeDataPageDisplay({ commit }, payload) {
    const {
      name,
      slug,
      pageId,
      pageDisplay,
      isPublished,
      isHomepage,
      isDisabledCustomFont,
      isDisabledClientTagging,
      customDomains,
      homepageCustomDomains,
      clientAnalyticsConfig,
      businessUsers
    } = payload
    try {
      const res = await this.$axios.$post(
        `/page/${pageId}/page-display/`,
        mapPageDisplayToSubmitObj(pageDisplay)
      )

      await this.$axios.$patch(`/page/${pageId}/`, {
        name,
        slug,
        is_published: isPublished ? true : undefined,
        current_page_display: isPublished ? res.data.id : undefined,
        is_homepage: isHomepage,
        is_disable_custom_font: isDisabledCustomFont,
        is_disable_client_tagging: isDisabledClientTagging,
        custom_domains: customDomains,
        homepage_custom_domains: homepageCustomDomains,
        client_analytics_config: clientAnalyticsConfig,
        business_users: businessUsers
      })

      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async newSession({ commit }, payload) {
    const { sessionUuid, pageviewUuid, pageUuid, url, referer, eventType } =
      payload
    try {
      const res = await this.$axios({
        baseURL: this.$config.pageAnalyticApiUrl,
        url: '/session/',
        method: 'post',
        headers: {
          Authorization: `Bearer ${this.$config.pageAnalyticToken}`
        },
        data: {
          session_uuid: sessionUuid,
          pageview_uuid: pageviewUuid,
          page_uuid: pageUuid,
          event_type: eventType,
          url,
          referrer: referer
        }
      })
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  changeItemTags({ commit, state }, payload) {
    const { id, tags } = payload
    const currentData = cloneDeep(state.pages)
    const indexToChange = currentData.findIndex((x) => x.id === id)
    currentData[indexToChange].tags = tags
    commit('SET_PAGES', currentData)
  },

  async load({ commit }, file) {
    const formData = new FormData()
    formData.append('file', file)
    try {
      const res = await this.$axios({
        method: 'POST',
        baseURL: '/',
        url: '/server/load-lp-template',
        data: formData
      })
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async save({ commit }, id) {
    try {
      const res = await this.$axios({
        baseURL: '/',
        url: '/server/save-lp-template',
        method: 'POST',
        data: {
          id
        },
        responseType: 'blob'
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}

/* MAPPING FOR SUBMIT START */
const mapPageDisplayToSubmitObj = (pageDisplay) => {
  const pageDisplayFix = cloneDeep(pageDisplay)
  const pageHasForm = pageDisplay.general.hasForm
  let formDisplay
  if (pageHasForm) {
    let mainFormValues
    let mainFormItemsValues

    const findOnSection = (contents) => {
      for (const x of contents) {
        if (x.type === 'main_form') {
          mainFormValues = x.values
        }

        if (x.type === 'main_form_items') {
          mainFormItemsValues = x.values
        }

        if (x.type === 'section') {
          findOnSection(x.contents)
        }
      }
    }

    for (const x of pageDisplayFix.main) {
      if (x.type === 'main_form') {
        mainFormValues = x.values
      }

      if (x.type === 'main_form_items') {
        mainFormItemsValues = x.values
      }

      if (x.type === 'section') {
        findOnSection(x.contents)
      }
    }

    formDisplay = mapPageDisplayToSubmitFormDisplayObj({
      ...mainFormValues,
      ...mainFormItemsValues
    })

    const valuesTemp = mainFormValues

    // this will store into the JSON
    const excludeFromDelete = [
      'isLabelShown',
      'paymentMethodPosition',
      'isUpsellAutoOn',
      'upsellTogglePosition',
      'upsellTextCustom',
      'isPaymentMethod',
      'inactiveCourierCodes',
      'inactivePaymentMethods',
      'isUpsellShown',
      'successPageTitle',
      'successPageBodyTextNoPayment',
      'successPageBodyTextBankTransfer',
      'successPageBodyTextCod',
      'successPageBodyTextEPayment',
      'isPaymentMethodShown',
      'isPaymentMethodBankTransferExpanded',
      'textColor'
    ]

    Object.keys(valuesTemp).forEach((x) => {
      if (!excludeFromDelete.includes(x)) {
        delete valuesTemp[x]
      }
    })

    const replaceMainFormValueOnSection = (contents) => {
      contents.forEach((x) => {
        if (x.type === 'main_form') {
          x.values = valuesTemp
        }

        if (x.type === 'main_form_items') {
          delete x.values
        }

        if (x.type === 'section') {
          replaceMainFormValueOnSection(x.contents)
        }
      })
    }

    pageDisplayFix.main.forEach((x, i) => {
      if (x.type === 'main_form') {
        x.values = valuesTemp
      }

      if (x.type === 'main_form_items') {
        delete x.values
      }

      if (x.type === 'section') {
        replaceMainFormValueOnSection(x.contents)
      }
    })
  }
  const containerAndButton = pageButtonsMapper({
    main: pageDisplayFix.main,
    sidebar: pageDisplayFix.sidebar
  })

  return {
    schema_version: pageDisplayFix.schemaVersion,
    meta: pageDisplayFix.meta,
    general: mapPageDisplayToSubmitGeneralObj(pageDisplayFix.general),
    sidebar: containerAndButton.sidebar,
    main: containerAndButton.main,
    header: pageDisplayFix.header,
    banner: pageDisplayFix.banner,
    form_display: formDisplay,
    fb_pixels: pageDisplayFix.fbPixels,
    onload_fb_events: pageDisplayFix.onloadFbEvents,
    tiktok_pixels: pageDisplayFix.tiktokPixels,
    onload_tiktok_events: pageDisplayFix.onloadTiktokEvents,
    kwai_client_pixels: pageDisplayFix.kwaiClientPixels,
    onload_kwai_client_events: pageDisplayFix.onloadKwaiClientEvents,
    kwai_server_pixels: pageDisplayFix.kwaiServerPixels,
    onload_kwai_server_events: pageDisplayFix.onloadKwaiServerEvents,
    gtm: pageDisplayFix.gtm,
    page_buttons: containerAndButton.buttons,
    fb_events_onload_parameters: pageDisplayFix.fbEventsOnloadParameters,
    tiktok_events_onload_parameters:
      pageDisplayFix.tiktokEventsOnloadParameters,
    kwai_client_events_onload_parameters:
      pageDisplayFix.kwaiClientEventsOnloadParameters,
    kwai_server_events_onload_parameters:
      pageDisplayFix.kwaiServerEventsOnloadParameters
  }
}

const pageButtonsMapper = ({ main, sidebar }) => {
  const buttons = []
  const buttonRefactor = (widgetBtn) => {
    if (
      widgetBtn.values.buttonId &&
      (widgetBtn.values.otherPage || widgetBtn.values.customDomain)
    ) {
      buttons.push({
        button_id: widgetBtn.values.buttonId,
        other_page: widgetBtn.values.otherPage
          ? widgetBtn.values.otherPage
          : undefined,
        custom_domain: widgetBtn.values.customDomain
          ? widgetBtn.values.customDomain
          : undefined
      })
      delete widgetBtn.values.otherPage
      delete widgetBtn.values.otherPageObject
      delete widgetBtn.values.customDomain
      delete widgetBtn.values.customDomainObject
    }
    return widgetBtn
  }
  const buttonExtractor = (rootWidget) => {
    if (rootWidget.type === 'section') {
      const newWidgetContents = map(rootWidget.contents, (widget, i) => {
        if (widget.type === 'button') {
          return buttonRefactor(widget)
        } else if (widget.type === 'section') {
          return buttonExtractor(widget)
        } else {
          return widget
        }
      })
      return {
        ...rootWidget,
        contents: newWidgetContents
      }
    } else if (rootWidget.type === 'button') {
      return buttonRefactor(rootWidget)
    } else {
      return rootWidget
    }
  }
  const fixedMain = map(main, (rootWidget) => buttonExtractor(rootWidget))
  const fixedSidebar = map(sidebar, (rootWidget) => buttonExtractor(rootWidget))

  return { main: fixedMain, sidebar: fixedSidebar, buttons }
}

const mapPageDisplayToSubmitGeneralObj = (pageDisplayGeneral) => {
  return {
    layout_type: pageDisplayGeneral.layoutType,
    container_max_width: pageDisplayGeneral.containerMaxWidth,
    font_style: pageDisplayGeneral.fontStyle,
    font_size: pageDisplayGeneral.fontSize,
    page_background_color: pageDisplayGeneral.pageBackgroundColor,
    background_color: pageDisplayGeneral.backgroundColor,
    sidebar_position: pageDisplayGeneral.sidebarPosition,
    custom_head_script: pageDisplayGeneral.customHeadScript,
    is_custom_head_script: pageDisplayGeneral.isCustomHeadScript,
    page_padding: pageDisplayGeneral.pagePadding,
    page_full_padding: pageDisplayGeneral.pageFullPadding,
    page_padding_when_sidebar: pageDisplayGeneral.pagePaddingWhenSidebar,
    page_full_padding_when_sidebar:
      pageDisplayGeneral.pageFullPaddingWhenSidebar,
    gap_desktop: pageDisplayGeneral.gapDesktop,
    gap_tablet: pageDisplayGeneral.gapTablet,
    gap_mobile: pageDisplayGeneral.gapMobile,
    has_form: pageDisplayGeneral.hasForm
  }
}

const mapPageDisplayToSubmitFormDisplayObj = (pageDisplayFormDisplay) => {
  const map = {
    is_items_shown: pageDisplayFormDisplay.isItemsShown,
    is_multiple_items: pageDisplayFormDisplay.isMultipleItems,
    items_type: pageDisplayFormDisplay.itemsType,
    items_list_type: pageDisplayFormDisplay.itemsListType,
    is_items_price_shown: pageDisplayFormDisplay.isItemsPriceShown,
    bundle_price_options: pageDisplayFormDisplay.bundles,
    variants: pageDisplayFormDisplay.variants,
    is_items_images_shown: pageDisplayFormDisplay.isItemsImagesShown,
    items_title: pageDisplayFormDisplay.itemsTitle,
    form_title: pageDisplayFormDisplay.formTitle,
    is_name: pageDisplayFormDisplay.isName,
    is_name_required: pageDisplayFormDisplay.isNameRequired,
    name_label: pageDisplayFormDisplay.nameLabel,
    name_placeholder: pageDisplayFormDisplay.namePlaceholder,
    phone_label: pageDisplayFormDisplay.phoneLabel,
    phone_placeholder: pageDisplayFormDisplay.phonePlaceholder,
    is_phone: pageDisplayFormDisplay.isPhone,
    is_phone_required: pageDisplayFormDisplay.isPhoneRequired,
    email_label: pageDisplayFormDisplay.emailLabel,
    email_placeholder: pageDisplayFormDisplay.emailPlaceholder,
    is_email: pageDisplayFormDisplay.isEmail,
    is_email_required: pageDisplayFormDisplay.isEmailRequired,
    location_type: pageDisplayFormDisplay.locationType,
    is_location_required: pageDisplayFormDisplay.isLocationRequired,
    is_address: pageDisplayFormDisplay.isAddress,
    address_label: pageDisplayFormDisplay.addressLabel,
    address_placeholder: pageDisplayFormDisplay.addressPlaceholder,
    is_address_required: pageDisplayFormDisplay.isAddressRequired,
    is_courier_search: pageDisplayFormDisplay.isCourierSearch,
    is_courier_shown: pageDisplayFormDisplay.isCourierShown,
    courier_label: pageDisplayFormDisplay.courierLabel,
    is_notes: pageDisplayFormDisplay.isNotes,
    notes_label: pageDisplayFormDisplay.notesLabel,
    notes_placeholder: pageDisplayFormDisplay.notesPlaceholder,
    is_notes_required: pageDisplayFormDisplay.isNotesRequired,
    is_discount_code: pageDisplayFormDisplay.isDiscountCode,
    discount_code_label: pageDisplayFormDisplay.discountCodeLabel,
    discount_code_placeholder: pageDisplayFormDisplay.discountCodePlaceholder,
    form_position: pageDisplayFormDisplay.formPosition,
    store: pageDisplayFormDisplay.store,
    after_submit_event: pageDisplayFormDisplay.afterSubmitEvent,
    custom_phone: pageDisplayFormDisplay.customPhone,
    other_page: pageDisplayFormDisplay.otherPage,
    custom_url: pageDisplayFormDisplay.customUrl,
    handler_assignment: pageDisplayFormDisplay.handlerAssignment,
    store_sales_person: pageDisplayFormDisplay.storeSalesPerson,
    text_for_bank_transfer: pageDisplayFormDisplay.textForBankTransfer,
    text_for_cod: pageDisplayFormDisplay.textForCod,
    text_for_epayment: pageDisplayFormDisplay.textForEPayment,
    onsubmit_fb_events: pageDisplayFormDisplay.onsubmitFbEvents,
    onsubmit_tiktok_events: pageDisplayFormDisplay.onsubmitTiktokEvents,
    onsubmit_kwai_client_events:
      pageDisplayFormDisplay.onsubmitKwaiClientEvents,
    onsubmit_kwai_server_events:
      pageDisplayFormDisplay.onsubmitKwaiServerEvents,
    fb_events_onsubmit_parameters:
      pageDisplayFormDisplay.fbEventsOnSubmitParameters,
    tiktok_events_onsubmit_parameters:
      pageDisplayFormDisplay.tiktokEventsOnSubmitParameters,
    kwai_client_events_onsubmit_parameters:
      pageDisplayFormDisplay.kwaiServerEventsOnSubmitParameters,
    kwai_server_events_onsubmit_parameters:
      pageDisplayFormDisplay.kwaiServerEventsOnSubmitParameters,
    default_ad_source: pageDisplayFormDisplay.defaultAdSource,
    is_summary_shown: pageDisplayFormDisplay.isSummaryShown,
    summary_label: pageDisplayFormDisplay.summaryLabel,
    is_price_strike: pageDisplayFormDisplay.isPriceStrike,
    is_upsell: pageDisplayFormDisplay.isUpsell,
    upsell_type: pageDisplayFormDisplay.upsellType,
    upsell_bundle_price_option: pageDisplayFormDisplay.upsellBundle,
    upsell_variant: pageDisplayFormDisplay.upsellVariant,
    upsell_description: pageDisplayFormDisplay.upsellDescription,
    upsell_img: pageDisplayFormDisplay.upsellImg,
    is_upsell_img_autoslide: pageDisplayFormDisplay.isUpsellImgAutoslide,
    upsell_img_duration: pageDisplayFormDisplay.upsellImgDuration,
    button_text: pageDisplayFormDisplay.buttonText,
    button_font_size: pageDisplayFormDisplay.buttonFontSize,
    button_color: pageDisplayFormDisplay.buttonColor,
    is_button_icon: pageDisplayFormDisplay.isButtonIcon,
    button_icon: pageDisplayFormDisplay.buttonIcon,
    payment_method_label: pageDisplayFormDisplay.paymentMethodLabel,
    outbound_domain: pageDisplayFormDisplay.outboundDomain,
    is_sending_email_invoice: pageDisplayFormDisplay.isSendingEmailInvoice
  }
  return Object.fromEntries(Object.entries(map).filter(([_, v]) => v !== null))
}

/* MAPPING FOR SUBMIT END */
