export const AffiliatorTocContent = () => import('../../components/common/AffiliatorTocContent.vue' /* webpackChunkName: "components/affiliator-toc-content" */).then(c => wrapFunctional(c.default || c))
export const Alert = () => import('../../components/common/Alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const CompanySelector = () => import('../../components/common/CompanySelector.vue' /* webpackChunkName: "components/company-selector" */).then(c => wrapFunctional(c.default || c))
export const CurrencyField = () => import('../../components/common/CurrencyField.vue' /* webpackChunkName: "components/currency-field" */).then(c => wrapFunctional(c.default || c))
export const LoadingBar = () => import('../../components/common/LoadingBar.vue' /* webpackChunkName: "components/loading-bar" */).then(c => wrapFunctional(c.default || c))
export const MainHeader = () => import('../../components/common/MainHeader.vue' /* webpackChunkName: "components/main-header" */).then(c => wrapFunctional(c.default || c))
export const OnboardStatus = () => import('../../components/common/OnboardStatus.vue' /* webpackChunkName: "components/onboard-status" */).then(c => wrapFunctional(c.default || c))
export const SclvAccordeon = () => import('../../components/common/SclvAccordeon.vue' /* webpackChunkName: "components/sclv-accordeon" */).then(c => wrapFunctional(c.default || c))
export const SclvAvatar = () => import('../../components/common/SclvAvatar.vue' /* webpackChunkName: "components/sclv-avatar" */).then(c => wrapFunctional(c.default || c))
export const SclvBuildText = () => import('../../components/common/SclvBuildText.vue' /* webpackChunkName: "components/sclv-build-text" */).then(c => wrapFunctional(c.default || c))
export const SclvButton = () => import('../../components/common/SclvButton.vue' /* webpackChunkName: "components/sclv-button" */).then(c => wrapFunctional(c.default || c))
export const SclvChart = () => import('../../components/common/SclvChart.vue' /* webpackChunkName: "components/sclv-chart" */).then(c => wrapFunctional(c.default || c))
export const SclvCheckBox = () => import('../../components/common/SclvCheckBox.vue' /* webpackChunkName: "components/sclv-check-box" */).then(c => wrapFunctional(c.default || c))
export const SclvCollections = () => import('../../components/common/SclvCollections.vue' /* webpackChunkName: "components/sclv-collections" */).then(c => wrapFunctional(c.default || c))
export const SclvColorPicker = () => import('../../components/common/SclvColorPicker.vue' /* webpackChunkName: "components/sclv-color-picker" */).then(c => wrapFunctional(c.default || c))
export const SclvDate = () => import('../../components/common/SclvDate.vue' /* webpackChunkName: "components/sclv-date" */).then(c => wrapFunctional(c.default || c))
export const SclvDropdown = () => import('../../components/common/SclvDropdown.vue' /* webpackChunkName: "components/sclv-dropdown" */).then(c => wrapFunctional(c.default || c))
export const SclvForm = () => import('../../components/common/SclvForm.vue' /* webpackChunkName: "components/sclv-form" */).then(c => wrapFunctional(c.default || c))
export const SclvFullscreenImages = () => import('../../components/common/SclvFullscreenImages.vue' /* webpackChunkName: "components/sclv-fullscreen-images" */).then(c => wrapFunctional(c.default || c))
export const SclvIcon = () => import('../../components/common/SclvIcon.vue' /* webpackChunkName: "components/sclv-icon" */).then(c => wrapFunctional(c.default || c))
export const SclvImage = () => import('../../components/common/SclvImage.vue' /* webpackChunkName: "components/sclv-image" */).then(c => wrapFunctional(c.default || c))
export const SclvImgSlider = () => import('../../components/common/SclvImgSlider.vue' /* webpackChunkName: "components/sclv-img-slider" */).then(c => wrapFunctional(c.default || c))
export const SclvIntersect = () => import('../../components/common/SclvIntersect.vue' /* webpackChunkName: "components/sclv-intersect" */).then(c => wrapFunctional(c.default || c))
export const SclvLottie = () => import('../../components/common/SclvLottie.vue' /* webpackChunkName: "components/sclv-lottie" */).then(c => wrapFunctional(c.default || c))
export const SclvModal = () => import('../../components/common/SclvModal.vue' /* webpackChunkName: "components/sclv-modal" */).then(c => wrapFunctional(c.default || c))
export const SclvModalSelection = () => import('../../components/common/SclvModalSelection.vue' /* webpackChunkName: "components/sclv-modal-selection" */).then(c => wrapFunctional(c.default || c))
export const SclvMultiImages = () => import('../../components/common/SclvMultiImages.vue' /* webpackChunkName: "components/sclv-multi-images" */).then(c => wrapFunctional(c.default || c))
export const SclvMultiSelect = () => import('../../components/common/SclvMultiSelect.vue' /* webpackChunkName: "components/sclv-multi-select" */).then(c => wrapFunctional(c.default || c))
export const SclvRadio = () => import('../../components/common/SclvRadio.vue' /* webpackChunkName: "components/sclv-radio" */).then(c => wrapFunctional(c.default || c))
export const SclvRange = () => import('../../components/common/SclvRange.vue' /* webpackChunkName: "components/sclv-range" */).then(c => wrapFunctional(c.default || c))
export const SclvRating = () => import('../../components/common/SclvRating.vue' /* webpackChunkName: "components/sclv-rating" */).then(c => wrapFunctional(c.default || c))
export const SclvRichTextViewer = () => import('../../components/common/SclvRichTextViewer.vue' /* webpackChunkName: "components/sclv-rich-text-viewer" */).then(c => wrapFunctional(c.default || c))
export const SclvSearchOption = () => import('../../components/common/SclvSearchOption.vue' /* webpackChunkName: "components/sclv-search-option" */).then(c => wrapFunctional(c.default || c))
export const SclvSelect = () => import('../../components/common/SclvSelect.vue' /* webpackChunkName: "components/sclv-select" */).then(c => wrapFunctional(c.default || c))
export const SclvSelectMultiple = () => import('../../components/common/SclvSelectMultiple.vue' /* webpackChunkName: "components/sclv-select-multiple" */).then(c => wrapFunctional(c.default || c))
export const SclvShimmer = () => import('../../components/common/SclvShimmer.vue' /* webpackChunkName: "components/sclv-shimmer" */).then(c => wrapFunctional(c.default || c))
export const SclvTab = () => import('../../components/common/SclvTab.vue' /* webpackChunkName: "components/sclv-tab" */).then(c => wrapFunctional(c.default || c))
export const SclvTextEditor = () => import('../../components/common/SclvTextEditor.vue' /* webpackChunkName: "components/sclv-text-editor" */).then(c => wrapFunctional(c.default || c))
export const SclvTextarea = () => import('../../components/common/SclvTextarea.vue' /* webpackChunkName: "components/sclv-textarea" */).then(c => wrapFunctional(c.default || c))
export const SclvTooltip = () => import('../../components/common/SclvTooltip.vue' /* webpackChunkName: "components/sclv-tooltip" */).then(c => wrapFunctional(c.default || c))
export const SclvUploadFile = () => import('../../components/common/SclvUploadFile.vue' /* webpackChunkName: "components/sclv-upload-file" */).then(c => wrapFunctional(c.default || c))
export const SclvUploadImage = () => import('../../components/common/SclvUploadImage.vue' /* webpackChunkName: "components/sclv-upload-image" */).then(c => wrapFunctional(c.default || c))
export const SimpleHeader = () => import('../../components/common/SimpleHeader.vue' /* webpackChunkName: "components/simple-header" */).then(c => wrapFunctional(c.default || c))
export const TextField = () => import('../../components/common/TextField.vue' /* webpackChunkName: "components/text-field" */).then(c => wrapFunctional(c.default || c))
export const TransitionExpand = () => import('../../components/common/TransitionExpand.vue' /* webpackChunkName: "components/transition-expand" */).then(c => wrapFunctional(c.default || c))
export const VDropdown = () => import('../../components/common/VDropdown.vue' /* webpackChunkName: "components/v-dropdown" */).then(c => wrapFunctional(c.default || c))
export const VTooltip = () => import('../../components/common/VTooltip.vue' /* webpackChunkName: "components/v-tooltip" */).then(c => wrapFunctional(c.default || c))
export const MobileHeader = () => import('../../components/common/Mobile/Header.vue' /* webpackChunkName: "components/mobile-header" */).then(c => wrapFunctional(c.default || c))
export const MobileListBusiness = () => import('../../components/common/Mobile/ListBusiness.vue' /* webpackChunkName: "components/mobile-list-business" */).then(c => wrapFunctional(c.default || c))
export const MobileNavbar = () => import('../../components/common/Mobile/Navbar.vue' /* webpackChunkName: "components/mobile-navbar" */).then(c => wrapFunctional(c.default || c))
export const MobileNavbarMore = () => import('../../components/common/Mobile/NavbarMore.vue' /* webpackChunkName: "components/mobile-navbar-more" */).then(c => wrapFunctional(c.default || c))
export const MobileResetPassword = () => import('../../components/common/Mobile/ResetPassword.vue' /* webpackChunkName: "components/mobile-reset-password" */).then(c => wrapFunctional(c.default || c))
export const MobileSettingMenu = () => import('../../components/common/Mobile/SettingMenu.vue' /* webpackChunkName: "components/mobile-setting-menu" */).then(c => wrapFunctional(c.default || c))
export const MobileSlider = () => import('../../components/common/Mobile/Slider.vue' /* webpackChunkName: "components/mobile-slider" */).then(c => wrapFunctional(c.default || c))
export const NotificationItem = () => import('../../components/common/Notification/Item.vue' /* webpackChunkName: "components/notification-item" */).then(c => wrapFunctional(c.default || c))
export const NotificationPopup = () => import('../../components/common/Notification/Popup.vue' /* webpackChunkName: "components/notification-popup" */).then(c => wrapFunctional(c.default || c))
export const BuilderCanvasWrapper = () => import('../../components/Builder/CanvasWrapper.vue' /* webpackChunkName: "components/builder-canvas-wrapper" */).then(c => wrapFunctional(c.default || c))
export const BuilderConfig = () => import('../../components/Builder/Config.vue' /* webpackChunkName: "components/builder-config" */).then(c => wrapFunctional(c.default || c))
export const BuilderMarginSetting = () => import('../../components/Builder/MarginSetting.vue' /* webpackChunkName: "components/builder-margin-setting" */).then(c => wrapFunctional(c.default || c))
export const BuilderPaddingSetting = () => import('../../components/Builder/PaddingSetting.vue' /* webpackChunkName: "components/builder-padding-setting" */).then(c => wrapFunctional(c.default || c))
export const BuilderSectionStyleEditor = () => import('../../components/Builder/SectionStyleEditor.vue' /* webpackChunkName: "components/builder-section-style-editor" */).then(c => wrapFunctional(c.default || c))
export const BuilderSettingWrapperPopup = () => import('../../components/Builder/SettingWrapperPopup.vue' /* webpackChunkName: "components/builder-setting-wrapper-popup" */).then(c => wrapFunctional(c.default || c))
export const Buttons = () => import('../../components/Buttons/index.vue' /* webpackChunkName: "components/buttons" */).then(c => wrapFunctional(c.default || c))
export const CustomModalSelectionBundlePriceOptions = () => import('../../components/CustomModalSelection/BundlePriceOptions.vue' /* webpackChunkName: "components/custom-modal-selection-bundle-price-options" */).then(c => wrapFunctional(c.default || c))
export const CustomModalSelectionBundlePriceOptionsRadio = () => import('../../components/CustomModalSelection/BundlePriceOptionsRadio.vue' /* webpackChunkName: "components/custom-modal-selection-bundle-price-options-radio" */).then(c => wrapFunctional(c.default || c))
export const CustomModalSelectionBundlePriceOptionsRadioSingle = () => import('../../components/CustomModalSelection/BundlePriceOptionsRadioSingle.vue' /* webpackChunkName: "components/custom-modal-selection-bundle-price-options-radio-single" */).then(c => wrapFunctional(c.default || c))
export const CustomModalSelectionCourier = () => import('../../components/CustomModalSelection/Courier.vue' /* webpackChunkName: "components/custom-modal-selection-courier" */).then(c => wrapFunctional(c.default || c))
export const CustomModalSelectionPayment = () => import('../../components/CustomModalSelection/Payment.vue' /* webpackChunkName: "components/custom-modal-selection-payment" */).then(c => wrapFunctional(c.default || c))
export const CustomModalSelectionProductAndBundleByStoreId = () => import('../../components/CustomModalSelection/ProductAndBundleByStoreId.vue' /* webpackChunkName: "components/custom-modal-selection-product-and-bundle-by-store-id" */).then(c => wrapFunctional(c.default || c))
export const CustomModalSelectionProductsAndBundles = () => import('../../components/CustomModalSelection/ProductsAndBundles.vue' /* webpackChunkName: "components/custom-modal-selection-products-and-bundles" */).then(c => wrapFunctional(c.default || c))
export const CustomModalSelectionSalesPerson = () => import('../../components/CustomModalSelection/SalesPerson.vue' /* webpackChunkName: "components/custom-modal-selection-sales-person" */).then(c => wrapFunctional(c.default || c))
export const CustomModalSelectionStoreTeam = () => import('../../components/CustomModalSelection/StoreTeam.vue' /* webpackChunkName: "components/custom-modal-selection-store-team" */).then(c => wrapFunctional(c.default || c))
export const CustomModalSelectionVariantsProduct = () => import('../../components/CustomModalSelection/VariantsProduct.vue' /* webpackChunkName: "components/custom-modal-selection-variants-product" */).then(c => wrapFunctional(c.default || c))
export const CustomModalSelectionVariantsProductSingle = () => import('../../components/CustomModalSelection/VariantsProductSingle.vue' /* webpackChunkName: "components/custom-modal-selection-variants-product-single" */).then(c => wrapFunctional(c.default || c))
export const FormAccountDelete = () => import('../../components/Form/AccountDelete.vue' /* webpackChunkName: "components/form-account-delete" */).then(c => wrapFunctional(c.default || c))
export const FormAccountEdit = () => import('../../components/Form/AccountEdit.vue' /* webpackChunkName: "components/form-account-edit" */).then(c => wrapFunctional(c.default || c))
export const FormAccountPassword = () => import('../../components/Form/AccountPassword.vue' /* webpackChunkName: "components/form-account-password" */).then(c => wrapFunctional(c.default || c))
export const FormBusinessAccountPhone = () => import('../../components/Form/BusinessAccountPhone.vue' /* webpackChunkName: "components/form-business-account-phone" */).then(c => wrapFunctional(c.default || c))
export const FormBusinessAdd = () => import('../../components/Form/BusinessAdd.vue' /* webpackChunkName: "components/form-business-add" */).then(c => wrapFunctional(c.default || c))
export const FormBusinessChangeOwnership = () => import('../../components/Form/BusinessChangeOwnership.vue' /* webpackChunkName: "components/form-business-change-ownership" */).then(c => wrapFunctional(c.default || c))
export const FormBusinessDelete = () => import('../../components/Form/BusinessDelete.vue' /* webpackChunkName: "components/form-business-delete" */).then(c => wrapFunctional(c.default || c))
export const FormBusinessEdit = () => import('../../components/Form/BusinessEdit.vue' /* webpackChunkName: "components/form-business-edit" */).then(c => wrapFunctional(c.default || c))
export const FormBusinessLeave = () => import('../../components/Form/BusinessLeave.vue' /* webpackChunkName: "components/form-business-leave" */).then(c => wrapFunctional(c.default || c))
export const FormFbOrGtmAdd = () => import('../../components/Form/FbOrGtmAdd.vue' /* webpackChunkName: "components/form-fb-or-gtm-add" */).then(c => wrapFunctional(c.default || c))
export const FormFbOrGtmEdit = () => import('../../components/Form/FbOrGtmEdit.vue' /* webpackChunkName: "components/form-fb-or-gtm-edit" */).then(c => wrapFunctional(c.default || c))
export const FormMarketingMemberAdd = () => import('../../components/Form/MarketingMemberAdd.vue' /* webpackChunkName: "components/form-marketing-member-add" */).then(c => wrapFunctional(c.default || c))
export const FormMarketingMemberDelete = () => import('../../components/Form/MarketingMemberDelete.vue' /* webpackChunkName: "components/form-marketing-member-delete" */).then(c => wrapFunctional(c.default || c))
export const FormMarketingMemberSuspend = () => import('../../components/Form/MarketingMemberSuspend.vue' /* webpackChunkName: "components/form-marketing-member-suspend" */).then(c => wrapFunctional(c.default || c))
export const FormMarketingPackage = () => import('../../components/Form/MarketingPackage.vue' /* webpackChunkName: "components/form-marketing-package" */).then(c => wrapFunctional(c.default || c))
export const FormMarketingPackageDelete = () => import('../../components/Form/MarketingPackageDelete.vue' /* webpackChunkName: "components/form-marketing-package-delete" */).then(c => wrapFunctional(c.default || c))
export const FormMembershipReward = () => import('../../components/Form/MembershipReward.vue' /* webpackChunkName: "components/form-membership-reward" */).then(c => wrapFunctional(c.default || c))
export const FormMembershipRewardDelete = () => import('../../components/Form/MembershipRewardDelete.vue' /* webpackChunkName: "components/form-membership-reward-delete" */).then(c => wrapFunctional(c.default || c))
export const FormNewsFeedDelete = () => import('../../components/Form/NewsFeedDelete.vue' /* webpackChunkName: "components/form-news-feed-delete" */).then(c => wrapFunctional(c.default || c))
export const FormPaymentAddSimplified = () => import('../../components/Form/PaymentAddSimplified.vue' /* webpackChunkName: "components/form-payment-add-simplified" */).then(c => wrapFunctional(c.default || c))
export const FormPaymentSelection = () => import('../../components/Form/PaymentSelection.vue' /* webpackChunkName: "components/form-payment-selection" */).then(c => wrapFunctional(c.default || c))
export const FormProductAdd = () => import('../../components/Form/ProductAdd.vue' /* webpackChunkName: "components/form-product-add" */).then(c => wrapFunctional(c.default || c))
export const FormProductCategoryConfig = () => import('../../components/Form/ProductCategoryConfig.vue' /* webpackChunkName: "components/form-product-category-config" */).then(c => wrapFunctional(c.default || c))
export const FormProductDelete = () => import('../../components/Form/ProductDelete.vue' /* webpackChunkName: "components/form-product-delete" */).then(c => wrapFunctional(c.default || c))
export const FormProductVariant = () => import('../../components/Form/ProductVariant.vue' /* webpackChunkName: "components/form-product-variant" */).then(c => wrapFunctional(c.default || c))
export const FormProductVariantMassEdit = () => import('../../components/Form/ProductVariantMassEdit.vue' /* webpackChunkName: "components/form-product-variant-mass-edit" */).then(c => wrapFunctional(c.default || c))
export const FormRoleAdd = () => import('../../components/Form/RoleAdd.vue' /* webpackChunkName: "components/form-role-add" */).then(c => wrapFunctional(c.default || c))
export const FormRoleEdit = () => import('../../components/Form/RoleEdit.vue' /* webpackChunkName: "components/form-role-edit" */).then(c => wrapFunctional(c.default || c))
export const FormShipmentAddSimplified = () => import('../../components/Form/ShipmentAddSimplified.vue' /* webpackChunkName: "components/form-shipment-add-simplified" */).then(c => wrapFunctional(c.default || c))
export const FormStaffAdd = () => import('../../components/Form/StaffAdd.vue' /* webpackChunkName: "components/form-staff-add" */).then(c => wrapFunctional(c.default || c))
export const FormStaffDelete = () => import('../../components/Form/StaffDelete.vue' /* webpackChunkName: "components/form-staff-delete" */).then(c => wrapFunctional(c.default || c))
export const FormStaffEdit = () => import('../../components/Form/StaffEdit.vue' /* webpackChunkName: "components/form-staff-edit" */).then(c => wrapFunctional(c.default || c))
export const FormSubProduct = () => import('../../components/Form/SubProduct.vue' /* webpackChunkName: "components/form-sub-product" */).then(c => wrapFunctional(c.default || c))
export const FormWarehouseAddSimplified = () => import('../../components/Form/WarehouseAddSimplified.vue' /* webpackChunkName: "components/form-warehouse-add-simplified" */).then(c => wrapFunctional(c.default || c))
export const LayoutEmpty = () => import('../../components/Layout/Empty.vue' /* webpackChunkName: "components/layout-empty" */).then(c => wrapFunctional(c.default || c))
export const ListBundle = () => import('../../components/List/Bundle.vue' /* webpackChunkName: "components/list-bundle" */).then(c => wrapFunctional(c.default || c))
export const ListOrders = () => import('../../components/List/Orders.vue' /* webpackChunkName: "components/list-orders" */).then(c => wrapFunctional(c.default || c))
export const ListProduct = () => import('../../components/List/Product.vue' /* webpackChunkName: "components/list-product" */).then(c => wrapFunctional(c.default || c))
export const ModalActivateTax = () => import('../../components/Modal/ActivateTax.vue' /* webpackChunkName: "components/modal-activate-tax" */).then(c => wrapFunctional(c.default || c))
export const ModalBusinessDetail = () => import('../../components/Modal/BusinessDetail.vue' /* webpackChunkName: "components/modal-business-detail" */).then(c => wrapFunctional(c.default || c))
export const ModalBusinessInvitation = () => import('../../components/Modal/BusinessInvitation.vue' /* webpackChunkName: "components/modal-business-invitation" */).then(c => wrapFunctional(c.default || c))
export const ModalBusinessSuccess = () => import('../../components/Modal/BusinessSuccess.vue' /* webpackChunkName: "components/modal-business-success" */).then(c => wrapFunctional(c.default || c))
export const ModalBusinessUpgrade = () => import('../../components/Modal/BusinessUpgrade.vue' /* webpackChunkName: "components/modal-business-upgrade" */).then(c => wrapFunctional(c.default || c))
export const ModalCardPayment = () => import('../../components/Modal/CardPayment.vue' /* webpackChunkName: "components/modal-card-payment" */).then(c => wrapFunctional(c.default || c))
export const ModalChangePlanConfirm = () => import('../../components/Modal/ChangePlanConfirm.vue' /* webpackChunkName: "components/modal-change-plan-confirm" */).then(c => wrapFunctional(c.default || c))
export const ModalFBEventAvailableKey = () => import('../../components/Modal/FBEventAvailableKey.vue' /* webpackChunkName: "components/modal-f-b-event-available-key" */).then(c => wrapFunctional(c.default || c))
export const ModalKwaiEventAvailableKey = () => import('../../components/Modal/KwaiEventAvailableKey.vue' /* webpackChunkName: "components/modal-kwai-event-available-key" */).then(c => wrapFunctional(c.default || c))
export const ModalPaymentSuccess = () => import('../../components/Modal/PaymentSuccess.vue' /* webpackChunkName: "components/modal-payment-success" */).then(c => wrapFunctional(c.default || c))
export const ModalPaymentUpdateSuccess = () => import('../../components/Modal/PaymentUpdateSuccess.vue' /* webpackChunkName: "components/modal-payment-update-success" */).then(c => wrapFunctional(c.default || c))
export const ModalPricePlan = () => import('../../components/Modal/PricePlan.vue' /* webpackChunkName: "components/modal-price-plan" */).then(c => wrapFunctional(c.default || c))
export const ModalProductSuccess = () => import('../../components/Modal/ProductSuccess.vue' /* webpackChunkName: "components/modal-product-success" */).then(c => wrapFunctional(c.default || c))
export const ModalProductWithVariant = () => import('../../components/Modal/ProductWithVariant.vue' /* webpackChunkName: "components/modal-product-with-variant" */).then(c => wrapFunctional(c.default || c))
export const ModalShipmentSuccess = () => import('../../components/Modal/ShipmentSuccess.vue' /* webpackChunkName: "components/modal-shipment-success" */).then(c => wrapFunctional(c.default || c))
export const ModalSubscriptionPayment = () => import('../../components/Modal/SubscriptionPayment.vue' /* webpackChunkName: "components/modal-subscription-payment" */).then(c => wrapFunctional(c.default || c))
export const ModalTiktokEventAvailableKey = () => import('../../components/Modal/TiktokEventAvailableKey.vue' /* webpackChunkName: "components/modal-tiktok-event-available-key" */).then(c => wrapFunctional(c.default || c))
export const ModalVATutorial = () => import('../../components/Modal/VATutorial.vue' /* webpackChunkName: "components/modal-v-a-tutorial" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/Modal/index.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const NavbarAddPayment = () => import('../../components/Navbar/AddPayment.vue' /* webpackChunkName: "components/navbar-add-payment" */).then(c => wrapFunctional(c.default || c))
export const NavbarAddShipment = () => import('../../components/Navbar/AddShipment.vue' /* webpackChunkName: "components/navbar-add-shipment" */).then(c => wrapFunctional(c.default || c))
export const NavbarChangeInventory = () => import('../../components/Navbar/ChangeInventory.vue' /* webpackChunkName: "components/navbar-change-inventory" */).then(c => wrapFunctional(c.default || c))
export const NavbarFormBuilder = () => import('../../components/Navbar/FormBuilder.vue' /* webpackChunkName: "components/navbar-form-builder" */).then(c => wrapFunctional(c.default || c))
export const NavbarFormEditor = () => import('../../components/Navbar/FormEditor.vue' /* webpackChunkName: "components/navbar-form-editor" */).then(c => wrapFunctional(c.default || c))
export const NavbarInventory = () => import('../../components/Navbar/Inventory.vue' /* webpackChunkName: "components/navbar-inventory" */).then(c => wrapFunctional(c.default || c))
export const NavbarPageBuilder = () => import('../../components/Navbar/PageBuilder.vue' /* webpackChunkName: "components/navbar-page-builder" */).then(c => wrapFunctional(c.default || c))
export const NavbarProductAndBundle = () => import('../../components/Navbar/ProductAndBundle.vue' /* webpackChunkName: "components/navbar-product-and-bundle" */).then(c => wrapFunctional(c.default || c))
export const PagesCheckout = () => import('../../components/Pages/Checkout.vue' /* webpackChunkName: "components/pages-checkout" */).then(c => wrapFunctional(c.default || c))
export const PagesDisplayCanvasBuilder = () => import('../../components/Pages/DisplayCanvasBuilder.vue' /* webpackChunkName: "components/pages-display-canvas-builder" */).then(c => wrapFunctional(c.default || c))
export const PagesOnboarding = () => import('../../components/Pages/Onboarding.vue' /* webpackChunkName: "components/pages-onboarding" */).then(c => wrapFunctional(c.default || c))
export const PagesPublicOrder = () => import('../../components/Pages/PublicOrder.vue' /* webpackChunkName: "components/pages-public-order" */).then(c => wrapFunctional(c.default || c))
export const PagesSelfieGuide = () => import('../../components/Pages/SelfieGuide.vue' /* webpackChunkName: "components/pages-selfie-guide" */).then(c => wrapFunctional(c.default || c))
export const PagesSuccessPage = () => import('../../components/Pages/SuccessPage.vue' /* webpackChunkName: "components/pages-success-page" */).then(c => wrapFunctional(c.default || c))
export const PagesTakeSelfie = () => import('../../components/Pages/TakeSelfie.vue' /* webpackChunkName: "components/pages-take-selfie" */).then(c => wrapFunctional(c.default || c))
export const PagesThankYouBuilder = () => import('../../components/Pages/ThankYouBuilder.vue' /* webpackChunkName: "components/pages-thank-you-builder" */).then(c => wrapFunctional(c.default || c))
export const SectionBalanceWithdraw = () => import('../../components/Section/BalanceWithdraw.vue' /* webpackChunkName: "components/section-balance-withdraw" */).then(c => wrapFunctional(c.default || c))
export const SidebarMain = () => import('../../components/Sidebar/Main.vue' /* webpackChunkName: "components/sidebar-main" */).then(c => wrapFunctional(c.default || c))
export const SidebarPageBuilder = () => import('../../components/Sidebar/PageBuilder.vue' /* webpackChunkName: "components/sidebar-page-builder" */).then(c => wrapFunctional(c.default || c))
export const StatisticCardsNewDashboard = () => import('../../components/Statistic/CardsNewDashboard.vue' /* webpackChunkName: "components/statistic-cards-new-dashboard" */).then(c => wrapFunctional(c.default || c))
export const StatisticCardsSummary = () => import('../../components/Statistic/CardsSummary.vue' /* webpackChunkName: "components/statistic-cards-summary" */).then(c => wrapFunctional(c.default || c))
export const StatisticChartConversionRate = () => import('../../components/Statistic/ChartConversionRate.vue' /* webpackChunkName: "components/statistic-chart-conversion-rate" */).then(c => wrapFunctional(c.default || c))
export const StatisticChartNewDashboard = () => import('../../components/Statistic/ChartNewDashboard.vue' /* webpackChunkName: "components/statistic-chart-new-dashboard" */).then(c => wrapFunctional(c.default || c))
export const StatisticChartSummary = () => import('../../components/Statistic/ChartSummary.vue' /* webpackChunkName: "components/statistic-chart-summary" */).then(c => wrapFunctional(c.default || c))
export const TableAffiliateBusiness = () => import('../../components/Table/AffiliateBusiness.vue' /* webpackChunkName: "components/table-affiliate-business" */).then(c => wrapFunctional(c.default || c))
export const TableAffiliateTransaction = () => import('../../components/Table/AffiliateTransaction.vue' /* webpackChunkName: "components/table-affiliate-transaction" */).then(c => wrapFunctional(c.default || c))
export const TableBalanceHistory = () => import('../../components/Table/BalanceHistory.vue' /* webpackChunkName: "components/table-balance-history" */).then(c => wrapFunctional(c.default || c))
export const TableBalanceTransaction = () => import('../../components/Table/BalanceTransaction.vue' /* webpackChunkName: "components/table-balance-transaction" */).then(c => wrapFunctional(c.default || c))
export const TableDashboardRate = () => import('../../components/Table/DashboardRate.vue' /* webpackChunkName: "components/table-dashboard-rate" */).then(c => wrapFunctional(c.default || c))
export const TableDashboardSummary = () => import('../../components/Table/DashboardSummary.vue' /* webpackChunkName: "components/table-dashboard-summary" */).then(c => wrapFunctional(c.default || c))
export const TableInventoryBalance = () => import('../../components/Table/InventoryBalance.vue' /* webpackChunkName: "components/table-inventory-balance" */).then(c => wrapFunctional(c.default || c))
export const TableInventoryHistory = () => import('../../components/Table/InventoryHistory.vue' /* webpackChunkName: "components/table-inventory-history" */).then(c => wrapFunctional(c.default || c))
export const TableInvoices = () => import('../../components/Table/Invoices.vue' /* webpackChunkName: "components/table-invoices" */).then(c => wrapFunctional(c.default || c))
export const TableOrders = () => import('../../components/Table/Orders.vue' /* webpackChunkName: "components/table-orders" */).then(c => wrapFunctional(c.default || c))
export const Table = () => import('../../components/Table/index.vue' /* webpackChunkName: "components/table" */).then(c => wrapFunctional(c.default || c))
export const ToastItem = () => import('../../components/Toast/Item.vue' /* webpackChunkName: "components/toast-item" */).then(c => wrapFunctional(c.default || c))
export const Toast = () => import('../../components/Toast/index.vue' /* webpackChunkName: "components/toast" */).then(c => wrapFunctional(c.default || c))
export const ToolbarPageBuilder = () => import('../../components/Toolbar/PageBuilder.vue' /* webpackChunkName: "components/toolbar-page-builder" */).then(c => wrapFunctional(c.default || c))
export const BuilderStyleFieldAlignment = () => import('../../components/Builder/StyleField/Alignment.vue' /* webpackChunkName: "components/builder-style-field-alignment" */).then(c => wrapFunctional(c.default || c))
export const BuilderStyleFieldObjectFit = () => import('../../components/Builder/StyleField/ObjectFit.vue' /* webpackChunkName: "components/builder-style-field-object-fit" */).then(c => wrapFunctional(c.default || c))
export const BuilderStyleFieldRatio = () => import('../../components/Builder/StyleField/Ratio.vue' /* webpackChunkName: "components/builder-style-field-ratio" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetAnimation = () => import('../../components/Builder/Widget/Animation.vue' /* webpackChunkName: "components/builder-widget-animation" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetButton = () => import('../../components/Builder/Widget/Button.vue' /* webpackChunkName: "components/builder-widget-button" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetDivider = () => import('../../components/Builder/Widget/Divider.vue' /* webpackChunkName: "components/builder-widget-divider" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetEmbed = () => import('../../components/Builder/Widget/Embed.vue' /* webpackChunkName: "components/builder-widget-embed" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetFaq = () => import('../../components/Builder/Widget/Faq.vue' /* webpackChunkName: "components/builder-widget-faq" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetHtml = () => import('../../components/Builder/Widget/Html.vue' /* webpackChunkName: "components/builder-widget-html" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetImg = () => import('../../components/Builder/Widget/Img.vue' /* webpackChunkName: "components/builder-widget-img" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetImgSlider = () => import('../../components/Builder/Widget/ImgSlider.vue' /* webpackChunkName: "components/builder-widget-img-slider" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetList = () => import('../../components/Builder/Widget/List.vue' /* webpackChunkName: "components/builder-widget-list" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetMainForm = () => import('../../components/Builder/Widget/MainForm.vue' /* webpackChunkName: "components/builder-widget-main-form" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetMainFormItems = () => import('../../components/Builder/Widget/MainFormItems.vue' /* webpackChunkName: "components/builder-widget-main-form-items" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetScrollTarget = () => import('../../components/Builder/Widget/ScrollTarget.vue' /* webpackChunkName: "components/builder-widget-scroll-target" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetSection = () => import('../../components/Builder/Widget/Section.vue' /* webpackChunkName: "components/builder-widget-section" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetSpacer = () => import('../../components/Builder/Widget/Spacer.vue' /* webpackChunkName: "components/builder-widget-spacer" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetTestimony = () => import('../../components/Builder/Widget/Testimony.vue' /* webpackChunkName: "components/builder-widget-testimony" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetTextArea = () => import('../../components/Builder/Widget/TextArea.vue' /* webpackChunkName: "components/builder-widget-text-area" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetYoutube = () => import('../../components/Builder/Widget/Youtube.vue' /* webpackChunkName: "components/builder-widget-youtube" */).then(c => wrapFunctional(c.default || c))
export const ModalPluginDuitku = () => import('../../components/Modal/Plugin/Duitku.vue' /* webpackChunkName: "components/modal-plugin-duitku" */).then(c => wrapFunctional(c.default || c))
export const ModalPluginGopay = () => import('../../components/Modal/Plugin/Gopay.vue' /* webpackChunkName: "components/modal-plugin-gopay" */).then(c => wrapFunctional(c.default || c))
export const SidebarBuilderColorSelector = () => import('../../components/Sidebar/Builder/ColorSelector.vue' /* webpackChunkName: "components/sidebar-builder-color-selector" */).then(c => wrapFunctional(c.default || c))
export const SidebarBuilderFontSizeSelector = () => import('../../components/Sidebar/Builder/FontSizeSelector.vue' /* webpackChunkName: "components/sidebar-builder-font-size-selector" */).then(c => wrapFunctional(c.default || c))
export const SidebarBuilderHead = () => import('../../components/Sidebar/Builder/Head.vue' /* webpackChunkName: "components/sidebar-builder-head" */).then(c => wrapFunctional(c.default || c))
export const SidebarBuilderPositionSelector = () => import('../../components/Sidebar/Builder/PositionSelector.vue' /* webpackChunkName: "components/sidebar-builder-position-selector" */).then(c => wrapFunctional(c.default || c))
export const SidebarBuilder = () => import('../../components/Sidebar/Builder/index.vue' /* webpackChunkName: "components/sidebar-builder" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetSettingAnimation = () => import('../../components/Builder/Widget/Setting/Animation.vue' /* webpackChunkName: "components/builder-widget-setting-animation" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetSettingButton = () => import('../../components/Builder/Widget/Setting/Button.vue' /* webpackChunkName: "components/builder-widget-setting-button" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetSettingDivider = () => import('../../components/Builder/Widget/Setting/Divider.vue' /* webpackChunkName: "components/builder-widget-setting-divider" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetSettingEmbed = () => import('../../components/Builder/Widget/Setting/Embed.vue' /* webpackChunkName: "components/builder-widget-setting-embed" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetSettingFaq = () => import('../../components/Builder/Widget/Setting/Faq.vue' /* webpackChunkName: "components/builder-widget-setting-faq" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetSettingHtml = () => import('../../components/Builder/Widget/Setting/Html.vue' /* webpackChunkName: "components/builder-widget-setting-html" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetSettingImg = () => import('../../components/Builder/Widget/Setting/Img.vue' /* webpackChunkName: "components/builder-widget-setting-img" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetSettingImgSlider = () => import('../../components/Builder/Widget/Setting/ImgSlider.vue' /* webpackChunkName: "components/builder-widget-setting-img-slider" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetSettingList = () => import('../../components/Builder/Widget/Setting/List.vue' /* webpackChunkName: "components/builder-widget-setting-list" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetSettingMainForm = () => import('../../components/Builder/Widget/Setting/MainForm.vue' /* webpackChunkName: "components/builder-widget-setting-main-form" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetSettingMainFormItems = () => import('../../components/Builder/Widget/Setting/MainFormItems.vue' /* webpackChunkName: "components/builder-widget-setting-main-form-items" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetSettingScrollTarget = () => import('../../components/Builder/Widget/Setting/ScrollTarget.vue' /* webpackChunkName: "components/builder-widget-setting-scroll-target" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetSettingSection = () => import('../../components/Builder/Widget/Setting/Section.vue' /* webpackChunkName: "components/builder-widget-setting-section" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetSettingTestimony = () => import('../../components/Builder/Widget/Setting/Testimony.vue' /* webpackChunkName: "components/builder-widget-setting-testimony" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetSettingTextArea = () => import('../../components/Builder/Widget/Setting/TextArea.vue' /* webpackChunkName: "components/builder-widget-setting-text-area" */).then(c => wrapFunctional(c.default || c))
export const BuilderWidgetSettingYoutube = () => import('../../components/Builder/Widget/Setting/Youtube.vue' /* webpackChunkName: "components/builder-widget-setting-youtube" */).then(c => wrapFunctional(c.default || c))
export const SidebarBuilderTabDesign = () => import('../../components/Sidebar/Builder/Tab/Design.vue' /* webpackChunkName: "components/sidebar-builder-tab-design" */).then(c => wrapFunctional(c.default || c))
export const SidebarBuilderTabMain = () => import('../../components/Sidebar/Builder/Tab/Main.vue' /* webpackChunkName: "components/sidebar-builder-tab-main" */).then(c => wrapFunctional(c.default || c))
export const SidebarBuilderTabSetting = () => import('../../components/Sidebar/Builder/Tab/Setting.vue' /* webpackChunkName: "components/sidebar-builder-tab-setting" */).then(c => wrapFunctional(c.default || c))
export const SidebarBuilderTabSide = () => import('../../components/Sidebar/Builder/Tab/Side.vue' /* webpackChunkName: "components/sidebar-builder-tab-side" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
