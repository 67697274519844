import { mapGettersHelper, mapMutationsHelper } from '@/utils/helpers.js'

export const state = () => ({
  transactionsMeta: {
    pageSize: 25,
    lastId: undefined
  },
  transactions: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_TRANSACTIONS', [])
      commit('SET_TRANSACTIONS_META', {
        pageSize: 25,
        lastId: undefined
      })
    }
    const { pageSize, lastId } = state.transactionsMeta
    const url = `${this.$config.apiUrlV2}/business/xp-transactions/?
      limit=${isFirst ? 25 : pageSize}
      ${isFirst ? '' : `&after_id=${lastId}`}
      ${query?.reference_id ? `&reference_id=${query?.reference_id}` : ''}
      ${query?.types ? `&types=${query?.types}` : ''}
      ${query?.statuses ? query?.statuses.map((x) => `&statuses=${x}`) : ''}
      ${
        query?.channel_categories
          ? query?.channel_categories.map((x) => `&channel_categories=${x}`)
          : ''
      }
      ${query?.['created[gte]'] ? `&created[gte]=${query['created[gte]']}` : ''}
      ${
        query?.['created[lte]'] ? `&created[lte]=${query['created[lte]']}` : ''
      }`
    try {
      const res = await this.$axios.$get(url.replace(/\s+/g, ''))
      const allTransaction = [...state.transactions, ...res.data.results]
      commit('SET_TRANSACTIONS', allTransaction)
      const nextMeta = {
        pageSize,
        lastId: res.data.results[res.data.results.length - 1]?.id || undefined
      }
      commit('SET_TRANSACTIONS_META', nextMeta)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchBalance({ commit, state }, isHolder = false) {
    const url = `${this.$config.apiUrlV2}/business/xp-balance/${
      isHolder ? '?account_type=HOLDING' : ''
    }`
    try {
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async payout({ commit }, otp) {
    const url = `${this.$config.apiUrlV2}/business/xp-payout/`
    try {
      const res = await this.$axios.$post(url, {
        otp
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
