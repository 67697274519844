import {
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  metaTransaction: {},
  transaction: [],
  currentBalance: 0,
  onHoldBalance: 0,
  payoutTarget: null
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_META_TRANSACTION', {})
      commit('SET_TRANSACTION', [])
    }
    const { page, pageSize } = state.metaTransaction
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `${this.$config.apiUrlV2}/business/transactions/?${queries}`
    try {
      const resTransactionList = await this.$axios.$get(url)
      const allTransaction = [
        ...state.transaction,
        ...resTransactionList.data.results
      ]
      commit('SET_TRANSACTION', allTransaction)
      let newMeta = {
        itemsLength:
          resTransactionList.data.count ||
          resTransactionList.data.results.length
      }
      const nextMeta =
        resTransactionList.data.count > allTransaction.length
          ? { page: initQuery.page + 1, page_size: initQuery.page_size }
          : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_TRANSACTION', newMeta)
      return {
        totalCount: resTransactionList.data.count,
        currentData: allTransaction
      }
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async download({ commit }, { query } = {}) {
    const initQuery = initQueryParams({
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `${this.$config.apiUrlV2}/business/transactions/download?${queries}`
    try {
      return await this.$axios.get(url)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchById({ commit }, idBundle) {
    const url = `${this.$config.apiUrlV2}/bundle/${idBundle}/`
    try {
      const res = await this.$axios.$get(url)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchBySecret({ commit }, secret) {
    const url = `${this.$config.apiUrlV2}/business/transactions/${secret}`
    try {
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchBalance({ commit }, sync = false) {
    const url = `${this.$config.apiUrlV2}/business/balance/${
      sync ? '?sync=true' : ''
    }`
    try {
      const res = await this.$axios.$get(url)
      commit('SET_CURRENT_BALANCE', res.data.available_balance)
      commit('SET_ON_HOLD_BALANCE', res.data.pending_balance)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchPayoutTarget({ commit, dispatch }, idBundle) {
    const url = `${this.$config.apiUrlV2}/business/payout-target/`
    try {
      const res = await this.$axios.$get(url)
      if (res.data.channel_code) {
        const resChannelName = await dispatch(
          'fetchChannelName',
          res.data.channel_code
        )
        res.data.channel_name = resChannelName[0]
          ? resChannelName[0].channel_name
          : res.data.channel_code
      }
      commit('SET_PAYOUT_TARGET', res.data)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async storePayoutTargetData(
    { commit, dispatch },
    { channelCode, accountHolder, accountNumber }
  ) {
    const url = `${this.$config.apiUrlV2}/business/payout-target/`
    try {
      const res = await this.$axios.$post(url, {
        channel_code: channelCode,
        account_holder_name: accountHolder,
        account_number: accountNumber
      })
      if (res.data.channel_code) {
        const resChannelName = await dispatch(
          'fetchChannelName',
          res.data.channel_code
        )
        res.data.channel_name = resChannelName[0]
          ? resChannelName[0].channel_name
          : res.data.channel_code
      }
      commit('SET_PAYOUT_TARGET', res.data)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async deletePayoutTarget({ commit }) {
    const url = `${this.$config.apiUrlV2}/business/payout-target/`
    try {
      const res = await this.$axios.$delete(url)
      commit('SET_PAYOUT_TARGET', null)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchChannelName(_, idChannel) {
    const url = `${this.$config.apiUrlV2}/payout-channels?channel_code=${idChannel}`
    try {
      const res = await this.$axios.$get(url)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchAllChannel(_, query) {
    const queries = encodeQueryData(query)
    const url = `${this.$config.apiUrlV2}/payout-channels/?${queries}`
    try {
      const res = await this.$axios.$get(url)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async reqOtpWithdraw() {
    const url = `${this.$config.apiUrlV2}/business/payout-otp/`
    try {
      const res = await this.$axios.$post(url)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async withdraw(_, { otp, amount }) {
    const url = `${this.$config.apiUrlV2}/business/payout/`
    try {
      const res = await this.$axios.$post(url, {
        otp,
        amount
      })
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async update(store, payload) {
    try {
      const {
        id,
        name,
        publicName,
        customId,
        description,
        weightBump,
        itemType,
        isBundleSharing,
        bundlelines,
        images,
        labels,
        bundlePriceOptions
      } = payload
      const resUpdateBundle = await this.$axios.put(`bundle/${id}/`, {
        name,
        public_name: publicName,
        custom_id: customId === '' ? null : customId,
        description,
        item_type: itemType,
        weight_bump: weightBump,
        is_bundle_sharing: isBundleSharing,
        bundlelines,
        images,
        labels,
        bundle_price_options: bundlePriceOptions
      })
      return resUpdateBundle.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async patch(store, payload) {
    try {
      const {
        id,
        name,
        publicName,
        customId,
        description,
        weightBump,
        itemType,
        isBundleSharing,
        bundlelines,
        images,
        labels,
        bundlePriceOptions
      } = payload
      const resPatchBundle = await this.$axios.$patch(`bundle/${id}/`, {
        name,
        public_name: publicName,
        custom_id: customId,
        description,
        item_type: itemType,
        weight_bump: weightBump,
        is_bundle_sharing: isBundleSharing,
        bundlelines,
        images,
        labels,
        bundle_price_options: bundlePriceOptions
      })
      return resPatchBundle
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async topup({ commit }, payload) {
    try {
      const { amount } = payload
      const url = `${this.$config.apiUrlV2}/business/top-up/`
      const resTopupBalance = await this.$axios.$post(url, {
        amount
      })
      return resTopupBalance.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async checkAccountNumber({ commit }, payload) {
    try {
      const { channelCode, accountNumber, refName } = payload
      const url = `${this.$config.apiUrlV2}/business/check-account-number/`
      const res = await this.$axios.$post(url, {
        channel_code: channelCode,
        account_number: accountNumber,
        ref_name: refName
      })
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  setMetaBundles({ commit }, payload) {
    commit('SET_META_BUNDLE', payload)
  }
}
