import { cloneDeep } from 'lodash'
import { mapMutationsHelper, mapGettersHelper } from '@/utils/helpers.js'

export const state = () => ({
  permission: [],
  model: [],
  pagePermission: null
})

export const mutations = {
  ...mapMutationsHelper(state()),
  concatPermission(state, payload) {
    state.tempPermissions = [...state.tempPermissions, ...payload]
  }
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  // async fetchAll({ commit }, query) {
  //   if (isEmpty(query)) {
  //     query = {
  //       page: 1,
  //     }
  //   }
  //   query = {
  //     isActive: 1,
  //     page: 1,
  //     ...query,
  //   }
  //   const queries = encodeQueryData(query)
  //   const url = `permissions/?${queries}`
  //   try {
  //     const resStaffList = await this.$axios.$get(url)
  //     let meta = decodeQueryData(resStaffList.data.next?.split('?')?.[1])

  //     this.commit('permission/concatPermission', resStaffList.data.results)
  //     if (resStaffList.data.next) {
  //       await this.dispatch('permission/fetchAll', meta)
  //     } else {
  //       commit('SET_PERMISSION', this.state.permission.tempPermissions)
  //     }

  //     if (!resStaffList.data.next)
  //       meta = decodeQueryData(resStaffList.data.previous?.split('?')?.[1])
  //     const page = resStaffList.data.next ? meta.page - 1 : meta.page - 0 + 1
  //     commit('SET_META_PERMISSION', {
  //       itemsLength:
  //         resStaffList.data.count || resStaffList.data.results.length,
  //       page,
  //       perPage: meta.page_size - 0,
  //     })
  //     return resStaffList
  //   } catch (error) {
  //     if (error.response?.status === 401) {
  //       throw new Error('Bad Credentials')
  //     } else if (error.response?.status === 502) {
  //       throw new Error('Network Error')
  //     }
  //     throw error
  //   }
  // },
  async fetchModel({ commit }) {
    const url = `custom/`
    try {
      const resStaffList = await this.$axios.$get(url)
      commit('SET_MODEL', resStaffList.data)
      return resStaffList
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  hasPermission({ commit }, payload) {
    const { permission, user } = payload
    const permissionsList = cloneDeep(user.account.role.permissions_list || [])
    let hasPermission = false
    if (
      user.detail.email &&
      user.currentBusiness?.owner?.email === user.detail.email
    ) {
      return true
    }
    const permissionsArr =
      typeof permission === 'string' ? [permission] : permission
    hasPermission = permissionsArr.some((perm) =>
      permissionsList.includes(perm)
    )
    return hasPermission
  },
  setPagePermission({ commit }, permission) {
    commit('SET_PAGE_PERMISSION', permission)
  }
}
