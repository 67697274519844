var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isShow)?_c('ul',{staticClass:"absolute inset-x-0 flex flex list-none flex-wrap-reverse items-center justify-end border-y-2 border-gray-400 bg-white transition-bottom",class:[_vm.showUp ? 'bottom-[100%]' : 'bottom-[-8px]']},_vm._l((_vm.menu),function(moreMenu,index){return _c('li',{key:("mobile-nav-more-menu-" + index),staticClass:"flex w-1/5 justify-center"},[_c('NuxtLink',{attrs:{"to":moreMenu.link,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"relative flex w-full flex-col items-center justify-center py-[10px]",class:[
          isActive && !moreMenu.name === 'Woi!' && 'text-primary',
          { '!text-green-650': moreMenu.name === 'Verify' }
        ],attrs:{"href":href,"color":"bg-transparent"},on:{"click":function($event){return _vm.onClickNav($event, navigate, moreMenu)}}},[_c('SclvIcon',{staticClass:"mb-[2px]",class:[
            { 'text-[18px]': moreMenu.name === 'Affiliate' },
            { 'animate-bounce': moreMenu.name === 'Verify' }
          ],attrs:{"icon":moreMenu.icon}}),_vm._v(" "),(_vm.getUnread > 0 && moreMenu.name === 'Woi!')?_c('div',{staticClass:"absolute right-[8px] top-0 rounded-full bg-red-500 p-[4px] text-[10px] font-[500] text-white"},[_vm._v("\n          "+_vm._s(_vm.getUnread > 99 ? '99+' : _vm.getUnread)+"\n        ")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"text-[10px]",class:[isActive && 'text-black-950']},[_vm._v("\n          "+_vm._s(moreMenu.name)+"\n        ")])],1)]}}],null,true)})],1)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }