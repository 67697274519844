import { mapGettersHelper, mapMutationsHelper } from '@/utils/helpers.js'

export const state = () => ({
  financialEntities: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit }, queries = {}) {
    try {
      const res = await this.$axios.$get(
        `/financial-entity/?page=1&page_size=100&provider=duitku`
      )
      commit('SET_FINANCIAL_ENTITIES', res.data.results)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
