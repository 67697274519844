import { extend, setInteractionMode } from 'vee-validate'
// Library message
// import { messages as veeMessages } from 'vee-validate/dist/locale/id.json';
import { rules } from '@/utils/input-rules.js'

setInteractionMode('aggressive')

export default (ctx) => {
  const { store, app } = ctx
  // READ
  // https://logaretm.github.io/vee-validate/guide/interaction-and-ux.html#interaction-modes
  extend('required', {
    validate(value) {
      return {
        required: true,
        // eslint-disable-next-line unicorn/prefer-includes
        valid: ['', null, undefined].indexOf(value) === -1
      }
    },
    computesRequired: true,
    message: (fieldName, { isHidden }) => {
      if (!!isHidden && isHidden === 'hidden') {
        return ' '
      }
      return app.i18n.t('validation.requiredOnly', { field: fieldName })
    }
  })

  extend('required_boolean', {
    validate(value) {
      return {
        required: true,
        allowFalse: false,
        // eslint-disable-next-line unicorn/prefer-includes
        valid: ['', null, undefined, false].indexOf(value) === -1
      }
    },
    computesRequired: true,
    message: app.i18n.t('validation.required')
  })

  extend('required_length', {
    validate(value) {
      return {
        required: true,
        valid: value?.length > 0
      }
    },
    computesRequired: true,
    message: app.i18n.t('validation.required')
  })

  extend('required_selection', {
    validate(value) {
      return {
        required: true,
        allowFalse: false,
        // eslint-disable-next-line unicorn/prefer-includes
        valid: ['', null, undefined, false].indexOf(value) === -1
      }
    },
    computesRequired: true,
    message: app.i18n.t('validation.required')
  })

  extend('email', {
    validate: (value) => value.match(rules.email),
    message: app.i18n.t('validation.wrongFormat')
  })

  extend('domain', {
    validate: (value) => value.match(rules.domain),
    message: app.i18n.t('validation.wrongFormat')
  })

  // extend('subdomain', {
  //   validate: (value) => value.match(rules.subdomain),
  //   message: messages.wrong_format
  // })

  extend('password', {
    validate: (value) => value.match(rules.password),
    message: app.i18n.t('validation.wrongPassword')
  })

  extend('confirm_password', {
    params: ['target'],
    validate(value, { target }) {
      return value === target
    },
    message: app.i18n.t('validation.confirmPassword')
  })

  extend('confirm_value', {
    params: ['target'],
    validate(value, { target }) {
      return value === target
    },
    message: app.i18n.t('validation.confirmValue')
  })

  extend('min_value', {
    params: ['target'],
    validate(value, { target }) {
      return parseFloat(value) > parseFloat(target)
    },
    message: app.i18n.t('validation.minValue')
  })

  extend('max_value', {
    params: ['target'],
    validate(value, { target }) {
      return parseFloat(value) <= parseFloat(target)
    },
    message: app.i18n.t('validation.maxValue')
  })

  extend('email_not_exist', {
    validate(value) {
      return store.dispatch('user/checkEmail', value)
    },
    message() {
      return store.getters['user/getEmailExistMsg']
    }
  })

  extend('no_emoji', {
    validate: (value) => !/\p{Extended_Pictographic}/u.test(value),
    message: app.i18n.t('validation.noEmoji')
  })

  extend('number', {
    validate: (value) => value.match(rules.number),
    message: app.i18n.t('validation.onlyNumbers')
  })

  extend('subdomain', {
    validate: (value) =>
      // eslint-disable-next-line no-useless-escape
      !/[^a-zA-Z0-9\-]/.test(value) &&
      value.substring(0, 1) !== '-' &&
      value.substring(value.length - 1) !== '-',
    message: app.i18n.t('validation.notValid')
  })

  extend('lowerAlpha_num_dash', {
    validate: (value) => value.match(rules.lowerAplha_num_dash),
    message: app.i18n.t('validation.confirmValue')
  })

  extend('isNot', {
    validate: (value, values) => {
      return !values.includes(value)
    },
    message: app.i18n.t('validation.isNot')
  })

  extend('max', {
    params: ['maxChars'],
    validate: (value, { maxChars }) => {
      return value?.length <= maxChars
    },
    message: app.i18n.t('validation.max')
  })

  extend('min', {
    params: ['minChars'],
    validate: (value, { minChars }) => {
      return value?.length >= minChars
    },
    message: app.i18n.t('validation.min')
  })
}
