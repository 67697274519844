var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"provider",staticClass:"relative",class:[_vm.width, { 'mb-[3px]': !_vm.hideMessage }],attrs:{"vid":_vm.vid,"name":_vm.name || _vm.label || _vm.placeholder,"rules":_vm.rules,"immediate":_vm.immediate,"detect-input":false,"tag":"fieldset"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._t("label",function(){return [(_vm.label)?_c('label',{staticClass:"mb-[10px] block font-medium",class:[_vm.labelSize, _vm.labelColor],attrs:{"for":_vm.idFix}},[_vm._v("\n      "+_vm._s(_vm.label)+"\n      "),(_vm.label && _vm.rules.split('|').includes('required_selection'))?_c('span',{staticClass:"text-red-900",domProps:{"textContent":_vm._s('*')}}):_vm._e()]):_vm._e()]},null,{ idFix: _vm.idFix }),_vm._v(" "),_c('div',{ref:"selectWrapper",staticClass:"select-wrapper relative flex w-full flex-col",on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"flex w-full"},[_vm._t("prepend-outer"),_vm._v(" "),_c('div',{staticClass:"relative flex w-full transform flex-wrap items-center overflow-hidden transition duration-100 ease-in-out",class:[_vm.textSize]},[_vm._t("prepend",function(){return [(_vm.prepend)?_c('div',{staticClass:"flex h-full bg-gray-200 px-[12px]"},[_c('span',{staticClass:"whitespace-no-wrap text-grey-dark flex items-center leading-normal"},[_vm._v("\n              "+_vm._s(_vm.prepend)+"\n            ")])]):_vm._e()]}),_vm._v(" "),_c('div',{ref:"buttonSelect",staticClass:"flex w-full cursor-pointer items-center",class:[
            //isWithSubText
            //? 'h-[40px] md:h-[55px]'
            //: small
            //? 'h-[28px]'
            //: 'h-[40px]'
          ],attrs:{"id":_vm.idFix}},[_c('VDropdown',{ref:"dropdownComp",staticClass:"h-full flex-1 overflow-y-auto",attrs:{"placement":_vm.placement,"triggers":[],"disabled":_vm.disabled,"distance":-_vm.containerDropdownHeight,"popper-class":("sclv-dropdown " + (_vm.small ? 'small-popper' : '')),"shown":_vm.isShown,"positioning-disabled":_vm.$mq === 'sm',"auto-size":"","auto-hide":""},on:{"update:shown":function($event){_vm.isShown=$event},"hide":_vm.onHide,"apply-show":_vm.onApplyShow},scopedSlots:_vm._u([{key:"popper",fn:function(ref){
          var hide = ref.hide;
return [(!_vm.isSearchable)?[_c('div',{staticClass:"h-0 w-0",attrs:{"tabindex":_vm.mainStillFocus ? '-1' : '0'},on:{"focusin":_vm.prevFocus}}),_vm._v(" "),_c('div',{ref:"mainFocus",staticClass:"h-0 w-0",attrs:{"tabindex":"0"}}),_vm._v(" "),_c('div',{staticClass:"h-0 w-0",attrs:{"tabindex":_vm.mainStillFocus ? '-1' : '0'},on:{"focusin":_vm.nextFocus}})]:_vm._e(),_vm._v(" "),_c('button',{staticClass:"cloned-input focus:outline-to-black-25 flex h-full w-full flex-wrap items-center break-all text-left text-gray-900",class:[
                  _vm.plCorrectionDropdownSelection,
                  _vm.disabled ? 'bg-gray-50 text-black-950' : _vm.bg,
                  {
                    'text-black-400':
                      _vm.tempValue === null || _vm.tempValue === undefined,
                    'pr-[12px]': !_vm.small,
                    'pr-[8px]': _vm.small
                  },
                  _vm.small ? _vm.roundedSm : _vm.rounded,
                  _vm.isWithSubText
                    ? _vm.heightWithSubText
                    : _vm.small
                    ? '!max-h-[36px] !min-h-[36px]'
                    : _vm.height,
                  _vm.textSize
                ],attrs:{"tabindex":"-1","type":"button"},on:{"click":_vm.onClickInnerTrigger}},[_vm._t("selection",function(){return [_c('div',{staticClass:"flex w-full flex-col pr-[16px]",class:{ 'text-black-400': _vm.textValue === _vm.placeholder }},[_c('span',{staticClass:"flex min-h-[18px] items-center truncate"},[_vm._v("\n                      "+_vm._s(_vm.textValue)+" "+_vm._s(_vm.appendTextOnOpenInMobile)+"\n                    ")]),_vm._v(" "),(_vm.isWithSubText && _vm.textValue !== _vm.placeholder)?_c('span',{staticClass:"mt-[6px] text-[12px] text-black-200"},[_vm._v("\n                      "+_vm._s(_vm.subTextValue)+"\n                    ")]):_vm._e()])]},null,{
                    selected: _vm.textValue,
                    selectedOption: _vm.selectedOption,
                    isWithSubText: _vm.isWithSubText,
                    subText: _vm.subTextValue,
                    placeholder: _vm.placeholder,
                    isShown: _vm.isShown
                  }),_vm._v(" "),_c('SclvIcon',{staticClass:"absolute right-[-1px] mr-3 rotate-[-90deg] text-[8px] text-black-300 md:rotate-[90deg]",attrs:{"icon":"ic-angle-left"}})],2),_vm._v(" "),(_vm.isSearchable)?_c('div',{class:_vm.searchWrapperClass},[_c('div',{staticClass:"h-0 w-0",attrs:{"tabindex":_vm.mainStillFocus ? '-1' : '0'},on:{"focusin":_vm.prevFocus}}),_vm._v(" "),_c('TextField',{ref:"searchField",attrs:{"value":_vm.searchValue,"placeholder":_vm.placeholderSearch,"hide-message":"","bg":"bg-white","prepend":_vm.searchPrepend},on:{"keydown":_vm.seachOptionsTypeHandlerKeydown},nativeOn:{"input":function($event){return _vm.seachOptionsTypeHandler.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"flex h-full items-center bg-white pr-[6px]"},[_c('SclvIcon',{staticClass:"ml-auto text-[18px]",attrs:{"icon":"ic-search"}})],1)]},proxy:true}],null,true)}),_vm._v(" "),_c('div',{staticClass:"h-0 w-0",attrs:{"tabindex":_vm.mainStillFocus ? '-1' : '0'},on:{"focusin":_vm.nextFocus}})],1):_vm._e(),_vm._v(" "),_c('ul',{ref:"scrollListWrapper",staticClass:"list-dropdown overflow-y-auto font-medium md:max-h-[240px]",class:[
                  _vm.textSize,
                  _vm.listWrapperClass,
                  _vm.stopCursorEv ? 'pointer-events-none' : ''
                ]},[(
                    !!_vm.placeholder &&
                    !_vm.isSearchable &&
                    _vm.isLoadingSearch &&
                    !!_vm.urlOptions
                  )?_c('li',{staticClass:"p-[8px] text-black-400",attrs:{"value":null,"disabled":""}},[_vm._v("\n                  "+_vm._s(_vm.placeholder)+"\n                ")]):_vm._e(),_vm._v(" "),_c('li',{class:_vm.prependListClass,attrs:{"tabindex":!!_vm.$slots['prependListItem'] ? '0' : '-1'}},[_vm._t("prependListItem",null,null,{ hide: hide, setSelectedList: _vm.setSelectedList, selectedList: _vm.selectedList })],2),_vm._v(" "),(!_vm.isLoadingSearch)?_vm._l((_vm.fixedOptions),function(list,i){return _c('li',{key:i,ref:("generatedList" + i),refInFor:true,staticClass:"flex items-center rounded",class:[
                      list[_vm.valueField] === _vm.tempValue
                        ? 'cursor-pointer text-primary'
                        : list[_vm.valueField] !== 'separator' &&
                          !_vm.excludeFromList.includes(list[_vm.valueField])
                        ? 'cursor-pointer text-gray-700'
                        : '',
                      i === _vm.selectedList ||
                      _vm.excludeFromList.includes(list[_vm.valueField])
                        ? 'bg-gray-150'
                        : '',
                      _vm.defaultListHeight
                    ],on:{"click":function($event){list[_vm.valueField] === 'separator' ||
                      _vm.excludeFromList.includes(list[_vm.valueField])
                        ? null
                        : _vm.selectValue(list)},"mouseenter":function($event){_vm.selectedList = i}}},[(list[_vm.valueField] === 'separator')?_c('div',{staticClass:"h-[2px] w-full bg-gray-400"}):_vm._t("list-item",function(){return [_c('div',{staticClass:"flex w-full items-center",class:_vm.listWrapperClass},[(_vm.isWithAvatar)?_c('SclvAvatar',{staticClass:"h-[24px] w-[24px] rounded-full object-cover text-[10px]",class:{
                            'order-2': _vm.avatarRight,
                            'order-1': !_vm.avatarRight
                          },attrs:{"username":list[_vm.avatarObject],"src":list[_vm.avatarField]}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"w-full flex-1 break-words",class:{
                            'order-1 pr-[10px]': _vm.avatarRight,
                            'order-2 pl-[10px]': _vm.isWithAvatar && !_vm.avatarRight
                          }},[_vm._v("\n                          "+_vm._s(list[_vm.textField])+"\n                          "),(_vm.isWithSubText)?_c('span',{staticClass:"mt-[6px] text-[12px]"},[_vm._v("\n                            "+_vm._s(list[_vm.subTextField])+"\n                          ")]):_vm._e()])],1)]},null,{ item: list, index: i })],2)}):_vm._e(),_vm._v(" "),(
                    _vm.emptyOptionsOnOpen && _vm.searchValue.length < _vm.searchMinChar
                  )?_c('li',{staticClass:"flex h-[32px] w-full items-center justify-center"},[_vm._v("\n                  Ketik minimal "+_vm._s(_vm.searchMinChar)+" karakter...\n                ")]):(_vm.isLoadingSearch)?_c('li',{staticClass:"flex h-[32px] w-full items-center justify-center",domProps:{"textContent":_vm._s(_vm.searchLoadingText)}}):_vm._e(),_vm._v(" "),(
                    !!_vm.urlOptions &&
                    !_vm.isLoadingSearch &&
                    !_vm.isPaginationDone &&
                    (!_vm.emptyOptionsOnOpen ||
                      (_vm.emptyOptionsOnOpen &&
                        _vm.searchValue.length >= _vm.searchMinChar))
                  )?_c('SclvIntersect',{attrs:{"root":_vm.$refs.scrollListWrapper,"is-done":_vm.isPaginationDone},on:{"change":_vm.onView}},[_c('div',{staticClass:"flex h-[32px] w-full items-center justify-center",domProps:{"textContent":_vm._s(_vm.listLoadingText)}})]):_vm._e(),_vm._v(" "),(
                    !_vm.isLoadingSearch &&
                    ((_vm.urlOptions && _vm.isPaginationDone) || !_vm.urlOptions) &&
                    !_vm.searchValue &&
                    !_vm.emptyOptionsOnOpen &&
                    _vm.fixedOptions.length < 1
                  )?_c('li',{staticClass:"flex w-full items-center justify-center"},[_vm._t("list-empty",function(){return [_c('p',{staticClass:"py-[8px]"},[_vm._v(_vm._s(_vm.noOptionsText))])]})],2):(
                    ((_vm.urlOptions && _vm.isPaginationDone) || !_vm.urlOptions) &&
                    !_vm.isLoadingSearch &&
                    !!_vm.searchValue &&
                    _vm.fixedOptions.length < 1
                  )?_c('li',{staticClass:"flex w-full items-center justify-center"},[_vm._t("no-result",function(){return [_c('p',{staticClass:"py-[8px]"},[_vm._v(_vm._s(_vm.noResultText))])]})],2):_vm._e(),_vm._v(" "),_c('li',{class:_vm.appendListClass},[_vm._t("appendListItem",null,null,{ hide: hide })],2)],2)]}}],null,true)},[_c('div',{staticClass:"relative h-full w-full overflow-hidden",class:[
                {
                  'border-2 focus-within:border-primary': !_vm.noBorder,
                  'border-red-600': errors[0] && !_vm.isShown && !_vm.noBorder,
                  'border-input': !errors[0] && _vm.isShown && !_vm.noBorder
                },
                _vm.small ? _vm.roundedSm : _vm.rounded
              ]},[_c('div',{staticClass:"absolute inset-0 cursor-pointer",on:{"click":_vm.onClickOuterTrigger}}),_vm._v(" "),_c('div',{staticClass:"h-0 w-0",attrs:{"tabindex":_vm.mainStillFocus ? '0' : '-1'},on:{"focusin":_vm.prevFocus}}),_vm._v(" "),_c('button',{ref:"outerTrigger",staticClass:"flex h-full w-full flex-wrap items-center break-all text-left focus:outline-none",class:[
                  _vm.disabled ? 'bg-gray-50 text-black-950' : _vm.bg,
                  {
                    'text-black-400':
                      _vm.tempValue === null || _vm.tempValue === undefined,
                    'px-[12px]': !_vm.small,
                    'px-[8px]': _vm.small
                  },
                  _vm.isWithSubText
                    ? _vm.heightWithSubText
                    : _vm.small
                    ? 'h-[26px]'
                    : _vm.height
                ],attrs:{"tabindex":"0","disabled":_vm.disabled,"type":"button"},on:{"focusin":_vm.outerTriggerFocusIn,"focusout":_vm.outerTriggerFocusOut}},[_vm._t("selection",function(){return [_c('div',{staticClass:"flex flex-col",class:[
                      { 'text-black-400': _vm.textValue === _vm.placeholder },
                      { 'w-full pr-[16px]': !_vm.noSpaceDropdownIcon }
                    ]},[_c('span',{staticClass:"flex min-h-[18px] items-center truncate"},[_vm._v("\n                      "+_vm._s(_vm.textValue)+"\n                    ")]),_vm._v(" "),(_vm.isWithSubText && _vm.textValue !== _vm.placeholder)?_c('span',{staticClass:"mt-[6px] text-[12px] text-black-200"},[_vm._v("\n                      "+_vm._s(_vm.subTextValue)+"\n                    ")]):_vm._e()])]},null,{
                    selected: _vm.textValue,
                    selectedOption: _vm.selectedOption,
                    isWithSubText: _vm.isWithSubText,
                    subText: _vm.subTextValue,
                    placeholder: _vm.placeholder,
                    isShown: _vm.isShown
                  }),_vm._v(" "),_c('SclvIcon',{staticClass:"right-0 rotate-[-90deg] text-[8px] text-black-300",class:[
                    _vm.noSpaceDropdownIcon ? 'ml-[6px]' : 'absolute mr-3'
                  ],attrs:{"icon":"ic-angle-left"}})],2),_vm._v(" "),_c('div',{staticClass:"h-0 w-0",attrs:{"tabindex":_vm.mainStillFocus ? '0' : '-1'},on:{"focusin":_vm.nextFocus}})])])],1)],2),_vm._v(" "),_vm._t("append",function(){return [(_vm.append)?_c('div',{staticClass:"flex h-full font-medium"},[_c('span',{staticClass:"whitespace-no-wrap text-grey-dark flex items-center bg-black-75 px-3 leading-normal"},[_vm._v("\n            "+_vm._s(_vm.append)+"\n          ")])]):_vm._e()]})],2),_vm._v(" "),_c('div',{ref:"containerDropdown",staticClass:"absolute top-full hidden h-full w-full md:block",class:_vm.width,staticStyle:{"z-index":"-1"}})]),_vm._v(" "),(
      (!_vm.hideMessage && !_vm.autoHideMessage) ||
      (_vm.autoHideMessage && errors.length > 0)
    )?_c('span',{staticClass:"break-word mb-[5px] mt-[5px] flex items-center text-[12px] text-red-900",class:[!errors[0] && 'h-[3px]']},[(errors[0])?_c('SclvIcon',{staticClass:"mr-[8px] text-[13px] text-red-900",attrs:{"icon":"ic-exclamation"}}):_vm._e(),_vm._v("\n    "+_vm._s(errors[0] || ' ')+"\n  ")],1):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }